import { Link } from 'react-router-dom';
import { Badge, MenuProps } from 'antd';
import {
  FileProtectOutlined,
  AuditOutlined,
  UnorderedListOutlined,
  TeamOutlined,
  BankOutlined,
  FileExcelOutlined,
  EditOutlined,
  // ClusterOutlined,
} from '@ant-design/icons';
import {
  licensesRoute,
  licensesRequestRoute,
  licensesRequestCreateRoute,
  eventsRoute,
  usersRoute,
  createUserRoute,
  banksRoute,
  // terminalsRoute,
  // terminalAddRoute,
  bankAddRoute,
  licensesAddRoute,
  licensesDefaultSettingsRoute,
  revokeLicensesRequestRoute,
  revokeLicensesRequestCreateRoute,
  licensesRequestExportRoute,
  changeLicensesRequestCreateRoute,
  changeLicensesRequestRoute,
  licensesExport,
} from 'app/pages/routes';
import { permissions } from 'app/features/users/permissions';

// type MainMenuProps = () => Required<MenuProps['items']>;
type MainMenuProps = () => Required<MenuProps>['items'];

export const mainMenuItems: MainMenuProps = () => {
  // export const mainMenuItems: ItemType[] = () => {
  return [
    // {
    //   key: 'terminals',
    //   label: 'Терминалы',
    //   permission: permissions['PERM_TERMINAL_READ'],
    //   icon: <ClusterOutlined />,
    //   children: [
    //     {
    //       key: 'terminals-all',
    //       permission: permissions['PERM_TERMINAL_READ'],
    //       label: (
    //         <Link to={terminalsRoute.path}>
    //           <Badge status="default" text="Все терминалы" />
    //         </Link>
    //       ),
    //     },
    //     {
    //       key: 'terminals-add',
    //       permission: permissions['PERM_TERMINAL_CREATE'],
    //       label: (
    //         <Link to={terminalAddRoute.path}>
    //           <Badge status="default" text="Добавить терминал" />
    //         </Link>
    //       ),
    //     },
    //   ],
    // },
    {
      key: 'licenses',
      label: 'Лицензии',
      permission: permissions.PERM_BANK_LICENCE_READ,
      icon: <FileProtectOutlined />,
      children: [
        {
          key: 'licenses-all',
          permission: permissions.PERM_BANK_LICENCE_READ,
          label: (
            <Link to={licensesRoute.path}>
              <Badge status="default" text="Все лицензии" />
            </Link>
          ),
        },
        {
          key: 'licenses-add',
          permission: permissions.PERM_BANK_LICENCE_CREATE,
          label: (
            <Link to={licensesAddRoute.path}>
              <Badge status="default" text="Добавить лицензию" />
            </Link>
          ),
        },
        {
          key: 'licenses-default-settings',
          permission: permissions.PERM_DEFAULT_TERMINAL_POS_CONF_UPDATE,
          label: (
            <Link to={licensesDefaultSettingsRoute.path}>
              <Badge status="default" text="Настройки по умолчанию" />
            </Link>
          ),
        },
        {
          key: 'licenses-export',
          permission: permissions.PERM_BANK_LICENCE_READ,
          label: (
            <Link to={licensesExport.path}>
              <Badge status="default" text="Экспорт лицензий" />
            </Link>
          ),
        },
      ],
    },
    {
      key: 'license-requests',
      label: 'Заявки на лицензию',
      permission: permissions.PERM_BANK_LICENCE_REQUEST_READ,
      icon: <AuditOutlined />,
      children: [
        {
          key: 'license-requests-all',
          permission: permissions.PERM_BANK_LICENCE_REQUEST_READ,
          label: (
            <Link to={licensesRequestRoute.path}>
              <Badge status="default" text="Все заявки" />
            </Link>
          ),
        },
        {
          key: 'license-requests-create',
          permission: permissions.PERM_BANK_LICENCE_REQUEST_CREATE,
          label: (
            <Link to={licensesRequestCreateRoute.path}>
              <Badge status="default" text="Создать заявку" />
            </Link>
          ),
        },
        {
          key: 'license-requests-export',
          permission: permissions.PERM_BANK_LICENCE_REQUEST_READ,
          label: (
            <Link to={licensesRequestExportRoute.path}>
              <Badge status="default" text="Экспорт заявок" />
            </Link>
          ),
        },
      ],
    },
    {
      key: 'remove-license-requests',
      label: 'Удаление лицензий',
      permission: permissions.PERM_REVOKE_LICENCE_REQUEST_READ,
      icon: <FileExcelOutlined />,
      children: [
        {
          key: 'remove-license-requests-all',
          permission: permissions.PERM_REVOKE_LICENCE_REQUEST_READ,
          label: (
            <Link to={revokeLicensesRequestRoute.path}>
              <Badge status="default" text="Все заявки" />
            </Link>
          ),
        },
        {
          key: 'remove-license-requests-create',
          permission: permissions.PERM_REVOKE_LICENCE_REQUEST_CREATE,
          label: (
            <Link to={revokeLicensesRequestCreateRoute.path}>
              <Badge status="default" text="Создать заявку" />
            </Link>
          ),
        },
      ],
    },
    {
      key: 'change-license-requests',
      label: 'Редактирование лицензий',
      permission: permissions.PERM_CHANGE_LICENCE_REQUEST_READ,
      icon: <EditOutlined />,
      children: [
        {
          key: 'change-license-requests-all',
          permission: permissions.PERM_CHANGE_LICENCE_REQUEST_READ,
          label: (
            <Link to={changeLicensesRequestRoute.path}>
              <Badge status="default" text="Все заявки" />
            </Link>
          ),
        },
        {
          key: 'change-license-requests-create',
          permission: permissions.PERM_CHANGE_LICENCE_REQUEST_CREATE,
          label: (
            <Link to={changeLicensesRequestCreateRoute.path}>
              <Badge status="default" text="Создать заявку" />
            </Link>
          ),
        },
      ],
    },

    {
      key: 'banks',
      label: 'Банки',
      permission: permissions.PERM_BANK_READ,
      icon: <BankOutlined />,
      children: [
        {
          key: 'banks-all',
          permission: permissions.PERM_BANK_READ,
          label: (
            <Link to={banksRoute.path}>
              <Badge status="default" text="Все банки" />
            </Link>
          ),
        },
        {
          key: 'banks-add',
          permission: permissions.PERM_BANK_CREATE,
          label: (
            <Link to={bankAddRoute.path}>
              <Badge status="default" text="Добавить банк" />
            </Link>
          ),
        },
      ],
    },

    {
      key: 'events',
      label: 'Журнал событий',
      permission: permissions.PERM_JOURNAL_READ,
      icon: <UnorderedListOutlined />,
      children: [
        {
          key: 'events-all',
          permission: permissions.PERM_JOURNAL_READ,
          label: (
            <Link to={eventsRoute.path}>
              <Badge status="default" text="Все события" />
            </Link>
          ),
        },
      ],
    },

    {
      key: 'users',
      label: 'Пользователи',
      permission: permissions.PERM_USER_READ,
      icon: <TeamOutlined />,
      children: [
        {
          key: 'users-all',
          permission: permissions.PERM_USER_READ,
          label: (
            <Link to={usersRoute.path}>
              <Badge status="default" text="Все пользователи" />
            </Link>
          ),
        },
        {
          key: 'users-create',
          permission: permissions.PERM_USER_CREATE,
          label: (
            <Link to={createUserRoute.path}>
              <Badge status="default" text="Добавить пользователя" />
            </Link>
          ),
        },
      ],
    },
  ];
};
