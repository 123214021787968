import { InputNumber, Select } from 'antd';
import { TColumn } from 'core/entities/columns';
import { TUser } from 'core/entities/user';
import { Link } from 'react-router-dom';

export const userColumns: TColumn<TUser>[] = [
  {
    key: 'id',
    title: 'ID',
    dataIndex: 'id',
    sorter: {
      multiple: 1,
    },
    available: true,
    visible: true,
    filtrable: true,
    filterRender: (props) => <InputNumber {...props} />,
  },
  {
    key: 'login',
    title: 'Логин',
    dataIndex: 'login',
    sorter: {
      multiple: 2,
    },
    available: true,
    visible: true,
    filtrable: true,
    render: (_, item: TUser) => {
      return <Link to={`/user/${item.id}`}>{item.login}</Link>;
    },
  },
  {
    key: 'blocked',
    title: 'Статус доступа',
    dataIndex: 'blocked',
    sorter: {
      multiple: 3,
    },
    available: true,
    visible: true,
    render: (_, { blocked }) => (blocked ? 'Заблокирован' : 'Разрешен'),
    filtrable: true,
    filterRender: (props) => (
      <Select {...props}>
        <Select.Option key="1" value="false">
          Разрешен
        </Select.Option>
        <Select.Option key="0" value="true">
          Заблокирован
        </Select.Option>
      </Select>
    ),
  },
  {
    key: 'roles',
    title: 'Роли',
    dataIndex: 'roles',
    available: true,
    visible: true,
    filtrable: false,
    render: (_, { roles }) => roles.map((role) => role.title).join(', '),
  },
  // {
  //   key: 'roles',
  //   title: 'Доступные роли',
  //   dataIndex: 'roles',
  //   sorter: false,
  //   available: false,
  //   visible: false,
  // },
];
