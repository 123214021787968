import { ObservableRequest } from 'app/tools/observable-request';

import { INotifications } from 'core/entities/notifications';
import { makeAutoObservable } from 'mobx';

import { IHttpClient } from 'app/libs/http-client/types';

import { RevokeLicenseRequestStore } from '../revoke-license-request.store';
import { TRevokeLicenseRequest } from 'core/entities/revoke-license-request';
import { getRevokeLicenceDetails } from 'app/api/requests/revoke-license-requests';

type DetailsRevokeLicenseRequestParams = {
  licenseRequestStore: RevokeLicenseRequestStore;
  apiClient: IHttpClient;
  notify: INotifications;
};

export class DetailsRevokeLicenseRequestService {
  private licenseRequest: ObservableRequest<
    TRevokeLicenseRequest['request']['id'],
    TRevokeLicenseRequest
  >;

  public get isLoading() {
    return this.licenseRequest.isLoading;
  }
  public get hasError() {
    return this.licenseRequest.hasError;
  }

  constructor({ licenseRequestStore, apiClient, notify }: DetailsRevokeLicenseRequestParams) {
    const request = (id: TRevokeLicenseRequest['request']['id']) =>
      apiClient.fetch<TRevokeLicenseRequest>(getRevokeLicenceDetails(id));

    this.licenseRequest = new ObservableRequest(request, {
      done: (licenseRequest) => licenseRequestStore.setData(licenseRequest),
      fail: (error) => notify.addMessage({ type: 'error', message: error.message }),
    });

    makeAutoObservable(this);
  }

  fetchLicenseRequest(id: TRevokeLicenseRequest['request']['id']) {
    this.licenseRequest.fetch(id);
  }
}
