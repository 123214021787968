import { makeAutoObservable } from 'mobx';
import { INotifications } from 'core/entities/notifications';
import { ObservableRequest } from 'app/tools/observable-request';
import { IHttpClient } from 'app/libs/http-client/types';
import { BankStore } from '../bank.store';
import { TBank, TBankUpdated } from 'core/entities/bank';
import { updateBankRequest } from 'app/api/requests/banks';

type UpdateBankServiceParams = {
  apiClient: IHttpClient;
  bankStore: BankStore;
  notify: INotifications;
};

export class UpdateBankService {
  private updateBankRequest: ObservableRequest<Omit<TBankUpdated, 'id'>, TBank>;

  public get status() {
    return this.updateBankRequest.status;
  }

  public get isLoading() {
    return this.updateBankRequest.isLoading;
  }

  private bankStore: BankStore;
  private notify: INotifications;

  constructor({ bankStore, apiClient, notify }: UpdateBankServiceParams) {
    this.bankStore = bankStore;
    this.notify = notify;

    const request = (updatedBank: Omit<TBankUpdated, 'id'>) => {
      const bankData = { id: bankStore.data.id, ...updatedBank };

      return apiClient.fetch<TBank>(updateBankRequest(bankData));
    };

    this.updateBankRequest = new ObservableRequest(request, {
      done: (bank) => this.updateBank(bank),
      fail: (error) => this.addError(error.message),
    });

    makeAutoObservable(this);
  }

  public async updateData(updatedBank: Omit<TBankUpdated, 'id'>) {
    this.updateBankRequest.fetch(updatedBank);
  }

  private updateBank(bank: TBank) {
    this.bankStore.setData(bank);

    this.notify.addMessage({
      type: 'success',
      message: 'Данные банка были обновлены',
    });
  }

  private addError(error: string) {
    this.notify.addMessage({ type: 'error', message: error });
  }
}
