import { makeAutoObservable } from 'mobx';
import { INotifications } from 'core/entities/notifications';
import { ObservableRequest } from 'app/tools/observable-request';
import { IHttpClient } from 'app/libs/http-client/types';
import { generatePath, NavigateFunction } from 'react-router-dom';
import { changeLicenseRequestDetailsRoute } from 'app/pages/routes';
import { UserStore } from 'app/features/users/user.store';
import {
  TChangeLicenseRequest,
  TChangeLicenseRequestNew,
} from 'core/entities/change-license-request';
import { addChangeLicenceRequestRequest } from 'app/api/requests/change-license-requests';

type AddChangeLicenseRequestServiceParams = {
  apiClient: IHttpClient;
  userStore: UserStore;
  navigate: NavigateFunction;
  notify: INotifications;
};

export class AddChangeLicenseRequestService {
  private addChangeLicenseRequest: ObservableRequest<
    TChangeLicenseRequestNew,
    TChangeLicenseRequest
  >;

  public get status() {
    return this.addChangeLicenseRequest.status;
  }

  public get isLoading() {
    return this.addChangeLicenseRequest.isLoading;
  }

  private notify: INotifications;
  private navigate: NavigateFunction;

  constructor({ apiClient, notify, navigate, userStore }: AddChangeLicenseRequestServiceParams) {
    this.notify = notify;
    this.navigate = navigate;

    const request = (newRequest: TChangeLicenseRequestNew) => {
      const requestData = { ...newRequest, bank: userStore.data.bank };

      return apiClient.fetch<TChangeLicenseRequest>(addChangeLicenceRequestRequest(requestData));
    };

    this.addChangeLicenseRequest = new ObservableRequest(request, {
      done: (data) => this.requestCreated(data.id),
      fail: (error) => this.addError(error.message),
    });

    makeAutoObservable(this);
  }

  public async createLicenseRequest(licenceRequest: TChangeLicenseRequestNew) {
    this.addChangeLicenseRequest.fetch(licenceRequest);
  }

  private requestCreated(requestId: TChangeLicenseRequest['id']) {
    this.navigate(
      generatePath(changeLicenseRequestDetailsRoute.path, { requestId: String(requestId) }),
    );
  }

  private addError(error: string) {
    this.notify.addMessage({ type: 'error', message: error });
  }
}
