import { TBankLicense } from 'core/entities/bank-license';
import { ICollectionStore } from 'core/entities/collection-store';

export class BankLicenseCollectionStore implements ICollectionStore<TBankLicense> {
  public items: TBankLicense[] = [];

  public fillData(items: TBankLicense[]): void {
    this.items = items;
  }
}
