import { ObservableRequest } from 'app/tools/observable-request';

import { INotifications } from 'core/entities/notifications';
import { makeAutoObservable } from 'mobx';

import { IHttpClient } from 'app/libs/http-client/types';
import { BankLicenseStore } from '../bank-license.store';
import { TBankLicense } from 'core/entities/bank-license';
import { updateLicenceRequest } from 'app/api/requests/bank-licenses';
import { TTerminal } from 'core/entities/terminal';

type СhangeableFields = Extract<keyof TBankLicense, 'inn' | 'blocked'>;

type UpdateBankLicenseParams = {
  bankLicenseStore: BankLicenseStore;
  apiClient: IHttpClient;
  notify: INotifications;
};

export class UpdateBankLicenseService {
  private updateLicense: ObservableRequest<TBankLicense, TBankLicense>;

  public get isLoading() {
    return this.updateLicense.isLoading;
  }

  private bankLicenseStore: BankLicenseStore;

  constructor({ bankLicenseStore, apiClient, notify }: UpdateBankLicenseParams) {
    this.bankLicenseStore = bankLicenseStore;

    const request = (license: TBankLicense) =>
      apiClient.fetch<TBankLicense>(updateLicenceRequest(license));

    this.updateLicense = new ObservableRequest(request, {
      done: (license) => bankLicenseStore.setData(license),
      fail: (error) => notify.addMessage({ type: 'error', message: error.message }),
    });

    makeAutoObservable(this);
  }

  changeField<F extends СhangeableFields, V extends TBankLicense[F]>(name: F, value: V) {
    const patchedLicense: TBankLicense = {
      ...this.bankLicenseStore.data,
      [name]: value,
    };

    this.updateLicense.fetch(patchedLicense);
  }

  public updatePosConfig(config: TTerminal['posConfig']) {
    // const patchedLicenseRequest: TBankLicense = {
    //   ...this.licenseRequestStore.data,
    //   terminal: { ...this.licenseRequestStore.data.terminal, posConfig: config },
    // };
    // this.updateLicense.fetch(patchedLicenseRequest);
  }

  public resetLicense() {
    const patchedLicense: TBankLicense = {
      ...this.bankLicenseStore.data,
      terminal: null,
    };

    this.updateLicense.fetch(patchedLicense);
  }
}
