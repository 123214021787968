import { makeAutoObservable } from 'mobx';
import { UserStore } from 'app/features/users/user.store';
import { ObservableRequest } from 'app/tools/observable-request';
import { TUser } from 'core/entities/user';
import { getUserRequest } from 'app/api/requests/users';
import { IHttpClient } from 'app/libs/http-client/types';
import { Fail } from 'core/data/fail';

type AuthVerifyParams = {
  apiClient: IHttpClient;
  currentUserStore: UserStore;
};

export class AuthVerifyService {
  public userRequest: ObservableRequest<void, TUser>;

  public get errorMsg() {
    return this.error ? this.error.message : '';
  }

  public error: Fail | null = null;

  constructor({ currentUserStore, apiClient }: AuthVerifyParams) {
    const request = () => apiClient.fetch<TUser>(getUserRequest());

    this.userRequest = new ObservableRequest(request, {
      done: (user) => currentUserStore.setData(user),
      fail: (error) => this.setError(error),
    });

    makeAutoObservable(this);
  }

  public async checkCurrentUser() {
    this.userRequest.fetch();
  }

  private setError(error: Fail) {
    this.error = error;
  }
}
