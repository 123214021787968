import { Badge, DatePicker, Input, InputNumber, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { SelectAvailableBank } from 'app/features/banks/all-banks/select-available-banks';
import { bankDetailsRoute, licensesDetailsRoute } from 'app/pages/routes';
import { TBank } from 'core/entities/bank';
import { TBankLicense } from 'core/entities/bank-license';
import { TColumn } from 'core/entities/columns';
import { FormItemAdapter } from 'libs/form-item-adapter';
import { generatePath, Link } from 'react-router-dom';
import { licenseKeySeparate } from '../ui/license-key-separate';

export const bankLicensesColumns: TColumn<TBankLicense>[] = [
  {
    key: 'id',
    title: 'ID',
    dataIndex: 'id',
    sorter: {
      multiple: 1,
    },
    width: '1%',
    available: true,
    visible: true,
  },
  {
    key: 'blocked',
    title: 'Статус',
    dataIndex: 'blocked',
    sorter: {
      multiple: 2,
    },
    available: true,
    visible: true,
    render: (_, record) =>
      record.blocked ? (
        <Badge status="error" text="Заблокирована" />
      ) : (
        <Badge status="success" text="Активна" />
      ),
  },
  {
    key: 'bank',
    title: 'Банк',
    dataIndex: 'bank',
    sorter: {
      multiple: 2,
    },
    available: true,
    visible: true,
    render: (_, record) => (
      <Link to={generatePath(bankDetailsRoute.path, { bankId: String(record.bank.id) })}>
        {record.bank.name}
      </Link>
    ),
  },
  {
    key: 'terminal',
    title: 'Терминал',
    dataIndex: 'terminal',
    sorter: {
      multiple: 3,
    },
    available: true,
    visible: true,
    // render: (_, record) =>
    //   record.terminal ? (
    //     <Link
    //       to={generatePath(terminalDetailsRoute.path, {
    //         terminalId: String(record.terminal.id),
    //       })}
    //       target="_blank"
    //     >
    //       {record.terminal.dsn}
    //     </Link>
    //   ) : (
    //     'Отсутствует'
    //   ),

    render: (_, record) => (record.terminal ? record.terminal.dsn : 'Отсутствует'),
  },
  {
    key: 'dsn',
    title: 'DSN',
    dataIndex: 'dsn',
    sorter: {
      multiple: 4,
    },
    available: true,
    visible: true,
  },
  {
    key: 'date',
    title: 'Дата',
    dataIndex: 'date',
    sorter: {
      multiple: 5,
    },
    available: true,
    visible: true,
    render: (_, record) => new Date(record.date).toLocaleString(),
  },
  {
    key: 'inn',
    title: 'ИНН',
    dataIndex: 'inn',
    available: true,
    visible: false,
  },
  {
    key: 'key',
    title: 'Ключ лицензии',
    dataIndex: 'key',
    available: true,
    visible: false,
    render: (_, record) => (record.key ? licenseKeySeparate(record.key) : 'Отсутствует'),
  },
  {
    key: 'details',
    title: 'Подробнее',
    dataIndex: 'details',
    available: true,
    visible: true,
    render: (_, record) => (
      <Link
        to={generatePath(licensesDetailsRoute.path, {
          licenseId: String(record.id),
        })}
      >
        Подробнее
      </Link>
    ),
  },
];

export const bankLicensesFilters: TColumn<TBankLicense>[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    available: true,
    visible: true,
    filtrable: true,
    filterRender: (props) => <InputNumber {...props} />,
  },
  {
    title: 'DSN',
    dataIndex: 'dsn',
    available: true,
    visible: true,
    filtrable: true,
    filterRender: (props) => (
      <MaskedInput
        mask={/^[0-9]+$/}
        onPaste={(e: any) => e.preventDefault()}
        // ^ fix bug (double value) when paste value to field (https://github.com/antoniopresto/antd-mask-input/issues/55#issuecomment-1207435176)
        style={{ width: '100%' }}
        {...props}
      />
    ),
  },
  {
    title: 'Начальная дата',
    dataIndex: 'dateFrom',
    available: true,
    visible: true,
    filtrable: true,
    filterRender: (props) => <DatePicker showTime {...props} />,
  },
  {
    title: 'Конечная дата',
    dataIndex: 'dateTo',
    available: true,
    visible: true,
    filtrable: true,
    filterRender: (props) => <DatePicker showTime {...props} />,
  },
  {
    title: 'Ключ',
    dataIndex: 'key',
    available: true,
    visible: true,
    filtrable: true,
    filterRender: (props) => <Input {...props} />,
  },
  {
    title: 'Статус',
    dataIndex: 'blocked',
    available: true,
    visible: true,
    filtrable: true,
    filterRender: (props) => (
      <Select>
        <Select.Option value={false}>
          <Badge status="success" text="Активна" />
        </Select.Option>
        <Select.Option value={true}>
          <Badge status="error" text="Заблокирована" />
        </Select.Option>
      </Select>
    ),
  },
  {
    title: 'Банк',
    dataIndex: 'bankId',
    available: true,
    visible: true,
    filtrable: true,
    perms: ['PERM_BANK_LICENCE_REQUEST_APPLY'],
    filterRender: () => (
      <FormItemAdapter onChangeFormat={(bank: TBank) => bank.id} valueFormat={(v) => v}>
        {(_, onChange) => <SelectAvailableBank onChange={onChange} />}
      </FormItemAdapter>
    ),
  },
];
