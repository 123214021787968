import { isAuthRequiredEvent } from 'app/api/events/is-auth-required';
import { IHttpClient } from 'app/libs/http-client/types';
import { makeAutoObservable } from 'mobx';
import React from 'react';

const storeContext = React.createContext<AuthAlertStore | undefined>(undefined);

export const AuthAlertStoreProvider = storeContext.Provider;

export const useAuthAlertStore = () => {
  const store = React.useContext(storeContext);

  if (store === undefined) {
    throw new Error('useCurrentUserStore must be within CurrentUserStoreProvider');
  }

  return store;
};

type AuthAlertStoreParams = {
  apiClient: IHttpClient;
};
export class AuthAlertStore {
  status: 'auth-required' | 'authenticated' = 'authenticated';

  constructor({ apiClient }: AuthAlertStoreParams) {
    apiClient.on(isAuthRequiredEvent, () => this.authRequired());

    makeAutoObservable(this);
  }

  authRequired() {
    this.status = 'auth-required';
  }

  authenticated() {
    this.status = 'authenticated';
  }
}
