import { useState } from 'react';
import { Modal, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import { apiClient } from 'app/api';
import { Notifications, NotificationsStore } from 'app/features/notifications/notifications';
import { AuthAlertStore, AuthAlertStoreProvider } from './auth-alert.store';
import { SignInForm } from '../sign-in/ui/sign-in-form';
import { SignInService } from '../sign-in/sign-in.service';

type AuthAlertProps = { children: React.ReactNode };

export const AuthAlert = observer(({ children }: AuthAlertProps) => {
  const [authState] = useState(() => new AuthAlertStore({ apiClient }));
  const [notify] = useState(() => new NotificationsStore());
  const [signIn] = useState(() => {
    const service = new SignInService({
      apiClient,
      notify,
      onFinish: () => authState.authenticated(),
    });

    return service;
  });

  const isAuthRequired = authState.status === 'auth-required';

  return (
    <AuthAlertStoreProvider value={authState}>
      {children}
      <Modal
        title="Необходима авторизация"
        visible={isAuthRequired}
        closable={false}
        footer={null}
        width={320}
      >
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Notifications messages={notify.messages} onClose={(msg) => notify.removeMessage(msg)} />

          <SignInForm
            loading={signIn.isLoading}
            onSubmit={(credentials) => signIn.sendCredentials(credentials)}
          />
        </Space>
      </Modal>
    </AuthAlertStoreProvider>
  );
});
