import { createMockHandler } from 'app/api/create-mock-handler';
import { RequestConfig } from 'app/api/types';
import { TBankLicense, TBankLicenseConstraints } from 'core/entities/bank-license';
import { createRequest } from '../create-request';

const config: RequestConfig = {
  method: 'post',
  url: '/tms-ws/api/get_licences',
};

export const getLicencesRequest = (constraints?: TBankLicenseConstraints) =>
  createRequest({
    url: config.url,
    method: config.method,
    body: JSON.stringify(constraints),
  });

const data: TBankLicense[] = [
  {
    id: 1,
    dsn: '32232323',
    bank: { id: 1, name: 'Тестовый' },
    requestId: 1,
    terminal: {
      id: 1,
      bankLicence: {},
      blocked: false,
      data: '',
      dsn: '000000',
      type: { id: 1, name: 'Test' },
      zn: '0000000',
      posConfig: { host: 'test.com', https: true, password: '', port: 0, type: 1 },
    },
    date: new Date().toJSON(),
    dateExpires: new Date().toJSON(),
    inn: '000000',
    key: '0000000000',
    blocked: false,
  },
  {
    id: 2,
    dsn: '666666565656',
    bank: { id: 1, name: 'Тестовый' },
    requestId: 1,
    terminal: {
      id: 2,
      bankLicence: {},
      blocked: false,
      data: '',
      dsn: '000000',
      type: { id: 1, name: 'Test' },
      zn: '0000000',
      posConfig: { host: 'test.com', https: true, password: '', port: 0, type: 1 },
    },
    date: new Date().toJSON(),
    dateExpires: new Date().toJSON(),
    inn: '000000',
    key: '1111111111',
    blocked: false,
  },
  {
    id: 3,
    bank: { id: 1, name: 'Тестовый' },
    dsn: '0111111',
    requestId: 1,
    terminal: {
      id: 3,
      bankLicence: {},
      blocked: true,
      data: '',
      dsn: '000000',
      type: { id: 1, name: 'Test' },
      zn: '0000000',
      posConfig: { host: 'test.com', https: true, password: '', port: 0, type: 1 },
    },
    date: new Date().toJSON(),
    dateExpires: new Date().toJSON(),
    inn: '000000',
    key: '1111111111',
    blocked: true,
  },
];

export const getLicencesRequestMockHandler = createMockHandler<TBankLicense[]>({
  url: config.url,
  method: config.method,
  status: 200,
  data,
});
