import { Alert, Button, Card, Descriptions, Form, Input, Row, Space } from 'antd';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { apiClient } from 'app/api';
import { DetailsBankService } from 'app/features/banks/details-bank/details-banks.service';
import { Notifications, NotificationsStore } from 'app/features/notifications/notifications';
import { AppPage } from 'app/ui/app-page';
import { observer } from 'mobx-react-lite';
import { TBank } from 'core/entities/bank';
import { BankStore } from 'app/features/banks/bank.store';
import { UpdateBankService } from 'app/features/banks/update-bank/update-bank.service';
import { useUserStore } from 'app/features/users/user.store';
import { permissions } from 'app/features/users/permissions';
import { Loading } from 'app/ui/loading';

export const DetailsBankPage = observer(() => {
  const { bankId } = useParams<{ bankId: string }>();
  const [bankStore] = useState(() => new BankStore(Number(bankId)));
  const [notify] = useState(() => new NotificationsStore());

  const [bankService] = useState(() => new DetailsBankService({ apiClient, bankStore, notify }));

  const [updateBankServive] = useState(
    () => new UpdateBankService({ apiClient, bankStore, notify }),
  );

  useEffect(() => {
    bankService.fetchBank(Number(bankId));
  }, [bankId, bankService]);

  const bank = bankStore.data;

  const [form] = Form.useForm<TBank>();

  useEffect(() => {
    form.setFieldsValue({ name: bank.name });
  }, [bank.name, form]);

  const userStore = useUserStore();
  const isBankEditable = userStore.canAccess(permissions.PERM_BANK_UPDATE);
  const isLoading = bankService.isLoading || updateBankServive.isLoading;

  if (!Number.isInteger(Number(bankId))) {
    return (
      <AppPage header="Информация о банке">
        <Alert
          type="error"
          message={
            <span>
              Некорректный ID банка: <b>{bankId}</b>
            </span>
          }
          description="Проверьте ID банка в строке браузера"
          showIcon
        />
      </AppPage>
    );
  }

  if (!bankService.isLoading && bankService.hasError) {
    return (
      <AppPage header="Информация о банке">
        <Notifications messages={notify.messages} onClose={(m) => notify.removeMessage(m)} />
      </AppPage>
    );
  }

  return (
    <AppPage header="Информация о банке">
      <Space direction="vertical" style={{ display: 'flex' }}>
        <Notifications messages={notify.messages} onClose={(m) => notify.removeMessage(m)} />

        {isLoading ? (
          <Card size="small">
            <Loading isActive />
          </Card>
        ) : (
          <Card size="small">
            <Form form={form} onFinish={(bank) => updateBankServive.updateData(bank)}>
              <Descriptions bordered size="small" column={1}>
                <Descriptions.Item
                  label="ID банка"
                  labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                >
                  {bank.id}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Название банка"
                  labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                >
                  {isBankEditable ? (
                    <Form.Item name="name" noStyle>
                      <Input />
                    </Form.Item>
                  ) : (
                    bank.name
                  )}
                </Descriptions.Item>

                {/* <Descriptions.Item
            label="Заявок лицензии"
            labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
            >
            3 | <a href="/#">Посмотреть все</a>
          </Descriptions.Item> */}
              </Descriptions>

              {isBankEditable ? (
                <Row justify="end" style={{ marginTop: 16 }}>
                  <Button htmlType="submit" type="primary" loading={updateBankServive.isLoading}>
                    Сохранить
                  </Button>
                </Row>
              ) : null}
            </Form>
          </Card>
        )}
      </Space>
    </AppPage>
  );
});
