import { useCallback, useEffect, useState } from 'react';
import { Card, Space } from 'antd';
import { observer } from 'mobx-react-lite';

import { Notifications, NotificationsStore } from 'app/features/notifications/notifications';

import { Loading } from 'app/ui/loading';
import { PageContent } from 'UI/page-content';
import { PageHeader } from 'UI/page-header';
import { FilterColumns } from 'app/ui/filter-columns/filter-columns';

import { BaseTable } from 'app/ui/base-table';
import { TUserConstraints } from 'core/entities/user';
import { AllUsersService } from 'app/features/users/all-users/all-users.service';
import { userColumns } from 'app/features/users/all-users/users-column';
import { apiClient } from 'app/api';
import { TBaseConstraints } from 'app/ui/base-table/base-table';
import { ConstraintsStore } from 'app/features/constraints/constraints.store';

export const AllUsersPage = observer(() => {
  const [notify] = useState(() => new NotificationsStore());
  const [constraints] = useState(() => new ConstraintsStore<TUserConstraints>());

  const [allUsers] = useState(() => {
    const service = new AllUsersService({ apiClient, notify, constraints });

    return service;
  });

  useEffect(() => {
    allUsers.fetchUsers();
  }, [allUsers]);

  const onChangeFilter = useCallback((filters) => allUsers.filterRecords(filters), [allUsers]);

  const onChangeConstraints = useCallback(
    (constraints: TBaseConstraints<TUserConstraints>) => {
      allUsers.changeConstraints(constraints);
    },
    [allUsers],
  );

  return (
    <>
      <PageHeader
        header="Все пользователей"
        subheader="Просмотр и управления доступными пользователями"
      />

      <PageContent>
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Notifications messages={notify.messages} onClose={(msg) => notify.removeMessage(msg)} />

          <FilterColumns columns={userColumns} onChange={onChangeFilter} />

          <Card size="small">
            <Loading isActive={allUsers.isLoading}>
              <BaseTable
                columns={userColumns}
                dataSource={allUsers.users}
                paging={constraints.data.pageInfo}
                onChange={onChangeConstraints}
                persistentId="all-users-list"
              />
            </Loading>
          </Card>
        </Space>
      </PageContent>
    </>
  );
});
