import { Col, Form, Input, Row, Switch } from 'antd';

export const UserFields = () => (
  <>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item name="login" label="Логин">
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="password" label="Пароль">
          <Input.Password />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={16}>
      <Col span={12}>
        <Form.Item name="blocked" label="Блокировка пользователя" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Col>
    </Row>
  </>
);
