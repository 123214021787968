import { ReactNode } from 'react';
import styles from './page-content.module.css';

type PageContentProps = {
  children: ReactNode | ReactNode[];
};

export const PageContent = ({ children }: PageContentProps) => {
  return <div className={styles.container}>{children}</div>;
};
