import { DeleteOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Collapse, Divider, Form, Input, Select, Space } from 'antd';
import { TColumn } from 'core/entities/columns';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useCallback, useMemo, useState } from 'react';

type FilterColumnsProps<T> = {
  columns: {
    title: TColumn<T>['title'];
    dataIndex: TColumn<T>['dataIndex'];
    filtrable?: TColumn<T>['filtrable'];
    filterRender?: TColumn<T>['filterRender'];
  }[];
  opened?: boolean;
  onChange: (filters: Record<string, keyof T>) => void;
};

type FiltersState<T> = {
  title: TColumn<T>['title'];
  dataIndex: string;
  value: string;
  filterRender?: (props: { style?: CSSProperties }) => JSX.Element;
};

export const FilterColumns = observer(function <T>({
  columns,
  opened = false,
  onChange,
}: FilterColumnsProps<T>) {
  const [addFilterForm] = Form.useForm();
  const [filtersForm] = Form.useForm();
  const [filters, setFilters] = useState<FiltersState<T>[]>([]);
  const hasFilters = filters.length > 0;

  const onAddFilter = useCallback(
    ({ dataIndex }: { dataIndex: string }) => {
      const col = columns.find((col) => col.dataIndex === dataIndex);
      const filter = {
        dataIndex,
        value: '',
        title: col?.title || '',
        filterRender: col?.filterRender || (() => <Input />),
      };

      setFilters((state) => state.concat(filter));
      addFilterForm.resetFields();
    },
    [addFilterForm, columns],
  );

  const onRemoveFilter = useCallback(
    (event) => {
      const currentDataIndex = event.currentTarget.getAttribute('data-index');

      setFilters((state) =>
        state.filter((activeFilter) => activeFilter.dataIndex !== currentDataIndex),
      );
      filtersForm.resetFields([currentDataIndex]);
    },
    [filtersForm],
  );

  // useEffect(() => {
  //   if (filters.length === 0) {
  //     onChange({});
  //     // Q: ^ Why it's here? 🤔
  //     // A: For updates when filter was cleared
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filters.length === 0, onChange]);

  const renderColumnOptions = useMemo(
    () =>
      columns.length > 0 &&
      columns.map(
        (col) =>
          col.filtrable &&
          !filters.find((filter) => filter.dataIndex === col.dataIndex) && (
            <Select.Option value={col.dataIndex} key={col.dataIndex}>
              {col.title}
            </Select.Option>
          ),
      ),
    [columns, filters],
  );

  const DEFAULT_KEY = '1';

  return (
    <Collapse
      bordered={false}
      style={{ background: 'white', border: '1px solid #F0F0F0' }}
      defaultActiveKey={opened ? DEFAULT_KEY : ''}
    >
      <Collapse.Panel header="Фильтры" key={DEFAULT_KEY}>
        <Card size="small" bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}>
          {/* - - - - - - */}
          <Form form={addFilterForm} layout="horizontal" onFinish={onAddFilter}>
            <Space style={{ padding: '24px 12px 0' }}>
              <Form.Item
                name="dataIndex"
                label="Колонка"
                rules={[{ required: true, message: 'Выберите колонку' }]}
              >
                <Select placeholder="Выберите колонку" style={{ minWidth: 200 }}>
                  {renderColumnOptions}
                </Select>
              </Form.Item>

              <Form.Item label={' '} colon={false}>
                <Button icon={<PlusOutlined />} htmlType="submit">
                  Добавить фильтр
                </Button>
              </Form.Item>
            </Space>
          </Form>

          {hasFilters && <Divider style={{ margin: '12px 0' }} />}

          <Form
            form={filtersForm}
            onFinish={onChange}
            style={{
              width: '100%',
              display: 'table',
              borderCollapse: 'separate',
              borderSpacing: 12,
            }}
          >
            {filters.map((filter) => (
              <div style={{ padding: '0 12px', display: 'table-row' }} key={filter.dataIndex}>
                <span
                  style={{
                    display: 'table-cell',
                    width: 1,
                    textAlign: 'right',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {filter.title}:
                </span>
                <div style={{ display: 'table-cell', width: 250 }}>
                  <Form.Item
                    name={filter.dataIndex}
                    initialValue={filter.value}
                    rules={[{ required: true, message: 'Пожалуйста, заполните поле' }]}
                  >
                    {typeof filter.filterRender === 'function' ? (
                      filter.filterRender({ style: { minWidth: '250px' } })
                    ) : (
                      <Input style={{ minWidth: '250px' }} />
                    )}
                  </Form.Item>
                </div>
                <div style={{ display: 'table-cell' }}>
                  <Button
                    icon={<DeleteOutlined />}
                    data-index={filter.dataIndex}
                    onClick={onRemoveFilter}
                  >
                    Удалить
                  </Button>
                </div>
              </div>
            ))}
          </Form>

          <Divider style={{ margin: '6px 0 12px' }} />

          <Space style={{ padding: '0 12px' }}>
            <Button
              icon={<DeleteOutlined />}
              onClick={() => {
                addFilterForm.resetFields();
                filtersForm.resetFields();
                setFilters([]);
                onChange({});
              }}
              disabled={!hasFilters}
            >
              Очистить
            </Button>

            <Button
              type="primary"
              disabled={!hasFilters}
              icon={<FilterOutlined />}
              onClick={filtersForm.submit}
            >
              Применить
            </Button>
          </Space>
        </Card>
      </Collapse.Panel>
    </Collapse>
  );
});
