import { createMockHandler } from 'app/api/create-mock-handler';
import { RequestConfig } from 'app/api/types';
import { TPosConfig } from 'core/entities/pos-config';
import { createRequest } from '../create-request';

const config: RequestConfig = {
  method: 'post',
  url: '/tms-ws/api/update_default_pos_conf',
};

export const updateDefaultPosConfRequest = (posConfig: TPosConfig) =>
  createRequest({
    url: config.url,
    method: config.method,
    body: JSON.stringify(posConfig),
  });

const data: TPosConfig = {
  https: false,
  host: 'example.com',
  port: 4242,
  password: '',
  type: 2,
};

export const updateDefaultPosConfMockHandler = createMockHandler<TPosConfig>({
  url: config.url,
  method: config.method,
  status: 200,
  data,
});
