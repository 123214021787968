import { useState } from 'react';
import { Card, Layout, Row, Space } from 'antd';
import { SignInForm } from 'app/features/auth/sign-in/ui/sign-in-form';
import { observer } from 'mobx-react-lite';
import { SignInService } from 'app/features/auth/sign-in/sign-in.service';
import { Notifications, NotificationsStore } from 'app/features/notifications/notifications';
import { apiClient } from 'app/api';

const { Content } = Layout;

const reloadPage = () => window.location.reload();

const Title = () => <div style={{ textAlign: 'center' }}>Вход в панель управления</div>;

export const SignInPage = observer(() => {
  const [notify] = useState(() => new NotificationsStore());
  const [signInService] = useState(() => {
    const service = new SignInService({ onFinish: reloadPage, apiClient, notify });

    return service;
  });

  return (
    <Layout>
      <Content>
        <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
          <Card title={<Title />} hoverable style={{ maxWidth: 320 }}>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
              <Notifications
                messages={notify.messages}
                onClose={(msg) => notify.removeMessage(msg)}
              />

              <SignInForm
                loading={signInService.status === 'pending'}
                onSubmit={(credentials) => signInService.sendCredentials(credentials)}
              />
            </Space>
          </Card>
        </Row>
      </Content>
    </Layout>
  );
});
