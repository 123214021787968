import { makeAutoObservable } from 'mobx';
import { INotifications } from 'core/entities/notifications';
import { ObservableRequest } from 'app/tools/observable-request';
import { IHttpClient } from 'app/libs/http-client/types';
import { generatePath, NavigateFunction } from 'react-router-dom';
import { licensesDetailsRoute } from 'app/pages/routes';
import { addLicenseRequest } from 'app/api/requests/bank-licenses';
import { TBankLicense, TBankLicenseNew } from 'core/entities/bank-license';

type AddBankLicenseServiceParams = {
  apiClient: IHttpClient;
  navigate: NavigateFunction;
  notify: INotifications;
};

export class AddBankLicenseService {
  private addBankRequest: ObservableRequest<TBankLicenseNew, TBankLicense>;

  public get status() {
    return this.addBankRequest.status;
  }

  public get isLoading() {
    return this.addBankRequest.isLoading;
  }

  private notify: INotifications;
  private navigate: NavigateFunction;

  constructor({ apiClient, notify, navigate }: AddBankLicenseServiceParams) {
    this.notify = notify;
    this.navigate = navigate;

    const request = (newBank: TBankLicenseNew) =>
      apiClient.fetch<TBankLicense>(addLicenseRequest(newBank));

    this.addBankRequest = new ObservableRequest(request, {
      done: (license) => this.licenseCreated(license),
      fail: (error) => this.addError(error.message),
    });

    makeAutoObservable(this);
  }

  public async addBank(newBank: TBankLicenseNew) {
    this.addBankRequest.fetch(newBank);
  }

  private licenseCreated(license: TBankLicense) {
    const path = generatePath(licensesDetailsRoute.path, { licenseId: String(license.id) });

    this.navigate(path);
  }

  private addError(error: string) {
    this.notify.addMessage({ type: 'error', message: error });
  }
}
