import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { Space, Card, Descriptions, Button, Modal, Alert, Row, InputNumber, Input } from 'antd';
import { apiClient } from 'app/api';
import { DetailsLicenseRequestService } from 'app/features/license-requests/details-license-request/details-license-request.service';
import { LicenseRequestStore } from 'app/features/license-requests/license-request.store';
import { LicenseRequestStatus } from 'app/features/license-requests/ui/license-request-status';
import { UpdateLicenseRequestService } from 'app/features/license-requests/update-license-request/update-license-request.service';

import { NotificationsStore, Notifications } from 'app/features/notifications/notifications';
import { permissions } from 'app/features/users/permissions';
import { useUserStore } from 'app/features/users/user.store';
import { AppPage } from 'app/ui/app-page';
import { EditableLabel } from 'app/ui/editable-label';
import { ELicenseRequestStatus } from 'core/entities/license-request';
import { Observer, observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Loading } from 'app/ui/loading';
import { bankDetailsRoute } from '../routes';
import { AddDsnField } from 'app/features/license-requests/add-license-request/ui/add-dsn.field';

export const DetailsLicenseRequestsPage = observer(() => {
  const { requestId } = useParams<{ requestId: string }>();
  const [licenseRequestStore] = useState(() => new LicenseRequestStore(Number(requestId)));
  const [notify] = useState(() => new NotificationsStore());

  const [licenseRequestService] = useState(
    () => new DetailsLicenseRequestService({ apiClient, licenseRequestStore, notify }),
  );
  const [updateLicenseRequestService] = useState(
    () => new UpdateLicenseRequestService({ apiClient, licenseRequestStore, notify }),
  );

  useEffect(() => {
    licenseRequestService.fetchLicenseRequest(Number(requestId));
  }, [requestId, licenseRequestService]);

  const userStore = useUserStore();

  const licenseRequest = licenseRequestStore.data;

  const isLoading = licenseRequestService.isLoading || updateLicenseRequestService.isLoading;
  const isInnEditable =
    licenseRequestStore.status === ELicenseRequestStatus.created &&
    userStore.canAccess(permissions['PERM_BANK_LICENCE_REQUEST_UPDATE']);
  const isLicenceRequestEditable = licenseRequestStore.status === ELicenseRequestStatus.created;
  const hasRequestApplyPerm =
    licenseRequestStore.status === ELicenseRequestStatus.created &&
    userStore.canAccess(permissions['PERM_BANK_LICENCE_REQUEST_APPLY']);

  const isStatusDescriptionEditable =
    licenseRequestStore.status === ELicenseRequestStatus.created && hasRequestApplyPerm;
  const isStatusDescriptionVisible =
    licenseRequestStore.statusDescription &&
    licenseRequestStore.status !== ELicenseRequestStatus.created;

  const confirmLicenseRequest = () =>
    Modal.confirm({
      title: 'Подтвердить создание лицензий?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          Будет создано <b>{licenseRequest.licences.length} лицензий</b> для банка{' '}
          <b>{licenseRequest.bank.name}</b>.
          <p>
            Заявка станет недоступна для изменения, а ее статус будет изменен на "Подтверждена"
          </p>
          {isStatusDescriptionEditable && (
            <>
              <span>Комментарий к статусу заявки:</span>
              <Observer>
                {() => (
                  <Input.TextArea
                    showCount
                    maxLength={500}
                    value={licenseRequestStore.statusDescription}
                    onChange={(event) =>
                      licenseRequestStore.setStatusDescription(event.target.value)
                    }
                  />
                )}
              </Observer>
            </>
          )}
        </div>
      ),
      onOk() {
        updateLicenseRequestService.confirmLicenseRequest();
      },
      okText: 'Подтвердить',
    });

  const rejectLicenseRequest = () =>
    Modal.confirm({
      title: 'Отклонить заявку?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          Заявка на создание <b>{licenseRequest.licenceCount} лицензий</b> для банка{' '}
          <b>{licenseRequest.bank.name}</b> будет отклонена.
          <p>
            После этого действия, заявка станет недоступна для изменения, а ее статус будет изменен
            на "Отклонена"
          </p>
          {isStatusDescriptionEditable && (
            <>
              <span>Комментарий к статусу заявки:</span>
              <Observer>
                {() => (
                  <Input.TextArea
                    showCount
                    maxLength={500}
                    value={licenseRequestStore.statusDescription}
                    onChange={(event) =>
                      licenseRequestStore.setStatusDescription(event.target.value)
                    }
                  />
                )}
              </Observer>
            </>
          )}
        </div>
      ),
      onOk() {
        updateLicenseRequestService.rejectLicenseRequest();
      },
      okText: 'Отклонить',
    });

  if (!Number.isInteger(Number(requestId))) {
    return (
      <AppPage header="Просмотр заявки на лицензию" subheader="Отображение данных заявки">
        <Alert
          type="error"
          message={
            <span>
              Некорректный ID заявки: <b>{requestId}</b>
            </span>
          }
          description="Проверьте ID заявки в строке браузера"
          showIcon
        />
      </AppPage>
    );
  }

  if (!licenseRequestService.isLoading && licenseRequestService.hasError) {
    return (
      <AppPage header="Просмотр заявки на лицензию" subheader="Отображение данных заявки">
        <Notifications messages={notify.messages} onClose={(m) => notify.removeMessage(m)} />
      </AppPage>
    );
  }

  return (
    <AppPage header="Просмотр заявки на лицензию" subheader="Отображение данных заявки">
      <Space direction="vertical" style={{ display: 'flex' }}>
        {isLoading ? (
          <Card size="small">
            <Loading isActive />
          </Card>
        ) : (
          <>
            <Card size="small">
              <Descriptions bordered size="small" column={1}>
                <Descriptions.Item
                  label="ID заявки"
                  labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                >
                  {licenseRequest.id}
                </Descriptions.Item>

                <Descriptions.Item
                  label="Статус"
                  labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                >
                  <LicenseRequestStatus status={licenseRequest.status} />
                </Descriptions.Item>

                <Descriptions.Item label="Банк" labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}>
                  <Link
                    to={generatePath(bankDetailsRoute.path, {
                      bankId: String(licenseRequest.bank.id),
                    })}
                  >
                    {licenseRequest.bank.name}
                  </Link>
                </Descriptions.Item>

                <Descriptions.Item
                  label="Дата создания заявки"
                  labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                >
                  {new Date(licenseRequest.date).toLocaleString()}
                </Descriptions.Item>

                <Descriptions.Item label="ИНН" labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}>
                  {(
                    <EditableLabel
                      value={licenseRequest.inn}
                      editable={isInnEditable}
                      onChange={(value: string) =>
                        updateLicenseRequestService.changeField('inn', value)
                      }
                      input={(value: any, onChange: any) => (
                        <InputNumber
                          value={value}
                          onChange={onChange}
                          size="small"
                          controls={false}
                          stringMode={true}
                          style={{ width: '100%' }}
                        />
                      )}
                      validator={(value) => {
                        if (!value) return Promise.reject('Поле не может быть пустым');
                        if (value.length !== 8) return Promise.reject('Введите 8 цифр');

                        return Promise.resolve(value);
                      }}
                    />
                  ) ?? 'Отсутствует'}
                </Descriptions.Item>
              </Descriptions>
            </Card>

            {isStatusDescriptionVisible && (
              <Card size="small" title="Комментарий к статусу заявки">
                {licenseRequestStore.statusDescription}
              </Card>
            )}

            {/* <Card size="small">
              <Descriptions bordered size="small" column={2}>
                <Descriptions.Item
                  label="Запрошено лицензий"
                  contentStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                  labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <EditableLabel
                      label={(value: string) => (
                        <span style={{ fontWeight: 'bold' }}>{value} шт.</span>
                      )}
                      input={(value: string, onChange: any) => (
                        <InputNumber
                          value={value}
                          size="small"
                          min="1"
                          onChange={(value) => onChange(value)}
                        />
                      )}
                      value={licenseRequest.licenceCount}
                      editable={isLicenceCountEditable}
                      onChange={(v: string) =>
                        updateLicenseRequestService.changeField('licenceCount', Number(v))
                      }
                    />
                  </div>
                </Descriptions.Item>

                <Descriptions.Item labelStyle={{ display: 'none' }}>
                  {hasRequestApplyPerm && (
                    <Space>
                      <Button
                        type="primary"
                        icon={<CheckCircleOutlined />}
                        onClick={confirmLicenseRequest}
                      >
                        Подтвердить
                      </Button>

                      <Button
                        type="default"
                        danger={true}
                        icon={<CloseCircleOutlined />}
                        onClick={rejectLicenseRequest}
                      >
                        Отклонить
                      </Button>
                    </Space>
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Card> */}

            <Card size="small">
              <AddDsnField
                value={licenseRequest.licences}
                readOnly={!isLicenceRequestEditable}
                onChange={(licences) => licenseRequestStore.updateLicences(licences)}
              />
            </Card>

            <Notifications messages={notify.messages} onClose={(m) => notify.removeMessage(m)} />

            {isLicenceRequestEditable && (
              <Card size="small">
                <Row justify={hasRequestApplyPerm ? 'space-between' : 'end'}>
                  <Button
                    type={hasRequestApplyPerm ? 'default' : 'primary'}
                    icon={<SaveOutlined />}
                    onClick={() => updateLicenseRequestService.saveRequestFromStore()}
                  >
                    Сохранить
                  </Button>
                  {hasRequestApplyPerm && (
                    <Space>
                      <Button
                        type="primary"
                        icon={<CheckCircleOutlined />}
                        onClick={confirmLicenseRequest}
                      >
                        Подтвердить
                      </Button>

                      <Button
                        type="default"
                        danger={true}
                        icon={<CloseCircleOutlined />}
                        onClick={rejectLicenseRequest}
                      >
                        Отклонить
                      </Button>
                    </Space>
                  )}
                </Row>
              </Card>
            )}
          </>
        )}
      </Space>
    </AppPage>
  );
});
