import { createMockHandler } from 'app/api/create-mock-handler';
import { RequestConfig } from 'app/api/types';
import { TBankLicense, TBankLicenseNew } from 'core/entities/bank-license';
import { createRequest } from '../create-request';

const config: RequestConfig = {
  method: 'post',
  url: '/tms-ws/api/add_licence',
};

export const addLicenseRequest = (newLicense: TBankLicenseNew) =>
  createRequest({
    url: config.url,
    method: config.method,
    body: JSON.stringify(newLicense),
  });

const data: TBankLicense = {
  id: 1,
  bank: {
    id: 1,
    name: 'Тестовый банк',
  },
  dsn: '',
  requestId: 0,
  terminal: {
    id: 1,
    type: {
      id: 1,
      name: 'Тестовый',
    },
    bankLicence: {},
    dsn: '000000',
    zn: '000000',
    blocked: false,
    data: '',
    posConfig: {
      https: false,
      host: 'test.com',
      port: 0,
      password: '',
      type: 1,
    },
  },
  date: new Date().toJSON(),
  dateExpires: new Date().toJSON(),
  inn: '00000000',
  key: '00000000',
  blocked: false,
};

export const addLicenseRequestMockHandler = createMockHandler<TBankLicense>({
  url: config.url,
  method: config.method,
  status: 200,
  data,
});
