import { makeAutoObservable } from 'mobx';

import { IHttpClient } from 'app/libs/http-client/types';
import { INotifications } from 'core/entities/notifications';
import { TCredentials } from 'core/entities/credentials';
import { TLoginSucces } from 'core/entities/login-success';
import { ObservableRequest } from 'app/tools/observable-request';
import { loginRequest } from 'app/api/requests/auth';

type SignInServiceParams = {
  apiClient: IHttpClient;
  notify: INotifications;
  onFinish: () => void;
};

// type RequestStatus = Exclude<RequestState<TLoginSucces>['type'], 'unauthorized'>;

export class SignInService {
  private notify: INotifications;

  public get status() {
    return this.signInRequest.status;
  }

  public get isLoading() {
    return this.signInRequest.isLoading;
  }

  private signInRequest: ObservableRequest<TCredentials, TLoginSucces>;

  constructor({ onFinish, apiClient, notify }: SignInServiceParams) {
    this.notify = notify;

    const request = (credentials: TCredentials) =>
      apiClient.fetch<TLoginSucces>(loginRequest(credentials));

    this.signInRequest = new ObservableRequest(request, {
      done: onFinish,
      fail: (error) => notify.addMessage({ type: 'error', message: error.message }),
    });

    makeAutoObservable(this);
  }

  async sendCredentials(credentials: TCredentials) {
    this.notify.clearAll();
    this.signInRequest.fetch(credentials);
  }
}
