import { createMockHandler } from 'app/api/create-mock-handler';
import { RequestConfig } from 'app/api/types';

import { TCredentials } from 'core/entities/credentials';
import { TLoginSucces } from 'core/entities/login-success';
import { createRequest } from '../create-request';

const config: RequestConfig = {
  method: 'post',
  url: '/tms-ws/api/login',
};

export const loginRequest = (credentials: TCredentials) =>
  createRequest({
    url: config.url,
    method: config.method,
    body: JSON.stringify(credentials),
  });

const data: TLoginSucces = {
  session: '7ede335b-fecf-45ee-a7ef-5295372c95f0',
  user: {
    id: 1,
    blocked: false,
    login: 'admin',
    roles: [],
    bank: { id: 1, name: 'Cбер' },
  },
};

export const loginRequestMockHandler = createMockHandler<TLoginSucces>({
  url: config.url,
  method: config.method,
  status: 200,
  data,
});
