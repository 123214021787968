import { INotifications } from 'core/entities/notifications';
import { createContext, useContext } from 'react';

const storeContext = createContext<INotifications | undefined>(undefined);

export const NotificationsProvider = storeContext.Provider;

export const useNotifyProvider = () => {
  const notify = useContext(storeContext);

  if (notify === undefined) {
    throw new Error('useNotifyProvider must be within NotificationsProvider');
  }

  return notify;
};
