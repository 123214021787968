import { Space, Card, Tabs, Descriptions, Badge, Alert } from 'antd';
import { apiClient } from 'app/api';
import { NotificationsStore, Notifications } from 'app/features/notifications/notifications';
import { UserProfileService } from 'app/features/users/current-user/current-user.service';
import { permissions } from 'app/features/users/permissions';
import { ChangeUserDataService } from 'app/features/users/user-data/change-user-data.service';
import { UserDataForm } from 'app/features/users/user-data/user-data-form';
import { AvailableRolesService } from 'app/features/users/user-roles/available-roles.service';
import { RolesStore } from 'app/features/users/user-roles/roles.store';
import { UserRoles } from 'app/features/users/user-roles/user-roles';
import { UserStore, useUserStore } from 'app/features/users/user.store';
import { AppPage } from 'app/ui/app-page';
import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import { Loading } from 'app/ui/loading';
import { bankDetailsRoute } from '../routes';

export const EditUserPage = observer(() => {
  const { userId } = useParams<{ userId: string }>();

  const [userStore] = useState(() => new UserStore(Number(userId)));

  const [notify] = useState(() => new NotificationsStore());

  const [userProfile] = useState(() => {
    const service = new UserProfileService({ userStore, apiClient, notify });

    return service;
  });

  useEffect(() => {
    userProfile.getUser(Number(userId));
  }, [userProfile, userId]);

  const user = userStore.data;

  const [changeUserData] = useState(() => {
    const service = new ChangeUserDataService({
      userStore,
      apiClient,
      notify,
    });

    return service;
  });

  const [rolesStore] = useState(() => new RolesStore());

  const [availableRoles] = useState(() => {
    const service = new AvailableRolesService({
      apiClient,
      rolesStore,
      notify,
    });

    return service;
  });

  const currentUserStore = useUserStore();

  const isUserEditable = currentUserStore.canAccess(permissions.PERM_USER_UPDATE);

  useEffect(() => {
    availableRoles.getRoles();
  }, [availableRoles]);

  if (!Number.isInteger(Number(userId))) {
    return (
      <AppPage header="Просмотр пользователя" subheader="Изменения ролей и данных учетной записи">
        <Alert
          type="error"
          message={
            <span>
              Некорректный ID лицензии: <b>{userId}</b>
            </span>
          }
          description="Проверьте ID лицензии в строке браузера"
          showIcon
        />
      </AppPage>
    );
  }

  if (!userProfile.isLoading && userProfile.hasError) {
    return (
      <AppPage header="Просмотр пользователя" subheader="Изменения ролей и данных учетной записи">
        <Notifications messages={notify.messages} onClose={(m) => notify.removeMessage(m)} />
      </AppPage>
    );
  }

  return (
    <AppPage header="Просмотр пользователя" subheader="Изменения ролей и данных учетной записи">
      <Space direction="vertical" style={{ display: 'flex' }}>
        <Notifications messages={notify.messages} onClose={(m) => notify.removeMessage(m)} />

        <Card size="small" bodyStyle={{ paddingTop: 0 }}>
          {userProfile.isLoading ? (
            <div style={{ paddingTop: 12 }}>
              <Loading isActive />
            </div>
          ) : (
            <Tabs defaultActiveKey="1" animated={false}>
              <Tabs.TabPane tab="Учетная запись" key="1">
                {isUserEditable ? (
                  <div style={{ maxWidth: '50%' }}>
                    <UserDataForm
                      login={user.login}
                      blocked={user.blocked}
                      loading={changeUserData.isLoading}
                      onSubmit={(fields) => changeUserData.updateData(fields)}
                    />
                  </div>
                ) : (
                  <Descriptions bordered size="small" column={1}>
                    <Descriptions.Item
                      label="Логин"
                      labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                    >
                      {user.login}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Статус"
                      labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                    >
                      {user.blocked ? (
                        <Badge status="error" text="Заблокирован" />
                      ) : (
                        <Badge status="success" text="Активен" />
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Роли и разрешения" key="2">
                {availableRoles.isLoading ? (
                  <Loading isActive />
                ) : (
                  <UserRoles
                    availableRoles={rolesStore.roles}
                    selectedRoles={user.roles}
                    loading={changeUserData.isLoading}
                    onSubmit={
                      isUserEditable
                        ? // @ts-expect-error
                          ({ roles }) => changeUserData.updateData({ roles })
                        : undefined
                    }
                  />
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Банк" key="3">
                Банк пользователя:{' '}
                {user.bank ? (
                  <Link to={generatePath(bankDetailsRoute.path, { bankId: String(user.bank.id) })}>
                    {user.bank.name}
                  </Link>
                ) : (
                  'Отсутствует'
                )}
              </Tabs.TabPane>
            </Tabs>
          )}
        </Card>
      </Space>
    </AppPage>
  );
});
