export class Fail extends Error {
  protected _message: string = '';

  get message() {
    return this._message;
  }

  set message(msg) {
    this._message = msg ?? this._message;
  }
}
