import { TBank } from 'core/entities/bank';
import { TBankLicense } from 'core/entities/bank-license';
import { TRevokeBankLicense, TRevokeLicenseRequest } from 'core/entities/revoke-license-request';

import { makeAutoObservable } from 'mobx';

export class RevokeLicenseRequestStore {
  public data: TRevokeLicenseRequest = {
    request: {
      id: -1,
      bank: {
        id: -1,
        name: '',
      },
      date: '',
      status: 0,
      statusDescription: '',
      licences: [],
    },
    licences: [],
  };

  constructor(id: TBank['id']) {
    this.setId(id);

    makeAutoObservable(this);
  }

  get status() {
    return this.data.request.status;
  }

  get statusDescription() {
    return this.data.request.statusDescription ?? '';
  }

  setId(id: TRevokeLicenseRequest['request']['id']) {
    this.data.request.id = id;
  }

  private checkRevoked(id: TRevokeBankLicense['licenceId'], licenses: TBankLicense[]) {
    const foundLicense = licenses.find((lic) => lic.id === id);
    return foundLicense?.terminal === null;
  }

  setData(licenseRequest: TRevokeLicenseRequest) {
    this.data = licenseRequest;
    this.data.request = licenseRequest.request;

    this.data.request.licences = licenseRequest.request.licences.map((license) => ({
      ...license,
      revoked: this.checkRevoked(license.licenceId, licenseRequest.licences),
    }));
  }

  updateLicenses(licences: TRevokeLicenseRequest['licences']) {
    this.data.licences = licences;
  }

  deleteLicense(license: TRevokeBankLicense) {
    this.data.request.licences = this.data.request.licences.filter(
      (lic) => lic.licenceId !== license.licenceId,
    );
  }

  updateLicense(license: TRevokeBankLicense) {
    const foundIndex = this.data.request.licences.findIndex(
      (lic) => lic.licenceId === license.licenceId,
    );

    this.data.request.licences[foundIndex] = license;
  }

  setStatusDescription(value: TRevokeLicenseRequest['request']['statusDescription']) {
    this.data.request.statusDescription = value;
  }
}
