import { DatePicker, Input, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { SelectAvailableBank } from 'app/features/banks/all-banks/select-available-banks';
import { TBank } from 'core/entities/bank';
import { TColumn } from 'core/entities/columns';
import { ELicenseRequestStatus, TLicenseRequest } from 'core/entities/license-request';
import { FormItemAdapter } from 'libs/form-item-adapter';
import { LicenseRequestStatus } from '../ui/license-request-status';

export const exportLicenseRequestsFilters: TColumn<TLicenseRequest>[] = [
  {
    title: 'ИНН',
    dataIndex: 'inn',
    available: true,
    visible: true,
    filtrable: true,
    filterRender: (props) => <Input {...props} />,
  },
  {
    title: 'DSN',
    dataIndex: 'dsn',
    available: true,
    visible: true,
    filtrable: true,
    filterRender: (props) => (
      <MaskedInput
        mask={/^[0-9]+$/}
        onPaste={(e: any) => e.preventDefault()}
        // ^ fix bug (double value) when paste value to field (https://github.com/antoniopresto/antd-mask-input/issues/55#issuecomment-1207435176)
        style={{ width: '100%' }}
        {...props}
      />
    ),
  },
  {
    title: 'Начальная дата',
    dataIndex: 'dateFrom',
    available: true,
    visible: true,
    filtrable: true,
    filterRender: (props) => <DatePicker showTime {...props} />,
  },
  {
    title: 'Конечная дата',
    dataIndex: 'dateTo',
    available: true,
    visible: true,
    filtrable: true,
    filterRender: (props) => <DatePicker showTime {...props} />,
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    available: true,
    visible: true,
    filtrable: true,
    // filterRender: (props) => <Input {...props} />,
    filterRender: (props) => (
      <Select {...props}>
        <Select.Option key={ELicenseRequestStatus.created} value={ELicenseRequestStatus.created}>
          <LicenseRequestStatus status={ELicenseRequestStatus.created} />
        </Select.Option>
        <Select.Option
          key={ELicenseRequestStatus.confirmed}
          value={ELicenseRequestStatus.confirmed}
        >
          <LicenseRequestStatus status={ELicenseRequestStatus.confirmed} />
        </Select.Option>
        <Select.Option key={ELicenseRequestStatus.rejected} value={ELicenseRequestStatus.rejected}>
          <LicenseRequestStatus status={ELicenseRequestStatus.rejected} />
        </Select.Option>
      </Select>
    ),
  },
  {
    title: 'Банк',
    dataIndex: 'bankId',
    available: true,
    visible: true,
    filtrable: true,
    perms: ['PERM_BANK_LICENCE_REQUEST_APPLY'],
    filterRender: () => (
      <FormItemAdapter onChangeFormat={(bank: TBank) => bank.id} valueFormat={(v) => v}>
        {(_, onChange) => <SelectAvailableBank onChange={onChange} />}
      </FormItemAdapter>
    ),
  },
];
