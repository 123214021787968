import { makeAutoObservable } from 'mobx';

import { TUser } from 'core/entities/user';
import { IHttpClient } from 'app/libs/http-client/types';
import { INotifications } from 'core/entities/notifications';

import { ObservableRequest } from 'app/tools/observable-request';
import { getUserRequest } from 'app/api/requests/users';
import { UserStore } from '../user.store';

type UserProfileServiceParams = {
  apiClient: IHttpClient;
  userStore: UserStore;
  notify: INotifications;
};
export class UserProfileService {
  private getUserRequest: ObservableRequest<TUser['id'], TUser>;

  public get status() {
    return this.getUserRequest.status;
  }
  public get isLoading() {
    return this.getUserRequest.isLoading;
  }
  public get hasError() {
    return this.getUserRequest.hasError;
  }

  constructor({ userStore, apiClient, notify }: UserProfileServiceParams) {
    const request = (id: TUser['id']) => apiClient.fetch<TUser>(getUserRequest(id));

    this.getUserRequest = new ObservableRequest(request, {
      done: (user) => userStore.setData(user),
      fail: (error) => notify.addMessage({ type: 'error', message: error.message }),
    });

    makeAutoObservable(this);
  }

  public async getUser(id: TUser['id']) {
    this.getUserRequest.fetch(id);
  }
}
