import React, { useEffect } from 'react';
import { Empty, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import intersectionWith from 'lodash/intersectionWith';
import uniqBy from 'lodash/uniqBy';

import { extractRolesList, extractPermissions, filterPermissionsByRoles } from './table-utils';
import { TablePermission, TableRoleRecord, TableSelectedRole } from './types';

import styles from './select-roles.module.css';
import { TRole } from 'core/entities/role';

const rolesColumns = [
  {
    title: 'Доступные к добавлению роли',
    dataIndex: 'title',
    key: 'title',
  },
];

const permColumns = ({
  filter,
}: {
  filter: Array<TableRoleRecord>;
}): ColumnsType<TablePermission> => {
  return [
    {
      title: 'Разрешения, которые будут добавлены',
      dataIndex: 'title',
      key: 'title',
      filters: filter.map((role) => ({ text: role.title, value: role.id })),
      onFilter: (value, record) => value === record.roleId,
    },
  ];
};

type FetchedRolesProps = {
  roles?: Array<TRole>;
  selected?: Array<TRole>;
  onChange?: (selectedRoles: Array<TRole>) => void;
};

export const SelectRoles = React.memo(({ roles = [], selected, onChange }: FetchedRolesProps) => {
  const initSelected = React.useMemo(
    () =>
      Array.isArray(selected) && selected.length > 0
        ? intersectionWith(roles, selected, (role, selected) => role.id === selected.id).map(
            (r) => {
              const rowIndex = roles.findIndex((baseRoles) => baseRoles.id === r.id);
              return { roleId: r.id, rowIndex };
            },
          )
        : [],
    [roles, selected],
  );

  const [selectedRoles, setSelectedRoles] = React.useState<Array<TableSelectedRole>>([]);

  const permissions = React.useMemo(() => extractPermissions(roles || []), [roles]);
  const resultPermissions = React.useMemo(
    () => uniqBy(filterPermissionsByRoles(permissions, selectedRoles), (perm) => perm.id),
    [permissions, selectedRoles],
  );

  useEffect(() => {
    setSelectedRoles(initSelected);
  }, [initSelected]);

  return (
    <div className={styles.wrapper}>
      <Table
        className={styles.table}
        size="small"
        bordered
        dataSource={extractRolesList(roles)}
        columns={rolesColumns}
        pagination={false}
        rowClassName={styles.tableRow}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedRoles.map((r) => r.rowIndex),
          onChange: (rowIndexes, records: Array<TableRoleRecord>) => {
            const finalRolesSelected = records.map((r, idx) => ({
              roleId: r.id,
              rowIndex: rowIndexes[idx],
            }));

            const fullDataSelectedRoles = intersectionWith(
              roles,
              finalRolesSelected,
              (role, selected) => role.id === selected.roleId,
            );
            setSelectedRoles(finalRolesSelected);
            onChange && onChange(fullDataSelectedRoles);
          },
        }}
        onRow={(record: TableRoleRecord, rowIndex) => {
          return {
            onClick: () => {
              // По типам rowIndex может быть undefined,
              // но в реальности такое маловероятно.
              const rowIdx = rowIndex ?? -1;
              // Если кликнутая строка уже есть в списке ролей,
              // тогда возвращаем список выбранных строк без этой роли,
              // чтобы сделать ее не активной.
              // Иначе добавляем строку к списку ролей.

              const isRoleFinded = selectedRoles.find((r) => r.roleId === record.id);
              const resultRolesSelected = isRoleFinded
                ? selectedRoles.filter((r) => r.roleId !== record.id)
                : selectedRoles.concat({ roleId: record.id, rowIndex: rowIdx });

              const fullDataSelectedRoles = intersectionWith(
                roles,
                resultRolesSelected,
                (role, selected) => role.id === selected.roleId,
              );

              setSelectedRoles(resultRolesSelected);
              onChange && onChange(fullDataSelectedRoles);
            },
          };
        }}
      />

      <Table
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Выберите роль для отображения ее разрешений"
            />
          ),
        }}
        className={styles.table}
        size="small"
        bordered
        dataSource={resultPermissions}
        columns={permColumns({ filter: extractRolesList(roles) })}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => (
            <div>
              <div>
                <b>Роль:</b> {record.roleTitle}
              </div>
              {record.description && (
                <div>
                  <b>Описание:</b> {record.description}
                </div>
              )}
            </div>
          ),
        }}
      />
    </div>
  );
});
