import { TBankLicenseConstraints } from 'core/entities/bank-license';
import { createRequest } from '../create-request';

type RequesParams = TBankLicenseConstraints;

export const exportLicencesRequest = (constraints?: RequesParams) =>
  createRequest({
    method: 'post',
    url: '/tms-ws/api/export_licences',
    body: JSON.stringify(constraints),
  });
