import { Space, Form, Card, Button, Row, Col, Alert } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiClient } from 'app/api';
import { AddLicenseRequestService } from 'app/features/license-requests/add-license-request/add-license-request.service';
import { Notifications, NotificationsStore } from 'app/features/notifications/notifications';
import { useUserStore } from 'app/features/users/user.store';
import { AppPage } from 'app/ui/app-page';
import { TLicenseRequestNew } from 'core/entities/license-request';
import { Loading } from 'app/ui/loading';
import { AddDsnField } from 'app/features/license-requests/add-license-request/ui/add-dsn.field';
import { observer } from 'mobx-react-lite';
import { Rule } from 'antd/lib/form';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { MaskedInput } from 'app/ui/masked-input';

const dsnFieldRule: Rule[] = [
  {
    required: true,
    message: 'Добавьте как минимум один DSN',
    validateTrigger: 'onFinish',
    type: 'array',
  },
];

const getLicensesError = (errors: ValidateErrorEntity<TLicenseRequestNew>) => {
  const field = errors.errorFields.find((field) => field.name[0] === 'licences');
  const message = field?.errors[0];

  return message;
};
export const AddLicenseRequestPage = observer(() => {
  const navigate = useNavigate();
  const userStore = useUserStore();
  const [notify] = useState(() => new NotificationsStore());

  const [addLicenseRequest] = useState(() => {
    const service = new AddLicenseRequestService({
      apiClient,
      userStore,
      navigate,
      notify,
    });

    return service;
  });

  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  return (
    <AppPage header="Заявка на лицензию" subheader="Создание заявки на лицензию">
      <Form
        layout="vertical"
        onFinish={(request: TLicenseRequestNew) => {
          setErrorMsg(null);

          addLicenseRequest.createLicenseRequest({
            ...request,
            // @ts-expect-error
            licenceCount: request.licences.length,
          });
        }}
        onFinishFailed={(error) => {
          const licenseError = getLicensesError(error);
          setErrorMsg(licenseError || '');
        }}
        initialValues={{ inn: '', licences: [] }}
        // scrollToFirstError={{ behavior: 'smooth' }}
      >
        <Loading isActive={addLicenseRequest.isLoading}>
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Card size="small" title="Данные заявки">
              <div>
                {/* <h2>Данные заявки</h2> */}
                <div>
                  {/* <Form.Item
                    label="Количество лицензий"
                    name="licenceCount"
                    rules={[
                      {
                        required: true,
                        message: 'Добавьте минимум одну лицензию',
                      },
                    ]}
                    help={<span>Для создания заявки укажите необходимое количество лицензий</span>}
                  >
                    <InputNumber style={{ width: '50%' }} min={1} />
                  </Form.Item> */}

                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        label="ИНН"
                        name="inn"
                        validateFirst={true}
                        rules={[
                          {
                            required: true,
                            message: 'Укажите ИНН',
                          },
                          { len: 8, message: 'Введите 8 цифр' },
                        ]}
                      >
                        <MaskedInput mask="00000000" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>

            <Card size="small" title="Номера устройств">
              <Form.Item name="licences" noStyle rules={dsnFieldRule}>
                <AddDsnField
                // onChange={handleDsnChanged}
                />
              </Form.Item>
            </Card>

            <Notifications
              messages={notify.messages}
              onClose={(msg) => notify.removeMessage(msg)}
            />

            {errorMsg && (
              <Alert
                type="error"
                message={errorMsg}
                closable={true}
                afterClose={() => setErrorMsg(null)}
              />
            )}

            <Card size="small">
              <div style={{ display: 'flex' }}>
                <Button type="primary" htmlType="submit" style={{ marginLeft: 'auto' }}>
                  Отправить заявку
                </Button>
              </div>
            </Card>
          </Space>
        </Loading>
      </Form>
    </AppPage>
  );
});
