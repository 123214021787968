import { useEffect, useState } from 'react';
import { Card, Space, Tabs } from 'antd';
import { observer } from 'mobx-react-lite';

import { UserProfileService } from 'app/features/users/current-user/current-user.service';
import { AvailableRolesService } from 'app/features/users/user-roles/available-roles.service';
import { RolesStore } from 'app/features/users/user-roles/roles.store';
import { UserRoles } from 'app/features/users/user-roles/user-roles';
import { ChangeUserDataService } from 'app/features/users/user-data/change-user-data.service';
import { UserDataForm } from 'app/features/users/user-data/user-data-form';
import { Notifications, NotificationsStore } from 'app/features/notifications/notifications';
import { useUserStore } from 'app/features/users/user.store';

import { Loading } from 'app/ui/loading';
import { PageContent } from 'UI/page-content';
import { PageHeader } from 'UI/page-header';
import { LogoutButton } from 'app/features/auth/logout';
import { apiClient } from 'app/api';
import { Link, generatePath } from 'react-router-dom';
import { bankDetailsRoute } from '../routes';
import { permissions } from 'app/features/users/permissions';

export const ProfilePage = observer(() => {
  const userStore = useUserStore();
  const [notify] = useState(() => new NotificationsStore());

  const [userProfile] = useState(() => {
    const service = new UserProfileService({ userStore, apiClient, notify });

    return service;
  });

  useEffect(() => {
    userProfile.getUser(userStore.data.id);
  }, [userProfile, userStore.data.id]);

  const user = userStore.data;
  const hasBank = userStore.hasBank;

  const [changeUserData] = useState(() => {
    const service = new ChangeUserDataService({
      userStore,
      apiClient,
      notify,
    });

    return service;
  });

  const [rolesStore] = useState(() => new RolesStore());

  const [availableRoles] = useState(() => {
    const service = new AvailableRolesService({
      rolesStore,
      apiClient,
      notify,
    });

    return service;
  });

  const canUpdateUser = userStore.canAccess(permissions.PERM_USER_UPDATE);

  useEffect(() => {
    if (canUpdateUser) availableRoles.getRoles();
  }, [availableRoles, canUpdateUser]);

  return (
    <>
      <PageHeader
        header="Текущий пользователь"
        subheader="Изменения ролей и данных учетной записи"
        after={<LogoutButton notify={notify} />}
      />

      <PageContent>
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Notifications messages={notify.messages} onClose={(m) => notify.removeMessage(m)} />

          <Card size="small" bodyStyle={{ paddingTop: 0 }}>
            {userProfile.isLoading ? (
              <div style={{ paddingTop: 12 }}>
                <Loading isActive />
              </div>
            ) : (
              <Tabs defaultActiveKey="1" animated={false}>
                <Tabs.TabPane tab="Учетная запись" key="1">
                  <div style={{ maxWidth: '50%' }}>
                    <UserDataForm
                      login={user.login}
                      blocked={user.blocked}
                      loading={changeUserData.isLoading}
                      onSubmit={(fields) => changeUserData.updateData(fields)}
                    />
                  </div>
                </Tabs.TabPane>
                {canUpdateUser && (
                  <Tabs.TabPane tab="Роли и разрешения" key="2">
                    <UserRoles
                      availableRoles={user.roles}
                      selectedRoles={user.roles}
                      loading={changeUserData.isLoading}
                    />
                  </Tabs.TabPane>
                )}

                {hasBank && (
                  <Tabs.TabPane tab="Банк" key="3">
                    Банк пользователя:{' '}
                    <Link
                      to={generatePath(bankDetailsRoute.path, { bankId: String(user.bank?.id) })}
                    >
                      {user.bank?.name}
                    </Link>
                  </Tabs.TabPane>
                )}
              </Tabs>
            )}
          </Card>
        </Space>
      </PageContent>
    </>
  );
});
