import { TBankLicense } from 'core/entities/bank-license';
import { TChangeBankLicense } from 'core/entities/change-license-request';
import { ICollectionStore } from 'core/entities/collection-store';
import { makeAutoObservable } from 'mobx';

type NewDsnList = Record<TChangeBankLicense['licenceId'], TChangeBankLicense['newDsn']>;

export class LicensesToChangeStore implements ICollectionStore<TChangeBankLicense> {
  items: TChangeBankLicense[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public fillData(items: TChangeBankLicense[]): void {
    this.items = items;
  }
  public removeItem({ id }: { id: TBankLicense['id'] }) {
    // @ts-expect-error
    this.items = this.items.filter((item) => item.id !== id);
  }

  public clearItems() {
    this.items = [];
  }

  public addItem(item: TBankLicense) {
    this.items.push({ ...item, licenceId: item.id });
  }

  public mergeNewDsnList(changedLicenses: NewDsnList) {
    this.items = this.items.map((item) => {
      const newDsn = changedLicenses[item.licenceId];

      return newDsn ? { ...item, newDsn } : item;
    });
  }

  public hasItem({ id }: { id: TBankLicense['id'] }) {
    // @ts-expect-error
    return this.items.find((license) => license.id === id);
  }
}
