type LogReq = {
  method?: string;
  body?: BodyInit | null;
  headers?: string[][] | Record<string, string>;
};
type LogRes = {
  url?: string;
  headers?: string[][] | Record<string, string>;
  body?: string | null;
};

export type LogRecord = {
  req: LogReq;
  res: LogRes | null;
  err: Error;
};

export type LogData = {
  url: string;
  req: RequestInit;
  res?: Partial<Response>;
  body: string | null;
  err: Error;
};

type HttpLoggerParams = {
  dist: (record: LogRecord) => void;
};

export class HttpLogger {
  private dist: ((record: LogRecord) => void) | null = null;

  constructor(params: HttpLoggerParams) {
    this.dist = params.dist ?? null;
  }

  add(data: LogData) {
    if (this.dist === null) return;

    this.dist({
      req: this.transformRequest(data.req),
      res: data.res ? this.transformResponse(data.res, data.body) : null,
      err: data.err,
    });
  }

  transformHeaders(headers?: Headers | HeadersInit) {
    return headers instanceof Headers ? Object.fromEntries(headers) : headers;
  }

  transformRequest(req: RequestInit) {
    return {
      method: req.method,
      body: req.body,
      headers: this.transformHeaders(req.headers),
    };
  }

  transformResponse(res: Partial<Response>, body: string | null) {
    return {
      url: res.url,
      headers: this.transformHeaders(res?.headers),
      body: body,
    };
  }
}
