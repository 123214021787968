import { Input } from 'antd';
import { TBank } from 'core/entities/bank';
import { TColumn } from 'core/entities/columns';
import { Link } from 'react-router-dom';

export const bankColumns: TColumn<TBank>[] = [
  {
    key: 'id',
    title: 'ID',
    dataIndex: 'id',
    sorter: {
      multiple: 1,
    },
    width: '1%',
    available: true,
    visible: true,
  },
  {
    key: 'name',
    title: 'Название',
    dataIndex: 'name',
    sorter: {
      multiple: 2,
    },
    available: true,
    visible: true,
  },
  {
    key: 'details',
    title: 'Подробнее',
    dataIndex: 'details',
    available: true,
    visible: true,
    render: (_, bank) => <Link to={`/bank/${bank.id}`}>Подробнее</Link>,
  },
];

export const bankFilters: TColumn<TBank>[] = [
  {
    title: 'Название банка',
    dataIndex: 'name',
    available: true,
    visible: true,
    filtrable: true,
    filterRender: (props) => <Input {...props} />,
  },
];
