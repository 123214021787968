import { ObservableRequest } from 'app/tools/observable-request';

import { INotifications } from 'core/entities/notifications';
import { makeAutoObservable } from 'mobx';

import { IHttpClient } from 'app/libs/http-client/types';
import { TBankLicense } from 'core/entities/bank-license';
import { RevokeLicenseRequestStore } from './revoke-license-request.store';
import { revokeLicenceRequest } from 'app/api/requests/bank-licenses/revoke_licence';

type RevokeLicenseServiceParams = {
  licenseRequestStore: RevokeLicenseRequestStore;
  apiClient: IHttpClient;
  notify: INotifications;
};

export class RevokeLicenseService {
  private revokeLicenseReq: ObservableRequest<Pick<TBankLicense, 'id'>, TBankLicense>;

  public get isLoading() {
    return this.revokeLicenseReq.isLoading;
  }

  private licenseRequestStore: RevokeLicenseRequestStore;

  constructor({ licenseRequestStore, apiClient, notify }: RevokeLicenseServiceParams) {
    this.licenseRequestStore = licenseRequestStore;

    const request = (license: Pick<TBankLicense, 'id'>) =>
      apiClient.fetch<TBankLicense>(revokeLicenceRequest(license));

    this.revokeLicenseReq = new ObservableRequest(request, {
      done: (license) => this.updateLicense(license),
      fail: (error) => notify.addMessage({ type: 'error', message: error.message }),
    });

    makeAutoObservable(this);
  }

  private updateLicense(license: TBankLicense) {
    this.licenseRequestStore.updateLicense({
      dsn: license.dsn,
      licenceId: license.id,
      terminal: license.terminal,
    });
  }

  public revokeLicense(license: Pick<TBankLicense, 'id'>) {
    this.revokeLicenseReq.fetch(license);
  }
}
