import { ObservableRequest } from 'app/tools/observable-request';

import { INotifications } from 'core/entities/notifications';
import { TEvent, TEventConstraints } from 'core/entities/event';
import { makeAutoObservable } from 'mobx';

import { IHttpClient } from 'app/libs/http-client/types';
import { getJournalRecordsRequest } from 'app/api/requests/events';
import { ConstraintsStore } from 'app/features/constraints/constraints.store';
import { TPageable } from 'core/entities/pageable';

type ChangableConstraints = {
  pagination: { current: number; pageSize: number };
  sort: TEventConstraints['sorting'];
};

type AllEventsServiceParams = {
  apiClient: IHttpClient;
  notify: INotifications;
  constraints: ConstraintsStore<TEventConstraints>;
};

export class AllEventsService {
  public events: TEvent[] = [];

  private allEventsRequest: ObservableRequest<TEventConstraints, TPageable<TEvent[]>>;

  public get isLoading() {
    return this.allEventsRequest.isLoading;
  }

  private constraints: ConstraintsStore<TEventConstraints>;

  constructor({ apiClient, notify, constraints }: AllEventsServiceParams) {
    this.constraints = constraints;

    const request = (constraints?: TEventConstraints) =>
      apiClient.fetch<TPageable<TEvent[]>>(getJournalRecordsRequest(constraints));

    this.allEventsRequest = new ObservableRequest(request, {
      done: (events) => this.setEvents(events),
      fail: (error) => notify.addMessage({ type: 'error', message: error.message }),
    });

    makeAutoObservable(this);
  }

  setEvents({ content, ...pagination }: TPageable<TEvent[]>) {
    this.events = content;
    this.constraints.updatePageInfo(pagination);
  }

  public fetchEvents() {
    const constraints = this.constraints.data;

    this.allEventsRequest.fetch(constraints);
  }

  public filterRecords(filters: TEventConstraints['filter']) {
    this.constraints.setFilters(filters);

    this.fetchEvents();
  }

  public changeConstraints({ pagination, sort }: ChangableConstraints) {
    this.constraints.updatePageInfo({
      page: pagination.current,
      size: pagination.pageSize,
    });

    this.constraints.setSort(sort);

    this.fetchEvents();
  }
}
