import { CloseOutlined, SaveOutlined } from '@ant-design/icons/lib/icons';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import { Tooltip } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import Button from 'antd/lib/button';
import Col from 'antd/lib/col';
import Empty from 'antd/lib/empty';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Row from 'antd/lib/row';
import Space from 'antd/lib/space';
import Table from 'antd/lib/table';
import { permissions } from 'app/features/users/permissions';
import { useUserStore } from 'app/features/users/user.store';
import { licensesDetailsRoute } from 'app/pages/routes';
import { IpInputMask } from 'app/ui/ip-input-mask';
import { TBank } from 'core/entities/bank';
import { TBankLicense } from 'core/entities/bank-license';
import { observer } from 'mobx-react-lite';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

let messageId: number = 0;
const nextId = () => (messageId < Number.MAX_SAFE_INTEGER ? ++messageId : (messageId = 0));

const prepareDsnNumbers = (numbers: string) =>
  numbers
    .trim()
    .split(',')
    .map((num) => num.trim())
    .filter((num) => num.length > 0);

const EditableValueRowContext = createContext(false);
const EditableMethodRowContext = createContext<(state: boolean) => void>(() => null);

const EditableRow = ({ ...props }: any) => {
  const onRowEdit = useContext(OnRowEditContext);
  const dataSource = useContext(DataSourceContext);
  // ^ using context's for props re-renders optimizations :(

  const [isEditable, setIsEditable] = useState(false);
  const initialValues = useMemo(
    () => dataSource.find((row: DsnRecord) => row.key === props['data-row-key']),
    [dataSource, props],
  );

  return (
    <EditableMethodRowContext.Provider value={setIsEditable}>
      <EditableValueRowContext.Provider value={isEditable}>
        {isEditable ? (
          <Form
            component={false}
            initialValues={initialValues}
            onFinish={(fields) => {
              onRowEdit({
                ...initialValues,
                ...fields,
                key: props['data-row-key'],
              });
              setIsEditable(false);
            }}
          >
            <tr {...props} />
          </Form>
        ) : (
          <tr {...props} />
        )}
      </EditableValueRowContext.Provider>
    </EditableMethodRowContext.Provider>
  );
};

const EditableDsnField = (_: any, row: DsnRecord) => {
  return (
    <EditableValueRowContext.Consumer>
      {(isEditableRow) =>
        isEditableRow ? (
          <Form.Item noStyle shouldUpdate>
            {(form) => {
              const errors = form.getFieldError('dsn');
              const errorMsg = errors.join(', ');

              return (
                <Tooltip placement="topLeft" title={errorMsg} visible={errors.length > 0}>
                  <DataSourceContext.Consumer>
                    {(dataSource) => (
                      <Form.Item
                        name="dsn"
                        noStyle
                        validateFirst={true}
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите DSN',
                            validateTrigger: 'onSubmit',
                          },
                          { len: 11, message: 'Введите 11 цифр', validateTrigger: 'onSubmit' },
                          {
                            validateTrigger: 'onSubmit',
                            validator: (_, value, q) => {
                              const isDsnExists = dataSource.some(
                                (record) => row.key !== record.key && record.dsn === value,
                              );

                              return isDsnExists
                                ? Promise.reject('Этот DSN уже используется в заявке')
                                : Promise.resolve(value);
                            },
                          },
                        ]}
                      >
                        <EditCellInput editable={isEditableRow} />
                      </Form.Item>
                    )}
                  </DataSourceContext.Consumer>
                </Tooltip>
              );
            }}
          </Form.Item>
        ) : (
          row.dsn
        )
      }
    </EditableValueRowContext.Consumer>
  );
};

const EditableIpField = (_: any, row: DsnRecord) => {
  return (
    <EditableValueRowContext.Consumer>
      {(isEditableRow) =>
        isEditableRow ? (
          <Form.Item noStyle shouldUpdate>
            {(form) => {
              const errors = form.getFieldError('simIp');
              const errorMsg = errors.join(', ');

              return (
                <Tooltip placement="topLeft" title={errorMsg} visible={errors.length > 0}>
                  <DataSourceContext.Consumer>
                    {(dataSource) => (
                      <Form.Item
                        name="simIp"
                        noStyle
                        validateFirst={true}
                        rules={[
                          {
                            validateTrigger: 'onSubmit',
                            validator: (_, value?: string) => {
                              if (!value) return Promise.resolve();

                              const isValidIp = value
                                .split('.')
                                .every(
                                  (num) => parseInt(num, 10) >= 0 && parseInt(num, 10) <= 255,
                                );
                              console.log({ isValidIp, value });

                              return isValidIp
                                ? Promise.resolve(value)
                                : Promise.reject('Пожалуйста, введите корректный IP');
                            },
                          },
                          {
                            validateTrigger: 'onSubmit',
                            validator: (_, value) => {
                              if (!value) return Promise.resolve();

                              const isIpExists = dataSource.some(
                                (record) => row.key !== record.key && record.simIp === value,
                              );

                              return isIpExists
                                ? Promise.reject('Этот IP уже используется в заявке')
                                : Promise.resolve(value);
                            },
                          },
                        ]}
                      >
                        <EditCellIp editable={isEditableRow} />
                      </Form.Item>
                    )}
                  </DataSourceContext.Consumer>
                </Tooltip>
              );
            }}
          </Form.Item>
        ) : (
          row.simIp
        )
      }
    </EditableValueRowContext.Consumer>
  );
};

const RowActions = ({
  row,
  onDeleteRow,
}: {
  row: DsnRecord;
  onDeleteRow?: (row: DsnRecord['key']) => void;
}) => {
  return (
    <EditableMethodRowContext.Consumer>
      {(editRow) => (
        <EditableValueRowContext.Consumer>
          {(isEditableRow) => (
            <Space>
              {isEditableRow ? (
                <>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => prevValues !== curValues}
                  >
                    {(form) => (
                      <Button
                        type="primary"
                        size="small"
                        icon={<SaveOutlined />}
                        onClick={() => {
                          form.submit();
                        }}
                      >
                        Сохранить
                      </Button>
                    )}
                  </Form.Item>

                  <Button size="small" icon={<CloseOutlined />} onClick={() => editRow(false)}>
                    Отмена
                  </Button>
                </>
              ) : (
                <Button size="small" icon={<EditOutlined />} onClick={() => editRow(true)}>
                  Редактировать
                </Button>
              )}

              {!isEditableRow && (
                <Button
                  size="small"
                  icon={<DeleteOutlined />}
                  danger
                  onClick={() => onDeleteRow && onDeleteRow(row.key)}
                >
                  Удалить
                </Button>
              )}
            </Space>
          )}
        </EditableValueRowContext.Consumer>
      )}
    </EditableMethodRowContext.Consumer>
  );
};

type AddNumberInputProps = {
  value?: string;
  onChange?: (dsn: string) => void;
};
const AddNumberField = ({ value, onChange }: AddNumberInputProps) => {
  const form = Form.useFormInstance();
  // const inputValue = useRef('');
  // const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
  //   inputValue.current = event.target.value;
  // };

  // const submitNumber = () => onChange && onChange(inputValue.current);

  /**
   * Валидация
   * - Проверить, что в поле только запятые и числа
   * - поле не может быть пустым
   */
  return (
    <Form.Item label="DSN" extra={`Введите один или нескольно номеров через запятую`}>
      <Row gutter={8}>
        <Col span={8}>
          <Form.Item
            name="dsn"
            noStyle
            validateFirst={true}
            rules={[
              {
                required: true,
                validateTrigger: 'onSubmit',
                message: 'Пожалуйста, введите DSN',
              },
              {
                validateTrigger: 'onSubmit',
                validator(_, value: string) {
                  const numbers = prepareDsnNumbers(value);

                  for (const num of numbers) {
                    if (num.length !== 11)
                      return Promise.reject(`DSN ${num} должен состоять из 11 цифр`);
                  }

                  return Promise.resolve(value);
                },
              },
              {
                validateTrigger: 'onChange',
                validator(_, value: string) {
                  // if (value === undefined || value.length === 0)
                  // return Promise.reject('Пожалуйста, введите DSN');
                  const numbers = prepareDsnNumbers(value);

                  for (const num of numbers) {
                    if (!num.match(/^[0-9]+$/))
                      return Promise.reject(
                        'Поле может содержать только цифры, пробелы и запятые',
                      );
                  }

                  return Promise.resolve(value);
                },
              },
            ]}
          >
            <Input.TextArea
              rows={1}
              autoSize
              style={{ width: '100%' }}
              placeholder="00012300001, 00012300002"
            />
          </Form.Item>
        </Col>

        <Col>
          <Button type="primary" block onClick={() => form.submit()}>
            Добавить к заявке
          </Button>
        </Col>
      </Row>
    </Form.Item>
  );
};

const EditCellInput = ({ editable, value, initValue, onChange }: any) => {
  return editable ? (
    <MaskedInput
      value={value}
      mask={/^[0-9]+$/}
      onChange={onChange}
      onPaste={(e) => e.preventDefault()}
      // ^ fix bug (double value) when paste value to field (https://github.com/antoniopresto/antd-mask-input/issues/55#issuecomment-1207435176)
      style={{ width: '100%' }}
      size="small"
    />
  ) : (
    initValue
  );
};

const EditCellIp = ({ editable, value, initValue, onChange }: any) => {
  return editable ? (
    <IpInputMask onChange={onChange} value={value} style={{ width: '100%' }} size="small" />
  ) : (
    initValue
  );
};

type DsnRecord = {
  key: number;
  dsn: TBankLicense['dsn'];
  simIp?: string;
  blockedDate?: TBankLicense['blockedDate'];
  prevBankName?: TBank['name'];
  licenseId?: TBankLicense['id'];
};

const componentsTable = {
  body: {
    row: EditableRow,
  },
};

const DataSourceContext = createContext<DsnRecord[]>([]);
const OnRowEditContext = createContext<(record: DsnRecord) => void>(() => {});
const DsnTable = ({
  dataSource,
  readOnly,
  onDeleteRow,
  onEditRow = () => {},
  ...props
}: {
  dataSource: DsnRecord[];
  readOnly?: boolean;
  onDeleteRow?: (key: number) => void;
  onEditRow?: (row: DsnRecord) => void;
}) => {
  const userStore = useUserStore();
  const canManageLicenseRequest = userStore.canAccess(permissions.PERM_BANK_LICENCE_REQUEST_APPLY);

  return (
    <DataSourceContext.Provider value={dataSource}>
      <OnRowEditContext.Provider value={onEditRow}>
        <Table<DsnRecord>
          rowKey="key"
          size="small"
          bordered
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Добавьте DSN через поле выше"
              />
            ),
          }}
          dataSource={dataSource}
          components={componentsTable}
          footer={() => (
            <Row justify="end">
              Количество лицензий:
              <strong style={{ paddingLeft: 4 }}> {dataSource.length} шт.</strong>
            </Row>
          )}
          {...props}
        >
          <Table.Column<DsnRecord> title="№" width="1%" render={(_, __, index) => index + 1} />

          <Table.Column<DsnRecord>
            title="DSN"
            width="200px"
            dataIndex="dsn"
            render={EditableDsnField}
          />

          {canManageLicenseRequest && (
            <Table.Column<DsnRecord>
              title="IP адрес"
              width="200px"
              dataIndex="simIp"
              render={EditableIpField}
            />
          )}

          {canManageLicenseRequest && (
            <Table.Column<DsnRecord>
              title="Предыдущая лицензия"
              render={(_, { prevBankName, blockedDate, licenseId }) => {
                if (!licenseId) return null;

                const formatDate = blockedDate ? new Date(blockedDate).toLocaleString() : '';
                const linkTitle = [prevBankName, formatDate].filter(Boolean).join(', ');
                const resultTitle = linkTitle || 'Просмотр';

                return (
                  <Link to={licensesDetailsRoute.createPath({ licenseId: String(licenseId) })}>
                    {resultTitle}
                  </Link>
                );
              }}
            />
          )}

          {!readOnly && (
            <Table.Column<DsnRecord>
              title="Действия"
              render={(_, row) => <RowActions onDeleteRow={onDeleteRow} row={row} />}
            />
          )}
        </Table>
      </OnRowEditContext.Provider>
    </DataSourceContext.Provider>
  );
};

type AddDsnFieldProps = {
  value?: DsnRecord[];
  readOnly?: boolean;
  onChange?: (dsn: Array<DsnRecord>) => void;
};
export const AddDsnField = observer(({ value, onChange, readOnly }: AddDsnFieldProps) => {
  const [addDsnNumbersForm] = Form.useForm();
  const [dsnStore, setDsnStore] = useState<DsnRecord[]>(
    () => value?.map((record) => ({ ...record, key: nextId() })) || [],
  );

  const handleAddDsn = (value: { dsn: string }) => {
    const dsnCollection = prepareDsnNumbers(value.dsn).reduce<DsnRecord[]>((acc, dsn) => {
      if (acc.find((item) => item.dsn === dsn)) return acc;
      if (dsnStore.find((item) => item.dsn === dsn)) return acc;

      return acc.concat({ key: nextId(), dsn });
    }, []);

    const updatedStore = dsnStore.concat(dsnCollection);

    setDsnStore(updatedStore);
    addDsnNumbersForm.resetFields();
    onChange && onChange(updatedStore);
  };

  const handleDeleteRow = useCallback(
    (key: number) => {
      const updatedStore = dsnStore.filter((item) => item.key !== key);

      setDsnStore(updatedStore);
      onChange && onChange(updatedStore);
    },
    [dsnStore, onChange],
  );

  const handleEditRow = useCallback(
    (row: DsnRecord) => {
      const updatedStore = dsnStore.map((record) => (record.key === row.key ? row : record));

      setDsnStore(updatedStore);
      onChange && onChange(updatedStore);
    },
    [dsnStore, onChange],
  );

  return (
    <Space direction="vertical" style={{ display: 'flex' }}>
      {!readOnly && (
        <Form form={addDsnNumbersForm} onFinish={handleAddDsn} component={false}>
          <AddNumberField />
        </Form>
      )}

      <DsnTable
        dataSource={dsnStore}
        onDeleteRow={handleDeleteRow}
        onEditRow={handleEditRow}
        readOnly={readOnly}
      />
    </Space>
  );
});
