import { Alert, Space } from 'antd';
import { TNotification } from 'core/entities/notifications';
import { observer } from 'mobx-react-lite';

type NotificationsProps = {
  messages: TNotification[];
  onClose: (messageId: Pick<TNotification, 'id'>) => void;
};

export const Notifications = observer(({ messages = [], onClose }: NotificationsProps) => {
  return messages.length < 1 ? null : (
    <Space direction="vertical" style={{ display: 'flex' }}>
      {messages.map((msg) => (
        <Alert
          key={msg.id}
          type={msg.type}
          message={msg.title ?? msg.message}
          description={msg.title ? msg.message : null}
          // ^ I know, looks sucks, but required at specific look for AntD.Alert
          showIcon={true}
          closable={true}
          afterClose={() => onClose(msg)}
        />
      ))}
    </Space>
  );
});
