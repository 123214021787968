import { action, computed, makeObservable, observable } from 'mobx';
import { RequestState } from 'core/entities/response-state';
import { Result } from 'neverthrow';
import { Fail } from 'core/data/fail';
import { AbortFail } from 'core/data/abort-fail';

const doNothing = () => undefined;

type ApiRequestEvents<Done, Fail> = {
  pending?: () => void;
  done?: (data: Done) => void;
  fail?: (error: Fail) => void;
  abort?: (error: Fail) => void;
};

export class ObservableRequest<Param = undefined, Done = void, Error extends Fail = Fail> {
  public get status() {
    return this._status;
  }
  public get isLoading() {
    return this._status === 'pending';
  }
  public get hasError() {
    return this._status === 'fail';
  }

  private _status: RequestState<Done>['type'] = 'idle';

  private apiReq: (param?: Param) => Promise<Result<Done, Error>>;

  private onPendingCb: () => void;
  private onDoneCb: (data: Done) => void;
  private onFailCb: (error: Error) => void;
  private onAbortCb: (error: Error) => void;

  constructor(
    request: (param: Param) => Promise<Result<Done, Error>>,
    events?: ApiRequestEvents<Done, Error>,
  );
  constructor(
    request: (param?: Param) => Promise<Result<Done, Error>>,
    events?: ApiRequestEvents<Done, Error>,
  ) {
    this.apiReq = request;

    this.onPendingCb = events?.pending ?? doNothing;
    this.onDoneCb = events?.done ?? doNothing;
    this.onFailCb = events?.fail ?? doNothing;
    this.onAbortCb = events?.abort ?? doNothing;

    makeObservable(this, {
      // @ts-expect-error
      _status: observable,
      status: computed,
      isLoading: computed,
      fetch: action,
      onDone: action,
      onFail: action,
    });
  }

  async fetch(param?: Param): Promise<void> {
    this.onPending();

    const response = await this.apiReq(param);

    response.match(
      (data) => this.onDone(data),
      (error) => this.onFail(error),
    );
  }

  private onPending() {
    this._status = 'pending';
    this.onPendingCb();
  }

  private onDone(data: Done) {
    this._status = 'done';
    this.onDoneCb(data);
  }

  private onFail(error: Error) {
    if (error instanceof AbortFail) this.onAbortCb(error);

    this._status = 'fail';
    this.onFailCb(error);
  }
}
