import { TBank } from './bank';
import { TBankLicense } from './bank-license';
import { TConstraints } from './constraints';

export enum ELicenseRequestStatus {
  created,
  confirmed,
  rejected,
}

export type TLicenseRequest = {
  id: number;
  bank: TBank;
  date: string;
  inn: string;
  licenceCount: number;
  status: ELicenseRequestStatus;
  statusDescription: string;
  licences: Array<{
    key: number;
    dsn: TBankLicense['dsn'];
    simIp?: string;
    blockedDate?: TBankLicense['blockedDate'];
    prevBankName?: TBank['name'];
    licenseId?: TBankLicense['id'];
  }>;
};

export type TLicenseRequestDetails = {
  request: TLicenseRequest;
  licences: Array<TBankLicense>; // Licenses who used in other banks
};

export type TLicenseRequestNew = Pick<TLicenseRequest, 'inn' | 'licenceCount'>;

export type TLicenseRequestsConstraints = TConstraints<
  Pick<TLicenseRequest, 'id'>,
  { id: number; personId: number; showDeleted: boolean }
>;
