import { TBankLicense } from 'core/entities/bank-license';
import { TTerminal } from 'core/entities/terminal';
import { makeAutoObservable } from 'mobx';

export class BankLicenseStore {
  data: TBankLicense = {
    id: -1,
    bank: {
      id: -1,
      name: '',
    },
    blocked: false,
    date: new Date().toLocaleString(),
    dateExpires: new Date().toJSON(),
    inn: '',
    key: '',
    requestId: -1,
    dsn: '',
    terminal: {
      type: {
        id: -1,
        name: '',
      },
      zn: '',
      blocked: false,
      data: '',
      dsn: '',
      id: -1,
      posConfig: {
        host: '',
        https: true,
        password: '',
        port: 0,
        type: 0,
      },
      bankLicence: {},
    },
  };

  get isEmpty() {
    return Number.isInteger(this.data.id) && this.data.id > 0;
  }

  constructor() {
    makeAutoObservable(this);
  }

  setData(data: TBankLicense) {
    this.data = data;
  }

  setTerminal(terminal: TTerminal) {
    this.data.terminal = terminal;
  }
}
