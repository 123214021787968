import { rest } from 'msw';

type HandlerParams<Data = any> = {
  method: keyof typeof rest;
  url: string;
  status: number;
  data: Data;
  delay?: number;
};

type OverrideParams = Partial<Pick<HandlerParams, 'status' | 'data' | 'delay'>>;

export const createMockHandler =
  <Data>(params: HandlerParams<Data>) =>
  (override: OverrideParams = {}) =>
    rest[params.method](params.url, (_, res, ctx) =>
      res(
        ctx.status(override.status || params.status),
        ctx.json(override.data || params.data),
        ctx.delay(override.delay || params.delay || 1200),
      ),
    );
