import { WarningOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';

type UserGuardErrorProps = {
  error: string;
};

export const UserGuardError = ({ error }: UserGuardErrorProps) => {
  return (
    <div
      style={{
        display: 'flex',
        minHeight: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 16,
      }}
    >
      <Card
        hoverable
        title={
          <>
            <WarningOutlined /> Ошибка при получении данных пользователя
          </>
        }
      >
        <pre>{error}</pre>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
          <Button type="primary" onClick={() => window.location.replace(window.location.origin)}>
            Перейти на главную
          </Button>
        </div>
      </Card>
    </div>
  );
};
