import { createMockHandler } from 'app/api/create-mock-handler';
import { RequestConfig } from 'app/api/types';
import { TBank, TBankConstraints } from 'core/entities/bank';
import { createRequest } from '../create-request';

const config: RequestConfig = {
  method: 'post',
  url: '/tms-ws/api/get_banks',
};

export const getBanksRequest = (constraints?: TBankConstraints) =>
  createRequest({
    url: config.url,
    method: config.method,
    body: JSON.stringify(constraints),
  });

const data: TBank[] = [
  { id: 1, name: 'Тестовый банк' },
  { id: 2, name: 'Банк тестовый' },
];

export const getBanksRequestMockHandler = createMockHandler<TBank[]>({
  url: config.url,
  method: config.method,
  status: 200,
  data,
});
