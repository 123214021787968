import { ObservableRequest } from 'app/tools/observable-request';

import { INotifications } from 'core/entities/notifications';
import { makeAutoObservable } from 'mobx';

import { IHttpClient } from 'app/libs/http-client/types';

import { ChangeLicenseRequestStore } from '../change-license-request.store';
import { getChangeLicenceRequestRequest } from 'app/api/requests/change-license-requests';
import { TChangeLicenseRequest } from 'core/entities/change-license-request';

type DetailsChangeLicenseRequestParams = {
  licenseRequestStore: ChangeLicenseRequestStore;
  apiClient: IHttpClient;
  notify: INotifications;
};

export class DetailsChangeLicenseRequestService {
  private licenseRequest: ObservableRequest<TChangeLicenseRequest['id'], TChangeLicenseRequest>;

  public get isDone() {
    return this.licenseRequest.status === 'done';
  }

  public get isLoading() {
    return this.licenseRequest.isLoading;
  }
  public get hasError() {
    return this.licenseRequest.hasError;
  }

  constructor({ licenseRequestStore, apiClient, notify }: DetailsChangeLicenseRequestParams) {
    const request = (id: TChangeLicenseRequest['id']) =>
      apiClient.fetch<TChangeLicenseRequest>(getChangeLicenceRequestRequest(id));

    this.licenseRequest = new ObservableRequest(request, {
      done: (licenseRequest) => licenseRequestStore.setData(licenseRequest),
      fail: (error) => notify.addMessage({ type: 'error', message: error.message }),
    });

    makeAutoObservable(this);
  }

  fetchLicenseRequest(id: TChangeLicenseRequest['id']) {
    this.licenseRequest.fetch(id);
  }
}
