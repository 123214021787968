import { Select } from 'antd';
import { apiClient } from 'app/api';
import { ConstraintsStore } from 'app/features/constraints/constraints.store';
import { useNotifyProvider } from 'app/features/notifications/notifications/notification-provider';
import { TBankConstraints } from 'core/entities/bank';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { AllBanksService } from '../all-banks/all-banks.service';

export const SelectAvailableBank = observer(({ onChange }: any) => {
  const notify = useNotifyProvider();
  const [constraints] = useState(() => new ConstraintsStore<TBankConstraints>());
  const [bankService] = useState(() => new AllBanksService({ apiClient, notify, constraints }));

  const isLoading = bankService.isLoading;

  useEffect(() => {
    bankService.fetchBanks();
  }, [bankService]);

  const banks = useMemo(
    () => bankService.banks.map((bank) => ({ label: bank.name, value: bank.id })),
    [bankService.banks],
  );

  return (
    <Select
      placeholder="Выберите банк"
      options={banks}
      onChange={(_, option) =>
        Array.isArray(option) ? null : onChange({ id: option.value, name: option.label })
      }
      loading={isLoading}
    />
  );
});
