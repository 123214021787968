import { createMockHandler } from 'app/api/create-mock-handler';
import { RequestConfig } from 'app/api/types';
import { TLogout } from 'core/entities/logout';
import { createRequest } from '../create-request';

const config: RequestConfig = {
  method: 'get',
  url: '/tms-ws/api/logout',
};

export const logoutRequest = () => createRequest(config);

const data: TLogout = {
  result: 0,
};

export const logoutRequestMockHandler = createMockHandler<TLogout>({
  status: 200,
  url: config.url,
  method: config.method,
  data,
});
