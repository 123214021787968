import { Space, Card } from 'antd';
import { apiClient } from 'app/api';
import { AllEventsService } from 'app/features/events/all-events/all-events.service';
import { eventColumns, eventsFilters } from 'app/features/events/all-events/events-columns';
import { Notifications, NotificationsStore } from 'app/features/notifications/notifications';
import { BaseTable } from 'app/ui/base-table';
import { FilterColumns } from 'app/ui/filter-columns/filter-columns';
import { TEventConstraints } from 'core/entities/event';
import { observer } from 'mobx-react-lite';
import { useState, useEffect, useCallback } from 'react';
import { Loading } from 'app/ui/loading';
import { PageContent } from 'UI/page-content';
import { PageHeader } from 'UI/page-header';
import { TBaseConstraints } from 'app/ui/base-table/base-table';
import { ConstraintsStore } from 'app/features/constraints/constraints.store';

export const EventsPage = observer(() => {
  const [notify] = useState(() => new NotificationsStore());

  const [constraints] = useState(() => new ConstraintsStore<TEventConstraints>());

  const [allEvents] = useState(() => {
    const service = new AllEventsService({ apiClient, notify, constraints });

    return service;
  });

  const onChangeFilter = useCallback((filters) => allEvents.filterRecords(filters), [allEvents]);

  const onChangeConstraints = useCallback(
    (constraints: TBaseConstraints<TEventConstraints>) => {
      allEvents.changeConstraints(constraints);
    },
    [allEvents],
  );

  useEffect(() => {
    allEvents.fetchEvents();
  }, [allEvents]);

  return (
    <>
      <PageHeader
        header="Журнал событий"
        subheader="История действий пользователей панели администрирования"
      />
      <PageContent>
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Notifications messages={notify.messages} onClose={(msg) => notify.removeMessage(msg)} />

          <FilterColumns columns={eventsFilters} onChange={onChangeFilter} />

          <Card size="small">
            <Loading isActive={allEvents.isLoading}>
              <BaseTable
                columns={eventColumns}
                dataSource={allEvents.events}
                paging={constraints.data.pageInfo}
                onChange={onChangeConstraints}
                persistentId="all-events-list"
              />
            </Loading>
          </Card>
        </Space>
      </PageContent>
    </>
  );
});
