import React, { useEffect, useMemo, useState } from 'react';
import { Button, Drawer, Space, Table, TablePaginationConfig, TableProps, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { RightOutlined, SettingOutlined } from '@ant-design/icons';
import { TColumn } from 'core/entities/columns';
import styles from './base-table.module.css';
import { PageInfo, TConstraints } from 'core/entities/constraints';

const mergeWithPersistance = function <T>({
  columns,
  persistentId,
}: {
  columns?: TColumn<T>[];
  persistentId?: string;
}) {
  const params = window.localStorage.getItem(`${persistentId}/visibleColumns`);
  if (!persistentId || !columns || !params) return columns;

  const savedParams: { dataIndex: string; visible: boolean }[] = JSON.parse(params);

  const updatedColumns =
    columns &&
    columns.map((col) => {
      const colSetting = savedParams.find((param) => param.dataIndex === col.dataIndex);
      return colSetting ? { ...col, ...colSetting } : col;
    });

  return updatedColumns;
};

const basePagination = {
  hideOnSinglePage: false,
  showSizeChanger: true,
  defaultPageSize: 40,
};

export type TBasePagination = { current: number; pageSize: number };
export type TBaseConstraints<C extends TConstraints<any, any>> = {
  pagination: TBasePagination;
  sort: C['sorting'];
};

export type BaseTableProps<T, C extends TConstraints<any, any>> = {
  rightPanel?: JSX.Element | JSX.Element[];
  hasSettings?: boolean;
  paging?: PageInfo;
  onChange?: (constraints: TBaseConstraints<C>) => void;
  onSelect?: (selected: Array<T>) => void;
  columns?: TColumn<T>[];
  persistentId?: string;
} & Omit<TableProps<T>, 'onChange'>;

const BaseTableComponent = <
  T extends { id: number },
  C extends TConstraints<any, any> = TConstraints<any, any>,
>({
  paging,
  onSelect,
  onChange,
  rightPanel,
  hasSettings = true,
  rowSelection,
  columns,
  expandable,
  persistentId,
  ...props
}: BaseTableProps<T, C>) => {
  const [visibleColumns, setVisibleColumns] = useState(
    mergeWithPersistance({ columns, persistentId }),
  );
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [selectedRows, setSelectedRows] = React.useState<Array<T>>([]);

  const rowSelectionParams = React.useMemo(() => {
    if (!onSelect) return undefined;

    return {
      onChange: (_: any, selected: T[]) => {
        setSelectedRows(selected);
        onSelect && onSelect(selected);
      },
      selectedRowKeys: selectedRows.map((r) => r.id),
      ...rowSelection,
    };
  }, [onSelect, rowSelection, selectedRows]);

  const handleRowClick = React.useCallback(
    (row: T) => {
      if (!onSelect) return {};

      return {
        onClick: () => {
          const isRowSelected = selectedRows.find((r) => r.id === row.id);
          const finalSelectedRows = isRowSelected
            ? selectedRows.filter((r) => r.id !== row.id)
            : rowSelection?.type === 'radio'
            ? [row]
            : selectedRows.concat(row);

          setSelectedRows(finalSelectedRows);
          onSelect(finalSelectedRows);
        },
      };
    },
    [onSelect, rowSelection?.type, selectedRows],
  );

  const pagination: TablePaginationConfig = useMemo(() => {
    return {
      ...basePagination,
      current: paging?.page,
      pageSize: paging?.size,
      total: paging?.totalElements,
    };
  }, [paging?.page, paging?.size, paging?.totalElements]);

  const handleChange = React.useCallback(
    (pagination: TablePaginationConfig, _, sorter) => {
      const _pagination = {
        current: pagination.current || 1,
        pageSize: pagination.pageSize || basePagination.defaultPageSize,
      };

      let _sort = [] as any;

      if (Object.keys(sorter).length > 0) {
        _sort =
          Array.isArray(sorter) && sorter.length > 0
            ? sorter.map(({ field, order }) => ({ name: field, direction: order }))
            : [{ name: sorter.field, direction: sorter.order }];
      }

      onChange && onChange({ pagination: _pagination, sort: _sort });
    },
    [onChange],
  );

  const resultColumns = useMemo(() => {
    return visibleColumns?.filter((col) => col.available && col.visible);
  }, [visibleColumns]);

  const chekedVisibleColumns = React.useMemo(() => {
    return {
      selectedRowKeys: resultColumns ? resultColumns.map((r) => r.dataIndex) : [],
      onSelect: (record: any, selected: any) => {
        const column = visibleColumns?.find((col) => col.dataIndex === record.dataIndex);

        setVisibleColumns((state) =>
          state?.map((col) =>
            col.dataIndex === column?.dataIndex ? { ...col, visible: selected } : col,
          ),
        );
      },
    };
  }, [resultColumns, visibleColumns]);

  const expandableConfig = useMemo(
    () =>
      expandable
        ? {
            expandIcon: ({ onExpand, record, expanded }: any) => (
              <Button
                size="small"
                icon={<RightOutlined style={{ fontSize: 12 }} rotate={expanded ? 90 : 0} />}
                onClick={(e) => onExpand(record, e)}
              />
            ),
            ...expandable,
          }
        : undefined,
    [expandable],
  );

  useEffect(() => {
    if (persistentId) {
      window.localStorage.setItem(
        `${persistentId}/visibleColumns`,
        JSON.stringify(
          visibleColumns?.map(({ dataIndex, visible }) => ({ dataIndex, visible })) || [],
        ),
      );
    }
  }, [persistentId, visibleColumns]);

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        {(rightPanel || hasSettings) && (
          <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {rightPanel}
            {hasSettings && (
              <Button icon={<SettingOutlined />} onClick={() => setSettingsVisible(true)}>
                Настройки таблицы
              </Button>
            )}
          </Space>
        )}

        <Table<T>
          rowKey="id"
          columns={resultColumns}
          size="small"
          bordered={true}
          rowSelection={rowSelectionParams}
          rowClassName={onSelect && styles.rowSelectable}
          pagination={pagination}
          expandable={expandableConfig}
          onRow={handleRowClick}
          onChange={handleChange}
          {...props}
        />
      </Space>

      {hasSettings && (
        <Drawer
          visible={settingsVisible}
          title="Настройки таблицы"
          width="400"
          onClose={() => setSettingsVisible(false)}
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Title level={5}>Отображение колонок</Typography.Title>
            <Table<any>
              rowKey="dataIndex"
              size="small"
              columns={[{ dataIndex: 'title', title: 'Колонка' }]}
              dataSource={visibleColumns}
              rowSelection={chekedVisibleColumns}
              pagination={false}
              bordered
            />
          </Space>
        </Drawer>
      )}
    </>
  );
};

export const BaseTable = observer(BaseTableComponent);
