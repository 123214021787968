import { TBank } from 'core/entities/bank';
import { TLicenseRequest, TLicenseRequestDetails } from 'core/entities/license-request';
import { makeAutoObservable } from 'mobx';

export class LicenseRequestStore {
  public data: TLicenseRequest = {
    id: -1,
    bank: {
      id: -1,
      name: '',
    },
    date: '',
    licenceCount: 0,
    status: 0,
    statusDescription: '',
    inn: '',
    licences: [],
  };

  get status() {
    return this.data.status;
  }

  get statusDescription() {
    return this.data.statusDescription ?? '';
  }

  constructor(id: TBank['id']) {
    this.setId(id);

    makeAutoObservable(this);
  }

  setId(id: TLicenseRequest['id']) {
    this.data.id = id;
  }

  setData(licenseRequest: TLicenseRequestDetails) {
    this.data = this.addDublicateProps(licenseRequest);
  }

  private addDublicateProps(licenseRequest: TLicenseRequestDetails): TLicenseRequest {
    const filteredLicenses = licenseRequest.licences.sort((a, b) =>
      a.blocked === b.blocked ? (a.blocked > b.blocked ? 1 : -1) : a.id - b.id,
    );

    const updatedLicenses = licenseRequest.request.licences.map((license) => {
      const prevLicenseActivation = filteredLicenses.find((lic) => lic.dsn === license.dsn);

      if (!prevLicenseActivation) return license;

      return {
        ...license,
        blockedDate: prevLicenseActivation.blockedDate,
        prevBankName: prevLicenseActivation.bank.name,
        licenseId: prevLicenseActivation.id,
      };
    });

    const result = {
      ...licenseRequest.request,
      licences: updatedLicenses,
    };

    return result;
  }

  updateLicences(licences: TLicenseRequest['licences']) {
    this.data.licences = licences;
  }

  setStatusDescription(value: TLicenseRequest['statusDescription']) {
    this.data.statusDescription = value;
  }
}
