import { HttpClient } from 'app/libs/http-client';
import { captureError } from 'libs/error-tracker/capture-error';

export const apiClient = new HttpClient({
  log: ({ err, ...extra }) => {
    captureError(err, {
      // @ts-expect-error
      extra,
    });
  },
});
