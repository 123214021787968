import { observer } from 'mobx-react-lite';
import React, { ReactElement } from 'react';
import { UPermissions } from '../permissions';
import { useUserStore } from '../user.store';

type CanAccessProps = {
  permission: UPermissions;
};
export const CanAccess: React.FC<CanAccessProps> = observer(({ permission, children }) => {
  const user = useUserStore();
  const hasAccess = user.canAccess(permission);

  if (hasAccess) {
    return children as ReactElement;
    // console.log(React.Children.count(children));
    // return React.Children.count(children) > 1
    //   ? (children as ReactElement)
    //   : (children as ReactElement);
  }

  return null;
});
