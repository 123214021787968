import { TExportLicenseRequestConstraints } from 'core/entities/export-licenses';
import { createRequest } from '../create-request';

type RequesParams = TExportLicenseRequestConstraints;

export const exportLicenceRequestsRequest = (constraints?: RequesParams) =>
  createRequest({
    method: 'post',
    url: '/tms-ws/api/export_licence_requests',
    body: JSON.stringify(constraints),
  });
