import { DatePicker, Input, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { SelectAvailableBank } from 'app/features/banks/all-banks/select-available-banks';
import { bankDetailsRoute, changeLicenseRequestDetailsRoute } from 'app/pages/routes';

import { TBank } from 'core/entities/bank';
import {
  TChangeLicenseRequest,
  EChangeLicenseRequestStatus,
} from 'core/entities/change-license-request';
import { TColumn } from 'core/entities/columns';
import { FormItemAdapter } from 'libs/form-item-adapter';
import { generatePath, Link } from 'react-router-dom';
import { ChangeLicenseRequestStatus } from '../ui/change-license-request-status';

export const allChangeLicenseRequestsColumns: TColumn<TChangeLicenseRequest>[] = [
  {
    key: 'id',
    title: 'ID',
    dataIndex: 'id',
    sorter: {
      multiple: 1,
    },
    width: '1%',
    available: true,
    visible: true,
  },

  {
    key: 'bank',
    title: 'Банк',
    dataIndex: 'bank',
    sorter: {
      multiple: 2,
    },
    available: true,
    visible: true,
    render: (_, record) => (
      <Link to={generatePath(bankDetailsRoute.path, { bankId: String(record.bank.id) })}>
        {record.bank.name}
      </Link>
    ),
  },

  {
    key: 'status',
    title: 'Статус',
    dataIndex: 'status',
    sorter: {
      multiple: 6,
    },
    available: true,
    visible: true,
    render: (_, record) => <ChangeLicenseRequestStatus status={record.status} />,
  },

  {
    key: 'date',
    title: 'Дата',
    dataIndex: 'date',
    sorter: {
      multiple: 3,
    },
    available: true,
    visible: true,
    render: (_, record) => new Date(record.date).toLocaleString(),
  },
  {
    key: 'details',
    title: 'Подробнее',
    dataIndex: 'none',
    available: true,
    visible: true,
    render: (_, record) => (
      <Link
        to={generatePath(changeLicenseRequestDetailsRoute.path, {
          requestId: String(record.id),
        })}
      >
        Подробнее
      </Link>
    ),
  },
];

export const allChangeLicenseRequestsFilters: TColumn<TChangeLicenseRequest>[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    available: true,
    visible: true,
    filtrable: true,
    filterRender: (props) => <Input {...props} />,
  },
  {
    title: 'ИНН',
    dataIndex: 'inn',
    available: true,
    visible: true,
    filtrable: true,
    filterRender: (props) => <Input {...props} />,
  },
  {
    title: 'Начальная дата',
    dataIndex: 'dateFrom',
    available: true,
    visible: true,
    filtrable: true,
    filterRender: (props) => <DatePicker showTime {...props} />,
  },
  {
    title: 'Конечная дата',
    dataIndex: 'dateTo',
    available: true,
    visible: true,
    filtrable: true,
    filterRender: (props) => <DatePicker showTime {...props} />,
  },
  {
    title: 'DSN',
    dataIndex: 'dsn',
    available: true,
    visible: true,
    filtrable: true,
    filterRender: (props) => (
      <MaskedInput
        mask={/^[0-9]+$/}
        onPaste={(e: any) => e.preventDefault()}
        // ^ fix bug (double value) when paste value to field (https://github.com/antoniopresto/antd-mask-input/issues/55#issuecomment-1207435176)
        style={{ width: '100%' }}
        {...props}
      />
    ),
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    available: true,
    visible: true,
    filtrable: true,
    filterRender: (props) => (
      <Select {...props}>
        <Select.Option
          key={EChangeLicenseRequestStatus.created}
          value={EChangeLicenseRequestStatus.created}
        >
          <ChangeLicenseRequestStatus status={EChangeLicenseRequestStatus.created} />
        </Select.Option>
        <Select.Option
          key={EChangeLicenseRequestStatus.confirmed}
          value={EChangeLicenseRequestStatus.confirmed}
        >
          <ChangeLicenseRequestStatus status={EChangeLicenseRequestStatus.confirmed} />
        </Select.Option>
        <Select.Option
          key={EChangeLicenseRequestStatus.rejected}
          value={EChangeLicenseRequestStatus.rejected}
        >
          <ChangeLicenseRequestStatus status={EChangeLicenseRequestStatus.rejected} />
        </Select.Option>
      </Select>
    ),
  },
  {
    title: 'Банк',
    dataIndex: 'bankId',
    available: true,
    visible: true,
    filtrable: true,
    perms: ['PERM_REVOKE_LICENCE_REQUEST_APPLY'],
    filterRender: () => (
      <FormItemAdapter onChangeFormat={(bank: TBank) => bank.id} valueFormat={(v) => v}>
        {(_, onChange) => <SelectAvailableBank onChange={onChange} />}
      </FormItemAdapter>
    ),
  },
];
