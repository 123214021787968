import { createMockHandler } from 'app/api/create-mock-handler';
import { RequestConfig } from 'app/api/types';
import { TBankLicense } from 'core/entities/bank-license';
import { createRequest } from '../create-request';

const config: RequestConfig = {
  method: 'post',
  url: '/tms-ws/api/update_licence',
};

export const updateLicenceRequest = (license: TBankLicense) =>
  createRequest({
    url: config.url,
    method: config.method,
    body: JSON.stringify(license),
  });

const data: TBankLicense = {
  id: 1,
  dsn: '42424242',
  bank: { id: 1, name: 'Тестовый' },
  requestId: 1,
  terminal: {
    id: 1,
    bankLicence: {},
    blocked: false,
    data: '',
    dsn: '000000',
    type: { id: 1, name: 'Test' },
    zn: '0000000',
    posConfig: { host: 'test.com', https: true, password: '', port: 0, type: 1 },
  },
  date: new Date().toJSON(),
  dateExpires: new Date().toJSON(),
  inn: '000000',
  key: '0000000000',
  blocked: false,
};

export const updateLicenceRequestMockHandler = createMockHandler<TBankLicense>({
  url: config.url,
  method: config.method,
  status: 200,
  data,
});
