import { Card, Button, Row, Col, Form } from 'antd';
import { useState } from 'react';
import { apiClient } from 'app/api';
import { Notifications, NotificationsStore } from 'app/features/notifications/notifications';
import { AppPage } from 'app/ui/app-page';
import { observer } from 'mobx-react-lite';
import { ELicenseRequestStatus } from 'core/entities/license-request';
import { NotificationsProvider } from 'app/features/notifications/notifications/notification-provider';
import { ExportLicensesService } from 'app/features/bank-liceses/export-licenses/export-licenses.service';
import { bankLicensesFilters } from 'app/features/bank-liceses/all-bank-licenses/bank-licenses-columns';
import { useUserStore } from 'app/features/users/user.store';

export const ExportLicensesPage = observer(() => {
  const [notify] = useState(() => new NotificationsStore());

  const [exportLicenseRequests] = useState(() => {
    const service = new ExportLicensesService({
      apiClient,
      notify,
    });

    return service;
  });

  const [filtersForm] = Form.useForm();

  const exportData = () => {
    const filters = filtersForm.getFieldsValue();

    exportLicenseRequests.filterData(filters);
  };

  return (
    <AppPage header="Экспорт лицензий" subheader="Выгрузка данных лицензий">
      <NotificationsProvider value={notify}>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Card size="small" title="Фильтры">
              <FormFilters form={filtersForm} onChange={console.log} />
            </Card>
          </Col>

          <Col span={24}>
            <Card size="small">
              <Row justify="end">
                <Button
                  type="primary"
                  onClick={exportData}
                  loading={exportLicenseRequests.isLoading}
                >
                  Экспорт лицензий
                </Button>
              </Row>
            </Card>
          </Col>

          <Col span={24}>
            <Notifications
              messages={notify.messages}
              onClose={(msg) => notify.removeMessage(msg)}
            />
          </Col>
        </Row>
      </NotificationsProvider>
    </AppPage>
  );
});

const FormFilters = ({ form, onChange }: any) => {
  const userStore = useUserStore();

  const handleOnFinish = (filters: { status: ELicenseRequestStatus }) => {
    onChange(filters);
  };

  return (
    <Form layout="vertical" onFinish={handleOnFinish} form={form}>
      <Row gutter={[16, 16]}>
        {bankLicensesFilters.map((col) => {
          if (!col.filtrable) return null;

          const hasAccess = col.perms ? col.perms.some((perm) => userStore.canAccess(perm)) : true;

          if (!hasAccess) return null;

          return (
            <Col span={12}>
              <Form.Item name={col.dataIndex} label={col.title}>
                {col.filterRender && col.filterRender({ style: { width: '100%' } })}
              </Form.Item>
            </Col>
          );
        })}
      </Row>
    </Form>
  );
};
