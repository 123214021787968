import { Form, Space } from 'antd';
import { apiClient } from 'app/api';
import { Notifications, NotificationsStore } from 'app/features/notifications/notifications';
import { TRole } from 'core/entities/role';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Loading } from 'app/ui/loading';
import { AvailableRolesService } from '../user-roles/available-roles.service';
import { RolesStore } from '../user-roles/roles.store';
import { SelectRoles } from '../user-roles/select-roles/select-roles';

type RolesFieldsProps = {
  onChange?: (roles: TRole[]) => void;
};
export const RolesFields = observer(({ onChange }: RolesFieldsProps) => {
  const [rolesStore] = useState(() => new RolesStore());
  const [notify] = useState(() => new NotificationsStore());
  const [availableRoles] = useState(() => {
    const service = new AvailableRolesService({
      rolesStore,
      apiClient,
      notify,
    });

    return service;
  });

  useEffect(() => {
    availableRoles.getRoles();
  }, [availableRoles]);

  return (
    <Space direction="vertical" style={{ display: 'flex' }}>
      <Notifications messages={notify.messages} onClose={(msg) => notify.removeMessage(msg)} />

      <Loading isActive={availableRoles.isLoading}>
        <Form.Item
          name="roles"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, выберите необходимые роли',
            },
          ]}
        >
          <SelectRoles roles={rolesStore.roles} onChange={onChange} />
        </Form.Item>
      </Loading>
    </Space>
  );
});
