import { TBank } from './bank';
import { TBankLicense } from './bank-license';
import { TConstraints } from './constraints';

export enum EChangeLicenseRequestStatus {
  created,
  confirmed,
  rejected,
}

export type TChangeBankLicense = Pick<TBankLicense, 'dsn' | 'terminal'> & {
  licenceId: TBankLicense['id'];
  newDsn?: TBankLicense['dsn'];
  newInn?: TBankLicense['inn'];
};

export type TChangeLicenseRequest = {
  // request: {
  id: number;
  bank: TBank;
  date: string;
  status: EChangeLicenseRequestStatus;
  statusDescription: string;
  licences: Array<TChangeBankLicense>;
  // };
  // licences: Array<TBankLicense>;
};

export type TChangeUpdatedLicenseRequest = {
  request: TChangeLicenseRequest;
  licences: Array<TBankLicense>;
};

export type TChangeLicenseRequestNew = Pick<
  // TChangeLicenseRequest['request'],
  TChangeLicenseRequest,
  'licences'
>;

export type TChangeLicenseRequestsConstraints = TConstraints<
  // Pick<TChangeLicenseRequest['request'], 'id'>,
  Pick<TChangeLicenseRequest, 'id'>,
  { id: number; personId: number; showDeleted: boolean }
>;
