import { createMockHandler } from 'app/api/create-mock-handler';
import { RequestConfig } from 'app/api/types';
import { TBankLicense } from 'core/entities/bank-license';
import { createRequest } from '../create-request';

const config: RequestConfig = {
  method: 'post',
  url: '/tms-ws/api/revoke_licence',
};

export const revokeLicenceRequest = (license: Pick<TBankLicense, 'id'>) =>
  createRequest({
    url: config.url,
    method: config.method,
    body: JSON.stringify(license),
  });

const data: TBankLicense = {
  id: 1,
  bank: {
    id: 1,
    name: 'Тестовый банк',
  },
  dsn: '',
  requestId: 0,
  terminal: null,
  date: new Date().toJSON(),
  dateExpires: new Date().toJSON(),
  inn: '00000000',
  key: '00000000',
  blocked: false,
};

export const addLicenseRequestMockHandler = createMockHandler<TBankLicense>({
  url: config.url,
  method: config.method,
  status: 200,
  data,
});
