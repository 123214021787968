import { Space, Card } from 'antd';
import { apiClient } from 'app/api';
import { AllBankLicensesService } from 'app/features/bank-liceses/all-bank-licenses/all-bank-licenses.service';
import {
  bankLicensesColumns,
  bankLicensesFilters,
} from 'app/features/bank-liceses/all-bank-licenses/bank-licenses-columns';
import { Notifications, NotificationsStore } from 'app/features/notifications/notifications';
import { permissions } from 'app/features/users/permissions';
import { useUserStore } from 'app/features/users/user.store';
import { AppPage } from 'app/ui/app-page';
import { BaseTable } from 'app/ui/base-table';
import { FilterColumns } from 'app/ui/filter-columns/filter-columns';
import { TBankLicense, TBankLicenseConstraints } from 'core/entities/bank-license';
import { TColumn } from 'core/entities/columns';
import { observer } from 'mobx-react-lite';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { Loading } from 'app/ui/loading';
import { licensesRequestDetailsRoute } from '../routes';
import { BankLicenseCollectionStore } from 'app/features/bank-liceses/bank-license-collection.store';
import { NotificationsProvider } from 'app/features/notifications/notifications/notification-provider';
import { ConstraintsStore } from 'app/features/constraints/constraints.store';
import { TBaseConstraints } from 'app/ui/base-table/base-table';

export const AllBankLicensesPage = observer(() => {
  const [notify] = useState(() => new NotificationsStore());
  const [licensesStore] = useState(() => new BankLicenseCollectionStore());
  const [constraints] = useState(() => new ConstraintsStore<TBankLicenseConstraints>());

  const [allLicenses] = useState(() => {
    const service = new AllBankLicensesService({
      apiClient,
      notify,
      licensesStore,
      constraints,
    });

    return service;
  });

  const onChangeFilter = useCallback(
    (filters: TBankLicenseConstraints['filter']) => {
      allLicenses.filterRecords(filters);
    },
    [allLicenses],
  );

  const onChangeConstraints = useCallback(
    (constraints: TBaseConstraints<TBankLicenseConstraints>) => {
      allLicenses.changeConstraints(constraints);
    },
    [allLicenses],
  );

  useEffect(() => {
    allLicenses.fetchLicenses();
  }, [allLicenses]);

  const userStore = useUserStore();

  const isLicenseRequestViewable = userStore.canAccess(permissions.PERM_BANK_LICENCE_REQUEST_READ);

  const allLicensesColumns = useMemo<TColumn<TBankLicense>[]>(() => {
    const requestLicenseColumn: TColumn<TBankLicense> = {
      key: 'requestId',
      title: 'Заявка',
      dataIndex: 'requestId',
      sorter: {
        multiple: 2,
      },
      available: true,
      visible: true,
      render: (_, record) =>
        record.requestId ? (
          <Link
            to={generatePath(licensesRequestDetailsRoute.path, {
              requestId: String(record.requestId),
            })}
            target="_blank"
          >
            Посмотреть заявку
          </Link>
        ) : (
          'Отсутствует'
        ),
    };

    return isLicenseRequestViewable
      ? bankLicensesColumns.concat(requestLicenseColumn)
      : bankLicensesColumns;
  }, [isLicenseRequestViewable]);

  return (
    <NotificationsProvider value={notify}>
      <AppPage header="Доступные лицензии" subheader="Просмотр существующих лицензий">
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Notifications messages={notify.messages} onClose={(msg) => notify.removeMessage(msg)} />

          {/*
           // @ts-expect-error */}
          <FilterColumns columns={bankLicensesFilters} onChange={onChangeFilter} />

          <Card size="small">
            <Loading isActive={allLicenses.isLoading}>
              <BaseTable<TBankLicense, TBankLicenseConstraints>
                columns={allLicensesColumns}
                dataSource={licensesStore.items}
                paging={constraints.data.pageInfo}
                onChange={onChangeConstraints}
                persistentId="all-bank-licenses-list"
              />
            </Loading>
          </Card>
        </Space>
      </AppPage>
    </NotificationsProvider>
  );
});
