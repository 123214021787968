import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FileExcelOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {
  Space,
  Card,
  Descriptions,
  Button,
  Modal,
  Alert,
  Row,
  Table,
  Popconfirm,
  Input,
} from 'antd';
import { apiClient } from 'app/api';

import { NotificationsStore, Notifications } from 'app/features/notifications/notifications';
import { permissions } from 'app/features/users/permissions';
import { useUserStore } from 'app/features/users/user.store';
import { AppPage } from 'app/ui/app-page';
import { Observer, observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Loading } from 'app/ui/loading';
import { bankDetailsRoute } from '../routes';
import { DetailsRevokeLicenseRequestService } from 'app/features/revoke-license-requests/details-revoke-license-request/details-revoke-license-request.service';
import { RevokeLicenseRequestStore } from 'app/features/revoke-license-requests/revoke-license-request.store';
import { UpdateRevokeLicenseRequestService } from 'app/features/revoke-license-requests/update-revoke-license-request/update-revoke-license-request.service';
import {
  ERevokeLicenseRequestStatus,
  TRevokeBankLicense,
} from 'core/entities/revoke-license-request';
import { RevokeLicenseRequestStatus } from 'app/features/revoke-license-requests/ui/revoke-license-request-status';
import { RevokeLicenseService } from 'app/features/revoke-license-requests/revoke-license.service';

export const DetailsRevokeLicenseRequestsPage = observer(() => {
  const { requestId } = useParams<{ requestId: string }>();
  const [licenseRequestStore] = useState(() => new RevokeLicenseRequestStore(Number(requestId)));
  const [notify] = useState(() => new NotificationsStore());

  const [revokeLicenseRequestService] = useState(
    () => new DetailsRevokeLicenseRequestService({ apiClient, licenseRequestStore, notify }),
  );
  const [revokeLicenseService] = useState(
    () => new RevokeLicenseService({ apiClient, licenseRequestStore, notify }),
  );
  const [updateLicenseRequestService] = useState(
    () => new UpdateRevokeLicenseRequestService({ apiClient, licenseRequestStore, notify }),
  );

  useEffect(() => {
    revokeLicenseRequestService.fetchLicenseRequest(Number(requestId));
  }, [requestId, revokeLicenseRequestService]);

  const revokeLicense = (license: TRevokeBankLicense) => {
    revokeLicenseService.revokeLicense({ id: license.licenceId });
  };

  const deleteLicense = (license: TRevokeBankLicense) => {
    licenseRequestStore.deleteLicense(license);
  };

  const userStore = useUserStore();

  const licenseRequest = licenseRequestStore.data;

  const isLoading =
    revokeLicenseRequestService.isLoading ||
    updateLicenseRequestService.isLoading ||
    revokeLicenseService.isLoading;

  const isLicenceRequestEditable =
    licenseRequestStore.data.request.status === ERevokeLicenseRequestStatus.created;
  const hasRequestApplyPerm =
    licenseRequestStore.data.request.status === ERevokeLicenseRequestStatus.created &&
    userStore.canAccess(permissions.PERM_REVOKE_LICENCE_REQUEST_APPLY);

  const canRevokeLicense = userStore.canAccess(permissions.PERM_BANK_LICENCE_UPDATE);

  const isStatusDescriptionEditable =
    licenseRequestStore.status === ERevokeLicenseRequestStatus.created && hasRequestApplyPerm;
  const isStatusDescriptionVisible =
    licenseRequestStore.statusDescription &&
    licenseRequestStore.status !== ERevokeLicenseRequestStatus.created;

  const confirmLicenseRequest = () =>
    Modal.confirm({
      title: 'Подтвердить заявку на удаление лицензий?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          В заявке на удаление лицензии для банка <b>{licenseRequest.request.bank.name}</b>.
          <p>
            Заявка станет недоступна для изменения, а ее статус будет изменен на "Подтверждена"
          </p>
          {isStatusDescriptionEditable && (
            <>
              <span>Комментарий к статусу заявки:</span>
              <Observer>
                {() => (
                  <Input.TextArea
                    showCount
                    maxLength={500}
                    value={licenseRequestStore.statusDescription}
                    onChange={(event) =>
                      licenseRequestStore.setStatusDescription(event.target.value)
                    }
                  />
                )}
              </Observer>
            </>
          )}
        </div>
      ),
      onOk() {
        updateLicenseRequestService.confirmLicenseRequest();
      },
      okText: 'Подтвердить',
    });

  const rejectLicenseRequest = () =>
    Modal.confirm({
      title: 'Отклонить заявку?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          В заявке на удаление лицензии для банка <b>{licenseRequest.request.bank.name}</b>.
          <p>
            После этого действия, заявка станет недоступна для изменения, а ее статус будет изменен
            на "Отклонена"
          </p>
          {isStatusDescriptionEditable && (
            <>
              <span>Комментарий к статусу заявки:</span>
              <Observer>
                {() => (
                  <Input.TextArea
                    showCount
                    maxLength={500}
                    value={licenseRequestStore.statusDescription}
                    onChange={(event) =>
                      licenseRequestStore.setStatusDescription(event.target.value)
                    }
                  />
                )}
              </Observer>
            </>
          )}
        </div>
      ),
      onOk() {
        updateLicenseRequestService.rejectLicenseRequest();
      },
      okText: 'Отклонить',
    });

  if (!Number.isInteger(Number(requestId))) {
    return (
      <AppPage header="Просмотр заявки на удаление лицензий" subheader="Отображение данных заявки">
        <Alert
          type="error"
          message={
            <span>
              Некорректный ID заявки: <b>{requestId}</b>
            </span>
          }
          description="Проверьте ID заявки в строке браузера"
          showIcon
        />
      </AppPage>
    );
  }

  if (!revokeLicenseRequestService.isLoading && revokeLicenseRequestService.hasError) {
    return (
      <AppPage header="Просмотр заявки на удаление лицензий" subheader="Отображение данных заявки">
        <Notifications messages={notify.messages} onClose={(m) => notify.removeMessage(m)} />
      </AppPage>
    );
  }

  return (
    <AppPage header="Просмотр заявки на удаление лицензий" subheader="Отображение данных заявки">
      <Space direction="vertical" style={{ display: 'flex' }}>
        {isLoading ? (
          <Card size="small">
            <Loading isActive />
          </Card>
        ) : (
          <>
            <Card size="small">
              <Descriptions bordered size="small" column={1}>
                <Descriptions.Item
                  label="ID заявки"
                  labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                >
                  {licenseRequest.request.id}
                </Descriptions.Item>

                <Descriptions.Item
                  label="Статус"
                  labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                >
                  <RevokeLicenseRequestStatus status={licenseRequest.request.status} />
                </Descriptions.Item>

                <Descriptions.Item label="Банк" labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}>
                  <Link
                    to={generatePath(bankDetailsRoute.path, {
                      bankId: String(licenseRequest.request.bank.id),
                    })}
                  >
                    {licenseRequest.request.bank.name}
                  </Link>
                </Descriptions.Item>

                <Descriptions.Item
                  label="Дата создания заявки"
                  labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                >
                  {new Date(licenseRequest.request.date).toLocaleString()}
                </Descriptions.Item>
              </Descriptions>
            </Card>

            {isStatusDescriptionVisible && (
              <Card size="small" title="Комментарий к статусу заявки">
                {licenseRequestStore.statusDescription}
              </Card>
            )}

            <Card size="small" title="Лицензии на удаление">
              <Table<TRevokeBankLicense>
                rowKey={(row) => row.licenceId}
                dataSource={licenseRequest.request.licences}
                bordered={true}
                size="small"
              >
                <Table.Column<TRevokeBankLicense>
                  title="№"
                  width="1%"
                  render={(_, record) => {
                    const index = licenseRequest.licences.findIndex(
                      (license) => license.dsn === record.dsn,
                    );
                    return index + 1;
                  }}
                />
                <Table.Column dataIndex="dsn" title="DSN" />
                {/* <Table.Column<TRevokeBankLicense>
                  title="Статус лицензи"
                  render={(_, record) => (
                    <Observer>
                      {() => (record.revoked ? <span>Активна</span> : <span></span>)}
                    </Observer>
                  )}
                /> */}
                {isLicenceRequestEditable && (
                  <Table.Column<TRevokeBankLicense>
                    title="Действия"
                    render={(_, record) => {
                      return (
                        <Space>
                          {canRevokeLicense && (
                            <Observer>
                              {() =>
                                record.revoked ? (
                                  <Button disabled size="small">
                                    Лицензия удалена
                                  </Button>
                                ) : (
                                  <Popconfirm
                                    title="Вы уверены, что хотите удалить лицензию?"
                                    onConfirm={() => revokeLicense(record)}
                                    okText="Подтвердить"
                                    cancelText="Отмена"
                                  >
                                    <Button danger size="small" icon={<FileExcelOutlined />}>
                                      Удалить лицензию
                                    </Button>
                                  </Popconfirm>
                                )
                              }
                            </Observer>
                          )}

                          <Button
                            size="small"
                            icon={<DeleteOutlined />}
                            onClick={() => deleteLicense(record)}
                          >
                            Убрать из заявки
                          </Button>
                        </Space>
                      );
                    }}
                  />
                )}
              </Table>
            </Card>

            <Notifications messages={notify.messages} onClose={(m) => notify.removeMessage(m)} />

            {isLicenceRequestEditable && (
              <Card size="small">
                <Row justify={hasRequestApplyPerm ? 'space-between' : 'end'}>
                  <Button
                    type={hasRequestApplyPerm ? 'default' : 'primary'}
                    icon={<SaveOutlined />}
                    onClick={() => updateLicenseRequestService.saveRequestFromStore()}
                  >
                    Сохранить
                  </Button>
                  {hasRequestApplyPerm && (
                    <Space>
                      <Button
                        type="primary"
                        icon={<CheckCircleOutlined />}
                        onClick={confirmLicenseRequest}
                      >
                        Подтвердить
                      </Button>

                      <Button
                        type="default"
                        danger={true}
                        icon={<CloseCircleOutlined />}
                        onClick={rejectLicenseRequest}
                      >
                        Отклонить
                      </Button>
                    </Space>
                  )}
                </Row>
              </Card>
            )}
          </>
        )}
      </Space>
    </AppPage>
  );
});
