import { ObservableRequest } from 'app/tools/observable-request';

import { INotifications } from 'core/entities/notifications';
import { makeAutoObservable } from 'mobx';

import { IHttpClient } from 'app/libs/http-client/types';
import { TTerminal, TTerminalPosConfig } from 'core/entities/terminal';
import { updateTerminalPosConfRequest } from 'app/api/requests/terminals';
import { BankLicenseStore } from '../bank-liceses/bank-license.store';

type UpdatePosConfigParams = {
  bankLicenseStore: BankLicenseStore;
  apiClient: IHttpClient;
  notify: INotifications;
};

export class UpdatePosConfigService {
  private updatePosConfigRequest: ObservableRequest<TTerminalPosConfig, TTerminal>;

  public get isLoading() {
    return this.updatePosConfigRequest.isLoading;
  }

  private bankLicenseStore: BankLicenseStore;

  constructor({ bankLicenseStore, apiClient, notify }: UpdatePosConfigParams) {
    this.bankLicenseStore = bankLicenseStore;

    const request = (config: TTerminalPosConfig) =>
      apiClient.fetch<TTerminal>(updateTerminalPosConfRequest(config));

    this.updatePosConfigRequest = new ObservableRequest(request, {
      done: (terminal) => bankLicenseStore.setTerminal(terminal),
      fail: (error) => notify.addMessage({ type: 'error', message: error.message }),
    });

    makeAutoObservable(this);
  }

  public updatePosConfig(config: TTerminalPosConfig['posConfig']) {
    const terminal = this.bankLicenseStore.data.terminal;

    if (terminal) {
      const patchedTerminal: TTerminalPosConfig = {
        id: terminal.id,
        posConfig: config,
      };

      this.updatePosConfigRequest.fetch(patchedTerminal);
    }
  }
}
