import { Space, Form, Card, Button, Input } from 'antd';
import { apiClient } from 'app/api';
import { AddBankService } from 'app/features/banks/add-bank/add-bank.service';
import { Notifications, NotificationsStore } from 'app/features/notifications/notifications';
import { AppPage } from 'app/ui/app-page';
import { TBankNew } from 'core/entities/bank';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loading } from 'app/ui/loading';

export const AddBankPage = () => {
  const navigate = useNavigate();
  const [notify] = useState(() => new NotificationsStore());

  const [addBank] = useState(() => {
    const service = new AddBankService({
      apiClient,
      navigate,
      notify,
    });

    return service;
  });

  return (
    <AppPage header="Добавление банка" subheader="Создание записи о банке">
      <Form
        layout="vertical"
        onFinish={(newBank: TBankNew) => addBank.addBank(newBank)}
        scrollToFirstError={{ behavior: 'smooth' }}
      >
        <Loading isActive={addBank.isLoading}>
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Card size="small">
              <div>
                <h2>Данные банка</h2>
                <div style={{ maxWidth: '50%' }}>
                  <Form.Item
                    label="Название банка"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Укажите название банка',
                      },
                    ]}
                  >
                    <Input style={{ width: '50%' }} />
                  </Form.Item>
                </div>
              </div>
            </Card>

            <Notifications
              messages={notify.messages}
              onClose={(msg) => notify.removeMessage(msg)}
            />

            <Card size="small">
              <div style={{ display: 'flex' }}>
                <Button type="primary" htmlType="submit" style={{ marginLeft: 'auto' }}>
                  Добавить банк
                </Button>
              </div>
            </Card>
          </Space>
        </Loading>
      </Form>
    </AppPage>
  );
};
