import { observer } from 'mobx-react-lite';
import { Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { INotifications } from 'core/entities/notifications';
import { useState } from 'react';
import { LogoutService } from '../logout.service';
import { apiClient } from 'app/api';

type LogoutButtonProps = {
  notify: INotifications;
};

export const LogoutButton = observer(({ notify }: LogoutButtonProps) => {
  const [logout] = useState(() => {
    const service = new LogoutService({
      apiClient,
      notify,
      onFinish: () => window.location.reload(),
    });

    return service;
  });

  return (
    <Button
      size="small"
      icon={<LogoutOutlined />}
      onClick={() => logout.exit()}
      loading={logout.isLoading}
    >
      Выход
    </Button>
  );
});
