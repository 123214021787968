import { ObservableRequest } from 'app/tools/observable-request';

import { INotifications } from 'core/entities/notifications';
import { makeAutoObservable } from 'mobx';

import { IHttpClient } from 'app/libs/http-client/types';
import { TBankLicense, TBankLicenseConstraints } from 'core/entities/bank-license';
import { getLicencesRequest } from 'app/api/requests/bank-licenses';
import { ICollectionStore } from 'core/entities/collection-store';
import { ConstraintsStore } from 'app/features/constraints/constraints.store';
import { TPageable } from 'core/entities/pageable';

type ChangableConstraints = {
  pagination: { current: number; pageSize: number };
  sort: TBankLicenseConstraints['sorting'];
};

type AllBankLicensesServiceParams = {
  apiClient: IHttpClient;
  notify: INotifications;
  licensesStore: ICollectionStore<TBankLicense>;
  constraints: ConstraintsStore<TBankLicenseConstraints>;
};

export class AllBankLicensesService {
  public licenses: ICollectionStore<TBankLicense>;

  private allBankLicensesRequest: ObservableRequest<
    TBankLicenseConstraints,
    TPageable<TBankLicense[]>
  >;

  public get isLoading() {
    return this.allBankLicensesRequest.isLoading;
  }

  private constraints: ConstraintsStore<TBankLicenseConstraints>;

  constructor({ apiClient, notify, licensesStore, constraints }: AllBankLicensesServiceParams) {
    this.licenses = licensesStore;
    this.constraints = constraints;

    const request = (constraints?: TBankLicenseConstraints) =>
      apiClient.fetch<TPageable<TBankLicense[]>>(getLicencesRequest(constraints));

    this.allBankLicensesRequest = new ObservableRequest(request, {
      done: (licenses) => this.setLicenses(licenses),
      fail: (error) => notify.addMessage({ type: 'error', message: error.message }),
    });

    makeAutoObservable(this);
  }

  private setLicenses({ content, ...pagination }: TPageable<TBankLicense[]>) {
    this.licenses.fillData(content);
    this.constraints.updatePageInfo(pagination);
  }

  public resetConstraints() {
    this.constraints.reset();
  }

  public fetchLicenses() {
    const constraints = this.constraints.data;

    this.allBankLicensesRequest.fetch(constraints);
  }

  public filterRecords(filters?: TBankLicenseConstraints['filter']) {
    if (filters) this.constraints.setFilters(filters);

    this.fetchLicenses();
  }

  public changeConstraints({ pagination, sort }: ChangableConstraints) {
    this.constraints.updatePageInfo({
      page: pagination.current,
      size: pagination.pageSize,
    });

    this.constraints.setSort(sort);

    this.fetchLicenses();
  }
}
