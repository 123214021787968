import { ObservableRequest } from 'app/tools/observable-request';

import { INotifications } from 'core/entities/notifications';
import { makeAutoObservable } from 'mobx';

import { IHttpClient } from 'app/libs/http-client/types';
import { BankLicenseStore } from '../bank-license.store';
import { TBankLicense } from 'core/entities/bank-license';
import { getLicenceRequest } from 'app/api/requests/bank-licenses';

type DetailsBankLicenseServiceParams = {
  bankLicenseStore: BankLicenseStore;
  apiClient: IHttpClient;
  notify: INotifications;
};

export class DetailsBankLicenseService {
  private bankLicenseRequest: ObservableRequest<TBankLicense['id'], TBankLicense>;

  public get isLoading() {
    return this.bankLicenseRequest.isLoading;
  }

  public get hasError() {
    return this.bankLicenseRequest.hasError;
  }

  constructor({ bankLicenseStore, apiClient, notify }: DetailsBankLicenseServiceParams) {
    const request = (id: TBankLicense['id']) =>
      apiClient.fetch<TBankLicense>(getLicenceRequest(id));

    this.bankLicenseRequest = new ObservableRequest(request, {
      done: (license) => bankLicenseStore.setData(license),
      fail: (error) => notify.addMessage({ type: 'error', message: error.message }),
    });

    makeAutoObservable(this);
  }

  fetchLicense(id: TBankLicense['id']) {
    this.bankLicenseRequest.fetch(id);
  }
}
