import {
  Space,
  Form,
  Card,
  Button,
  Row,
  Col,
  Table,
  Empty,
  InputNumber,
  TablePaginationConfig,
} from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import { apiClient } from 'app/api';
import { Notifications, NotificationsStore } from 'app/features/notifications/notifications';
import { useUserStore } from 'app/features/users/user.store';
import { AppPage } from 'app/ui/app-page';
import { Loading } from 'app/ui/loading';
import { Observer, observer } from 'mobx-react-lite';
import {
  CheckOutlined,
  ClearOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { AllBankLicensesService } from 'app/features/bank-liceses/all-bank-licenses/all-bank-licenses.service';
import { BankLicenseCollectionStore } from 'app/features/bank-liceses/bank-license-collection.store';
import { LicensesToRevokeStore } from 'app/features/revoke-license-requests/add-revoke-license-request/licenses-for-remove.store';
import { TBankLicense, TBankLicenseConstraints } from 'core/entities/bank-license';
import { toJS } from 'mobx';
import { licensesDetailsRoute } from '../routes';
import { AddRevokeLicenseRequestService } from 'app/features/revoke-license-requests/add-revoke-license-request/add-revoke-license-requests.service';
import { permissions } from 'app/features/users/permissions';
import { ConstraintsStore } from 'app/features/constraints/constraints.store';

export const AddRevokeLicenseRequestPage = observer(() => {
  const navigate = useNavigate();
  const userStore = useUserStore();
  const [licenseFiltersForm] = Form.useForm();

  const [notify] = useState(() => new NotificationsStore());
  const [availableLicensesStore] = useState(() => new BankLicenseCollectionStore());
  const [licensesToRevokeStore] = useState(() => new LicensesToRevokeStore());
  const [constraints] = useState(
    () => new ConstraintsStore<TBankLicenseConstraints>({ pageInfo: { size: 10 } }),
  );

  const [availableLicensesService] = useState(() => {
    const service = new AllBankLicensesService({
      apiClient,
      notify,
      constraints,
      licensesStore: availableLicensesStore,
    });

    return service;
  });

  const [addRevokeLicenseRequest] = useState(() => {
    const service = new AddRevokeLicenseRequestService({ apiClient, navigate, notify, userStore });

    return service;
  });

  const findLicensesByDsn = useCallback(
    // @ts-expect-error
    ({ dsn }: { dsn: string }) => availableLicensesService.filterRecords({ dsn }),
    [availableLicensesService],
  );

  const clearLicensesFilter = useCallback(() => {
    licenseFiltersForm.resetFields();
    availableLicensesService.resetConstraints();
    availableLicensesService.fetchLicenses();
  }, [availableLicensesService, licenseFiltersForm]);

  const addLicenseToRequest = (license: TBankLicense) => {
    licensesToRevokeStore.addItem(license);
  };
  const revokeLicenseFromRequest = (license: TBankLicense) => {
    licensesToRevokeStore.removeItem(license);
  };

  const clearRequestLicenses = () => {
    licensesToRevokeStore.clearItems();
  };

  const sendRevokeLicenseRequest = () => {
    addRevokeLicenseRequest.createLicenseRequest({ licences: licensesToRevokeStore.items });
  };

  const onChangeConstraints = useCallback(
    (pagination: TablePaginationConfig, _: any, sorter: any) => {
      const _pagination = {
        current: pagination.current || 1,
        pageSize: pagination.pageSize || 40,
      };

      let _sorter = [] as any;

      if (Object.keys(sorter).length > 0) {
        _sorter =
          Array.isArray(sorter) && sorter.length > 0
            ? sorter.map(({ field, order }) => ({ name: field, direction: order }))
            : [{ name: sorter.field, direction: sorter.order }];
      }

      availableLicensesService.changeConstraints({
        pagination: _pagination,
        sort: _sorter,
      });
    },
    [availableLicensesService],
  );

  const pagination: TablePaginationConfig = useMemo(() => {
    return {
      hideOnSinglePage: false,
      showSizeChanger: true,
      defaultPageSize: 10,
      current: constraints.data.pageInfo?.page,
      pageSize: constraints.data.pageInfo?.size,
      total: constraints.data.pageInfo?.totalElements,
    };
  }, [
    constraints.data.pageInfo?.page,
    constraints.data.pageInfo?.size,
    constraints.data.pageInfo?.totalElements,
  ]);

  useEffect(() => {
    availableLicensesService.filterRecords();
  }, [availableLicensesService]);

  const canViewLicenses = userStore.canAccess(permissions.PERM_BANK_LICENCE_READ);

  return (
    <AppPage header="Заявка на сброс лицензий" subheader="Создание заявки на сброс лицензий">
      <Loading isActive={availableLicensesService.isLoading || addRevokeLicenseRequest.isLoading}>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <Card size="small" title="Все лицензии">
            {/* == Search DSN Form == */}
            <Form form={licenseFiltersForm} layout="vertical" onFinish={findLicensesByDsn}>
              <Form.Item label="Поиск по DSN">
                <Row gutter={8}>
                  <Col
                    xxl={{ span: 19 }}
                    xl={{ span: 17 }}
                    lg={{ span: 13 }}
                    md={{ span: 12 }}
                    sm={{ span: 10 }}
                  >
                    <Form.Item name="dsn" noStyle>
                      <InputNumber controls={false} style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 5 }}
                    xl={{ span: 7 }}
                    lg={{ span: 11 }}
                    md={{ span: 12 }}
                    sm={{ span: 14 }}
                  >
                    <Row gutter={8}>
                      <Col span={12}>
                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} block>
                          Найти
                        </Button>
                      </Col>
                      <Col span={12}>
                        <Button onClick={clearLicensesFilter} icon={<ClearOutlined />} block>
                          Очистить
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Item>
            </Form>

            <Table
              rowKey="id"
              dataSource={availableLicensesStore.items}
              size="small"
              onChange={onChangeConstraints}
              pagination={pagination}
              footer={() => (
                <Row justify="end">
                  Количество лицензий:
                  <strong style={{ paddingLeft: 4 }}>
                    {availableLicensesStore.items.length} шт.
                  </strong>
                </Row>
              )}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Доступные для удаления лицензии отсутствуют"
                  />
                ),
              }}
              bordered
            >
              <Table.Column title="ID" width="1%" dataIndex="id" />

              <Table.Column title="DSN" width="200px" dataIndex="dsn" />

              {canViewLicenses && (
                <Table.Column<TBankLicense>
                  width="200px"
                  render={(_, record) => (
                    <Link
                      to={generatePath(licensesDetailsRoute.path, {
                        licenseId: String(record.id),
                      })}
                    >
                      Просмотр лицензии
                    </Link>
                  )}
                />
              )}

              <Table.Column<TBankLicense>
                title="Действие"
                width="200px"
                render={(_, record) => (
                  <Observer>
                    {() =>
                      licensesToRevokeStore.hasItem(record) ? (
                        <Button size="small" icon={<CheckOutlined />} disabled>
                          Уже добавлена
                        </Button>
                      ) : (
                        <Button
                          size="small"
                          icon={<PlusOutlined />}
                          onClick={() => addLicenseToRequest(record)}
                        >
                          Добавить в заявку
                        </Button>
                      )
                    }
                  </Observer>
                )}
              />
            </Table>
          </Card>

          <Card size="small" title="Добавленные лицензии в заявку на сброс">
            <Space direction="vertical" style={{ display: 'flex' }}>
              <Row justify="end">
                <Col>
                  <Button
                    icon={<ClearOutlined />}
                    onClick={clearRequestLicenses}
                    size="small"
                    danger
                  >
                    Очистить заявку
                  </Button>
                </Col>
              </Row>

              <Table
                rowKey={(row) => row.licenceId}
                dataSource={toJS(licensesToRevokeStore.items)}
                size="small"
                pagination={{
                  hideOnSinglePage: false,
                  showSizeChanger: true,
                  defaultPageSize: 10,
                }}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="В заявке отсутсвуют лицензии на отзыв"
                    />
                  ),
                }}
                footer={() => (
                  <Row justify="end">
                    Количество лицензий:
                    <strong style={{ paddingLeft: 4 }}>
                      {licensesToRevokeStore.items.length} шт.
                    </strong>
                  </Row>
                )}
                bordered
              >
                <Table.Column title="ID" width="1%" dataIndex="id" />

                <Table.Column title="DSN" width="200px" dataIndex="dsn" />

                <Table.Column<TBankLicense>
                  title="Действие"
                  width="200px"
                  render={(_, record) => (
                    <Button
                      size="small"
                      icon={<DeleteOutlined />}
                      onClick={() => revokeLicenseFromRequest(record)}
                    >
                      Убрать из заявки
                    </Button>
                  )}
                />
              </Table>
            </Space>
          </Card>

          <Notifications messages={notify.messages} onClose={(msg) => notify.removeMessage(msg)} />

          <Card size="small">
            <Row justify="end">
              <Button type="primary" onClick={sendRevokeLicenseRequest}>
                Отправить заявку
              </Button>
            </Row>
          </Card>
        </Space>
      </Loading>
    </AppPage>
  );
});
