import { ObservableRequest } from 'app/tools/observable-request';

import { INotifications } from 'core/entities/notifications';
import { makeAutoObservable } from 'mobx';

import { IHttpClient } from 'app/libs/http-client/types';
import { TBank, TBankConstraints } from 'core/entities/bank';
import { getBanksRequest } from 'app/api/requests/banks';
import { ConstraintsStore } from 'app/features/constraints/constraints.store';
import { TPageable } from 'core/entities/pageable';

type ChangableConstraints = {
  pagination: { current: number; pageSize: number };
  sort: TBankConstraints['sorting'];
};

type AllBanksServiceParams = {
  apiClient: IHttpClient;
  notify: INotifications;
  constraints: ConstraintsStore<TBankConstraints>;
};

export class AllBanksService {
  public banks: TBank[] = [];

  private allBanksRequest: ObservableRequest<TBankConstraints, TPageable<TBank[]>>;

  public get isLoading() {
    return this.allBanksRequest.isLoading;
  }

  private constraints: ConstraintsStore<TBankConstraints>;

  constructor({ apiClient, notify, constraints }: AllBanksServiceParams) {
    this.constraints = constraints;

    const request = (constraints?: TBankConstraints) =>
      apiClient.fetch<TPageable<TBank[]>>(getBanksRequest(constraints));

    this.allBanksRequest = new ObservableRequest(request, {
      done: (banks) => this.setBank(banks),
      fail: (error) => notify.addMessage({ type: 'error', message: error.message }),
    });

    makeAutoObservable(this);
  }

  setBank({ content, ...pagination }: TPageable<TBank[]>) {
    this.banks = content;
    this.constraints.updatePageInfo(pagination);
  }

  fetchBanks() {
    const constraints = this.constraints.data;

    this.allBanksRequest.fetch(constraints);
  }

  public filterRecords(filters: TBankConstraints['filter']) {
    this.constraints.setFilters(filters);

    this.fetchBanks();
  }

  public changeConstraints({ pagination, sort }: ChangableConstraints) {
    this.constraints.updatePageInfo({
      page: pagination.current,
      size: pagination.pageSize,
    });

    this.constraints.setSort(sort);

    this.fetchBanks();
  }
}
