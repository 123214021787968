import { getRolesRequest } from 'app/api/requests/users';
import { IHttpClient } from 'app/libs/http-client/types';
import { ObservableRequest } from 'app/tools/observable-request';
import { INotifications } from 'core/entities/notifications';
import { TRole, TRoleConstraints } from 'core/entities/role';
import { makeAutoObservable } from 'mobx';
import { RolesStore } from './roles.store';

type AvailableRolesServiceParams = {
  apiClient: IHttpClient;
  rolesStore: RolesStore;
  notify: INotifications;
};

export class AvailableRolesService {
  private availableRolesRequest: ObservableRequest<TRoleConstraints, TRole[]>;

  public get status() {
    return this.availableRolesRequest.status;
  }
  public get isLoading() {
    return this.availableRolesRequest.isLoading;
  }

  constructor({ rolesStore, apiClient, notify }: AvailableRolesServiceParams) {
    const request = (constraints?: TRoleConstraints) =>
      apiClient.fetch<TRole[]>(getRolesRequest(constraints));

    this.availableRolesRequest = new ObservableRequest(request, {
      done: (roles) => rolesStore.setData(roles),
      fail: (error) => notify.addMessage({ type: 'error', message: error.message }),
    });

    makeAutoObservable(this);
  }

  async getRoles(constraints?: TRoleConstraints) {
    this.availableRolesRequest.fetch(constraints);
  }
}
