import { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Tabs,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { Notifications, NotificationsStore } from 'app/features/notifications/notifications';

import { Loading } from 'app/ui/loading';
import { generatePath, Link, useParams } from 'react-router-dom';
import { apiClient } from 'app/api';
import { BankLicenseStore } from 'app/features/bank-liceses/bank-license.store';
import { DetailsBankLicenseService } from 'app/features/bank-liceses/details-bank-license/details-bank-license.service';
import { AppPage } from 'app/ui/app-page';
import { bankDetailsRoute, licensesRequestDetailsRoute } from '../routes';
import { BankLicenseStatus } from 'app/features/bank-liceses/ui/bank-license-status';
import { EditableLabel } from 'app/ui/editable-label';
import { UpdateBankLicenseService } from 'app/features/bank-liceses/update-bank-license/update-bank-license.service';
import { useUserStore } from 'app/features/users/user.store';
import { permissions } from 'app/features/users/permissions';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { UpdatePosConfigService } from 'app/features/terminals/update-pos-config.service';
import { CanAccess } from 'app/features/users/ui/can-access';
import { licenseKeySeparate } from 'app/features/bank-liceses/ui/license-key-separate';

export const DetailsBankLicensePage = observer(() => {
  const { licenseId } = useParams<{ licenseId: string }>();
  const [bankLicenseStore] = useState(() => new BankLicenseStore());
  const [notify] = useState(() => new NotificationsStore());

  const [bankLicenseService] = useState(
    () => new DetailsBankLicenseService({ apiClient, bankLicenseStore, notify }),
  );

  const [updateBankLicenseService] = useState(
    () => new UpdateBankLicenseService({ apiClient, bankLicenseStore, notify }),
  );

  const [updatePosConfigService] = useState(
    () => new UpdatePosConfigService({ apiClient, bankLicenseStore, notify }),
  );

  useEffect(() => {
    bankLicenseService.fetchLicense(Number(licenseId));
  }, [bankLicenseService, licenseId]);

  const [posConfigForm] = Form.useForm();

  useEffect(() => {
    const initialData = bankLicenseStore.data.terminal
      ? bankLicenseStore.data.terminal.posConfig
      : {};

    posConfigForm.setFieldsValue(initialData);
  }, [bankLicenseStore.data.terminal, posConfigForm]);

  const userStore = useUserStore();

  if (!Number.isInteger(Number(licenseId))) {
    return (
      <AppPage
        header="Данные лицензии"
        subheader="Просмотр информации о лицензии и используемом терминале"
      >
        <Alert
          type="error"
          message={
            <span>
              Некорректный ID лицензии: <b>{licenseId}</b>
            </span>
          }
          description="Проверьте ID лицензии в строке браузера"
          showIcon
        />
      </AppPage>
    );
  }

  if (!bankLicenseService.isLoading && bankLicenseService.hasError) {
    return (
      <AppPage
        header="Данные лицензии"
        subheader="Просмотр информации о лицензии и используемом терминале"
      >
        <Notifications messages={notify.messages} onClose={(m) => notify.removeMessage(m)} />
      </AppPage>
    );
  }

  const confirmLicenseReset = () =>
    Modal.confirm({
      title: 'Подтвердить сброс лицензии?',
      icon: <ExclamationCircleOutlined />,
      content: <div>Данные терминала для текущей лицензии будут сброшены</div>,
      onOk() {
        updateBankLicenseService.resetLicense();
      },
    });

  const license = bankLicenseStore.data;
  const isLoading =
    bankLicenseService.isLoading ||
    updateBankLicenseService.isLoading ||
    updatePosConfigService.isLoading;
  const isInnEditable = userStore.canAccess(permissions.PERM_BANK_LICENCE_UPDATE);
  const isLicenseEditable = userStore.canAccess(permissions.PERM_BANK_LICENCE_UPDATE);
  const isLicenseRequestsAvailable = userStore.canAccess(
    permissions.PERM_BANK_LICENCE_REQUEST_READ,
  );
  const isPosConfigAccessable = userStore.canAccess(permissions.PERM_TERMINAL_READ);

  return (
    <AppPage
      header="Данные лицензии"
      subheader="Просмотр информации о лицензии и используемом терминале"
    >
      <Space direction="vertical" style={{ display: 'flex' }}>
        <Notifications messages={notify.messages} onClose={(m) => notify.removeMessage(m)} />

        {isLoading ? (
          <Card size="small">
            <Loading isActive />
          </Card>
        ) : (
          <>
            <Card size="small">
              <Descriptions bordered size="small" column={1}>
                <Descriptions.Item
                  label="ID лицензии"
                  labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                >
                  {license.id}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Статус"
                  labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                >
                  <EditableLabel
                    label={(value: boolean) => <BankLicenseStatus status={value} />}
                    input={(value: string, onChange: any) => (
                      <Select
                        value={value}
                        size="small"
                        onChange={(_, option) =>
                          Array.isArray(option) ? null : onChange(option.value)
                        }
                        style={{ minWidth: 200 }}
                      >
                        <Select.Option value={true}>
                          <BankLicenseStatus status={true} />
                        </Select.Option>
                        <Select.Option value={false}>
                          <BankLicenseStatus status={false} />
                        </Select.Option>
                      </Select>
                    )}
                    value={license.blocked}
                    editable={isInnEditable}
                    onChange={(value: boolean) =>
                      updateBankLicenseService.changeField('blocked', value)
                    }
                  />
                </Descriptions.Item>

                {license.blockedDate && (
                  <Descriptions.Item
                    label="Дата блокировки"
                    labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                  >
                    {new Date(license.blockedDate).toLocaleString()}
                  </Descriptions.Item>
                )}

                <Descriptions.Item
                  label="Действительна с"
                  labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                >
                  {new Date(license.date).toLocaleString()}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Действителена по"
                  labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                >
                  {new Date(license.dateExpires).toLocaleString()}
                </Descriptions.Item>

                <Descriptions.Item label="Банк" labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}>
                  <Link
                    to={generatePath(bankDetailsRoute.path, { bankId: String(license.bank.id) })}
                  >
                    {license.bank.name}
                  </Link>
                </Descriptions.Item>

                {isLicenseRequestsAvailable && (
                  <Descriptions.Item
                    label="Заявка от банка"
                    labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                  >
                    {license.requestId ? (
                      <Link
                        to={generatePath(licensesRequestDetailsRoute.path, {
                          requestId: String(license.requestId),
                        })}
                      >
                        Открыть
                      </Link>
                    ) : (
                      'Отсутствует'
                    )}
                  </Descriptions.Item>
                )}

                <Descriptions.Item label="ИНН" labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}>
                  {license.inn ?? 'Отсутствует'}
                </Descriptions.Item>

                <Descriptions.Item
                  label="DSN из заявки"
                  labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                >
                  {license.dsn ?? 'Отсутствует'}
                </Descriptions.Item>

                <Descriptions.Item label="IP" labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}>
                  {license.simIp ?? 'Отсутствует'}
                </Descriptions.Item>

                <Descriptions.Item
                  label="Ключ лицензии"
                  labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                >
                  {license.key ? licenseKeySeparate(license.key) : 'Отсутствует'}
                </Descriptions.Item>
              </Descriptions>
            </Card>

            <Card size="small" bodyStyle={{ paddingTop: 0 }}>
              {isLoading ? (
                <div style={{ paddingTop: 12 }}>
                  <Loading isActive />
                </div>
              ) : (
                <Tabs defaultActiveKey="1" animated={false}>
                  <Tabs.TabPane tab="Терминал" key="1">
                    {license.terminal ? (
                      <Space direction="vertical" style={{ display: 'flex' }}>
                        <Descriptions bordered size="small" column={1}>
                          <Descriptions.Item
                            label="ID терминала"
                            labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                          >
                            {license.terminal.id}
                            <Divider type="vertical" />
                          </Descriptions.Item>

                          <Descriptions.Item
                            label="Тип терминала"
                            labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                          >
                            {license.terminal?.type?.name ?? 'Не указан'}
                          </Descriptions.Item>

                          <Descriptions.Item
                            label="DSN"
                            labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                          >
                            {license.terminal.dsn ?? 'Отсутствует'}
                          </Descriptions.Item>

                          <Descriptions.Item
                            label="Заводской номер"
                            labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                          >
                            {license.terminal.zn ?? 'Отсутствует'}
                          </Descriptions.Item>

                          {isLicenseEditable && (
                            <Descriptions.Item
                              label="Действия"
                              labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                            >
                              <Button danger onClick={confirmLicenseReset}>
                                Сбросить лицензию на терминале
                              </Button>
                            </Descriptions.Item>
                          )}
                        </Descriptions>
                      </Space>
                    ) : (
                      'Данные о подключенном терминале отсутствуют'
                    )}
                  </Tabs.TabPane>

                  {isPosConfigAccessable && (
                    <Tabs.TabPane tab="Настройки терминала" key="2">
                      {license.terminal ? (
                        <Form
                          layout="vertical"
                          form={posConfigForm}
                          onFinish={(config) => updatePosConfigService.updatePosConfig(config)}
                        >
                          {/* <Form.Item label="IP адрес" name>
                        <IpInputMask />
                      </Form.Item> */}
                          {/* <Form.Item label="Тип платформы" name="type">
                        <Select>
                          <Select.Option key="1" value={1}>
                            Paymob
                          </Select.Option>
                        </Select>
                      </Form.Item> */}
                          <Row gutter={16}>
                            <Col span={8}>
                              <Form.Item label="Протокол" name="https">
                                <Select>
                                  <Select.Option key="1" value={false}>
                                    http
                                  </Select.Option>
                                  <Select.Option key="2" value={true}>
                                    https
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item label="Адрес сервера предхоста" name="host">
                                <Input placeholder="example.com" />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item label="Порт сервера предхоста" name="port">
                                <InputNumber style={{ width: '100%' }} placeholder="4242" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Form.Item
                            label="Пароль"
                            help="Для авторизации по ID терминала"
                            name="password"
                          >
                            <Input.Password />
                          </Form.Item>

                          <CanAccess permission={permissions.PERM_TERMINAL_POS_CONF_UPDATE}>
                            <Row justify="end">
                              <Button type="primary" htmlType="submit">
                                Сохранить
                              </Button>
                            </Row>
                          </CanAccess>
                        </Form>
                      ) : (
                        'Данные о подключенном терминале отсутствуют'
                      )}
                    </Tabs.TabPane>
                  )}
                </Tabs>
              )}
            </Card>
          </>
        )}
      </Space>
    </AppPage>
  );
});
