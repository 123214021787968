import { Badge } from 'antd';
import { TBankLicense } from 'core/entities/bank-license';

type BankLicenseStatusProps = {
  status: TBankLicense['blocked'];
};

export const BankLicenseStatus = ({ status }: BankLicenseStatusProps) => {
  return status ? (
    <Badge status="error" text="Заблокирована" />
  ) : (
    <Badge status="success" text="Активна" />
  );
};
