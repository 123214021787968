import { TBank } from 'core/entities/bank';
import { TChangeBankLicense, TChangeLicenseRequest } from 'core/entities/change-license-request';

import { makeAutoObservable } from 'mobx';

export class ChangeLicenseRequestStore {
  public data: TChangeLicenseRequest = {
    id: -1,
    bank: {
      id: -1,
      name: '',
    },
    date: '',
    status: 0,
    statusDescription: '',
    licences: [],
  };

  constructor(id: TBank['id']) {
    this.setId(id);

    makeAutoObservable(this);
  }

  get status() {
    return this.data.status;
  }

  get statusDescription() {
    return this.data.statusDescription ?? '';
  }

  setId(id: TChangeLicenseRequest['id']) {
    this.data.id = id;
  }

  setData(licenseRequest: TChangeLicenseRequest) {
    this.data = licenseRequest;
  }

  updateLicenses(licences: TChangeLicenseRequest['licences']) {
    this.data.licences = licences;
  }

  deleteLicense(license: TChangeBankLicense) {
    this.data.licences = this.data.licences.filter((lic) => lic.licenceId !== license.licenceId);
  }

  updateLicense(license: TChangeBankLicense) {
    const foundIndex = this.data.licences.findIndex((lic) => lic.licenceId === license.licenceId);

    this.data.licences[foundIndex] = license;
  }

  setStatusDescription(value: TChangeLicenseRequest['statusDescription']) {
    this.data.statusDescription = value;
  }
}
