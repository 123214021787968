import { makeAutoObservable } from 'mobx';
import {
  INotifications,
  TNewNotification,
  TNotification,
  TRemovedNotification,
} from 'core/entities/notifications';

let messageId: number = 0;
const nextId = () => (messageId < Number.MAX_SAFE_INTEGER ? ++messageId : (messageId = 0));

export class NotificationsStore implements INotifications {
  private _messages: TNotification[] = [];

  get messages() {
    return this._messages;
  }

  get lastMessage(): TNotification {
    return this._messages[this._messages.length];
  }

  constructor() {
    makeAutoObservable(this);
  }

  addMessage(notification: TNewNotification): void {
    this._messages.push({ ...notification, id: nextId() });
  }
  removeMessage(notification: TRemovedNotification): void {
    this._messages = this._messages.filter((msg) => msg.id !== notification.id);
  }
  clearAll(): void {
    this._messages = [];
  }
}
