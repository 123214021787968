import { EventPredicate, Handler } from './types';

type NotifyHandler = (predicate: EventPredicate, handler: Handler) => void;

export class Subscribers {
  private events: Map<EventPredicate, Handler> = new Map();

  public on(event: EventPredicate, handler: Handler) {
    this.events.set(event, handler);
    return () => void this.events.delete(event);
  }

  public notifyHandlers(fn: NotifyHandler) {
    this.events.forEach((value, key) => fn(key, value));
  }
}
