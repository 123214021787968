import { PageContent } from 'UI/page-content';
import { PageHeader } from 'UI/page-header';

type AppPageProps = {
  header: string;
  subheader?: string;
  after?: React.ReactNode;
};

export const AppPage: React.FC<AppPageProps> = ({ header, subheader, after, children }) => {
  return (
    <>
      <PageHeader header={header} subheader={subheader} after={after} />
      <PageContent>{children}</PageContent>
    </>
  );
};
