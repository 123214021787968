import { ObservableRequest } from 'app/tools/observable-request';

import { INotifications } from 'core/entities/notifications';
import { makeAutoObservable } from 'mobx';

import { IHttpClient } from 'app/libs/http-client/types';
import {
  TRevokeLicenseRequest,
  TRevokeLicenseRequestsConstraints,
} from 'core/entities/revoke-license-request';
import { getRevokeLicenceRequestsRequest } from 'app/api/requests/revoke-license-requests';
import { ConstraintsStore } from 'app/features/constraints/constraints.store';
import { TPageable } from 'core/entities/pageable';

type ChangableConstraints = {
  pagination: { current: number; pageSize: number };
  sort: TRevokeLicenseRequestsConstraints['sorting'];
};

type AllRevokeLicenseRequestsServiceParams = {
  apiClient: IHttpClient;
  notify: INotifications;
  constraints: ConstraintsStore<TRevokeLicenseRequestsConstraints>;
};

export class AllRevokeLicenseRequestsService {
  public requests: TRevokeLicenseRequest['request'][] = [];

  private allRevokeLicenseRequest: ObservableRequest<
    TRevokeLicenseRequestsConstraints,
    TPageable<TRevokeLicenseRequest['request'][]>
  >;

  public get isLoading() {
    return this.allRevokeLicenseRequest.isLoading;
  }

  private constraints: ConstraintsStore<TRevokeLicenseRequestsConstraints>;

  constructor({ apiClient, notify, constraints }: AllRevokeLicenseRequestsServiceParams) {
    this.constraints = constraints;

    const request = (constraints?: TRevokeLicenseRequestsConstraints) =>
      apiClient.fetch<TPageable<TRevokeLicenseRequest['request'][]>>(
        getRevokeLicenceRequestsRequest(constraints),
      );

    this.allRevokeLicenseRequest = new ObservableRequest(request, {
      done: (licenseRequests) => this.setLicenseRequests(licenseRequests),
      fail: (error) => notify.addMessage({ type: 'error', message: error.message }),
    });

    makeAutoObservable(this);
  }

  private setLicenseRequests({
    content,
    ...pagination
  }: TPageable<TRevokeLicenseRequest['request'][]>) {
    this.requests = content;
    this.constraints.updatePageInfo(pagination);
  }

  public fetchLicenseRequests() {
    const constraints = this.constraints.data;

    this.allRevokeLicenseRequest.fetch(constraints);
  }

  public filterRecords(filters: TRevokeLicenseRequestsConstraints['filter']) {
    this.constraints.setFilters(filters);
    this.fetchLicenseRequests();
  }

  public changeConstraints({ pagination, sort }: ChangableConstraints) {
    this.constraints.updatePageInfo({
      page: pagination.current,
      size: pagination.pageSize,
    });

    this.constraints.setSort(sort);

    this.fetchLicenseRequests();
  }
}
