import React from 'react';
import { MaskedInput } from 'antd-mask-input';
import { InputMaskOptions } from 'antd-mask-input/build/main/lib/MaskedInput';

const ipMaskParams: InputMaskOptions = {
  blocks: {
    IP: {
      mask: [
        // {
        //   mask: '\\0',
        // },
        {
          mask: Number,
          scale: 0,
          min: 0,
          max: 255,
        },
      ],
    },
  },
};

type IpInputProps = Omit<
  React.ComponentProps<typeof MaskedInput>,
  'mask' | 'maskOptions' | 'definitions'
>;
export const IpInputMask: React.VFC<IpInputProps> = (props) => (
  // <MaskedInput mask={ipMaskParams.mask} blocks={ipMaskParams.blocks} {...props} />
  // <MaskedInput {...props} mask="0[0][0].0[0][0].0[0][0].0[0][0]" />
  <MaskedInput {...props} mask={'IP{.}`IP{.}`IP{.}`IP'} maskOptions={ipMaskParams} />
);
