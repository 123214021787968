import { makeAutoObservable } from 'mobx';
import { INotifications } from 'core/entities/notifications';
import { ObservableRequest } from 'app/tools/observable-request';
import { IHttpClient } from 'app/libs/http-client/types';
import { generatePath, NavigateFunction } from 'react-router-dom';
import { revokeLicenseRequestDetailsRoute } from 'app/pages/routes';
import { UserStore } from 'app/features/users/user.store';
import {
  TRevokeLicenseRequest,
  TRevokeLicenseRequestNew,
} from 'core/entities/revoke-license-request';
import { addRevokeLicenceRequestRequest } from 'app/api/requests/revoke-license-requests';

type AddRevokeLicenseRequestServiceParams = {
  apiClient: IHttpClient;
  userStore: UserStore;
  navigate: NavigateFunction;
  notify: INotifications;
};

export class AddRevokeLicenseRequestService {
  private addRevokeLicenseRequest: ObservableRequest<
    TRevokeLicenseRequestNew,
    TRevokeLicenseRequest['request']
  >;

  public get status() {
    return this.addRevokeLicenseRequest.status;
  }

  public get isLoading() {
    return this.addRevokeLicenseRequest.isLoading;
  }

  private notify: INotifications;
  private navigate: NavigateFunction;

  constructor({ apiClient, notify, navigate, userStore }: AddRevokeLicenseRequestServiceParams) {
    this.notify = notify;
    this.navigate = navigate;

    const request = (newRequest: TRevokeLicenseRequestNew) => {
      const requestData = { ...newRequest, bank: userStore.data.bank };

      return apiClient.fetch<TRevokeLicenseRequest['request']>(
        addRevokeLicenceRequestRequest(requestData),
      );
    };

    this.addRevokeLicenseRequest = new ObservableRequest(request, {
      done: (data) => this.requestCreated(data.id),
      fail: (error) => this.addError(error.message),
    });

    makeAutoObservable(this);
  }

  public async createLicenseRequest(licenceRequest: TRevokeLicenseRequestNew) {
    const isValidRequest = this.validateLicenseRequest(licenceRequest);
    if (!isValidRequest) return null;

    this.addRevokeLicenseRequest.fetch(licenceRequest);
  }

  private validateLicenseRequest(licenceRequest: TRevokeLicenseRequestNew) {
    const isEmptyRequest = licenceRequest.licences.length > 0;

    if (!isEmptyRequest) {
      this.notify.addMessage({
        type: 'error',
        message: 'В заявке отсутствуют лицензии на удаление',
      });
      return false;
    }

    return true;
  }

  private requestCreated(requestId: TRevokeLicenseRequest['request']['id']) {
    this.navigate(
      generatePath(revokeLicenseRequestDetailsRoute.path, { requestId: String(requestId) }),
    );
  }

  private addError(error: string) {
    this.notify.addMessage({ type: 'error', message: error });
  }
}
