import { makeAutoObservable } from 'mobx';
import { TUser, TUpdatedUser } from 'core/entities/user';
import { UserStore } from '../user.store';
import { INotifications } from 'core/entities/notifications';
import { ObservableRequest } from 'app/tools/observable-request';
import { IHttpClient } from 'app/libs/http-client/types';
import { updateUserRequest } from 'app/api/requests/users';

type ChangeUserDataServiceParams = {
  apiClient: IHttpClient;
  userStore: UserStore;
  notify: INotifications;
};

export class ChangeUserDataService {
  private updateUserRequest: ObservableRequest<Omit<TUpdatedUser, 'id'>, TUser>;

  public get status() {
    return this.updateUserRequest.status;
  }

  public get isLoading() {
    return this.updateUserRequest.isLoading;
  }

  private userStore: UserStore;
  private notify: INotifications;

  constructor({ userStore, apiClient, notify }: ChangeUserDataServiceParams) {
    this.userStore = userStore;
    this.notify = notify;

    const request = (updatedUser: Omit<TUpdatedUser, 'id'>) => {
      // const userData = { id: userStore.data.id, ...updatedUser };
      const userData = { ...userStore.data, ...updatedUser };

      return apiClient.fetch<TUser>(updateUserRequest(userData));
    };

    this.updateUserRequest = new ObservableRequest(request, {
      done: (user) => this.userUpdated(user),
      fail: (error) => this.updatingError(error.message),
    });

    makeAutoObservable(this);
  }

  private userUpdated(user: TUser) {
    this.userStore.setData(user);

    this.notify.addMessage({
      type: 'success',
      message: 'Данные пользователя были обновлены',
    });
  }

  private updatingError(error: string) {
    this.notify.addMessage({ type: 'error', message: error });
  }

  async updateData(updatedUser: Omit<TUpdatedUser, 'id'>) {
    this.updateUserRequest.fetch(updatedUser);
  }
}
