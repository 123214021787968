import { TBank } from 'core/entities/bank';
import { makeAutoObservable } from 'mobx';

export class BankStore {
  public data: TBank = {
    id: -1,
    name: '',
  };

  constructor(id: TBank['id']) {
    this.setId(id);

    makeAutoObservable(this);
  }

  setId(id: TBank['id']) {
    this.data.id = id;
  }

  setData(bank: TBank) {
    this.data = bank;
  }
}
