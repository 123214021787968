import { Badge } from 'antd';
import { EChangeLicenseRequestStatus } from 'core/entities/change-license-request';

type LicenseRequestStatusProps = { status: EChangeLicenseRequestStatus };

export const ChangeLicenseRequestStatus = ({ status }: LicenseRequestStatusProps) => {
  switch (status) {
    case EChangeLicenseRequestStatus.created:
      return <Badge status="processing" text="В обработке" />;
    case EChangeLicenseRequestStatus.confirmed:
      return <Badge status="success" text="Подтверждена" />;
    case EChangeLicenseRequestStatus.rejected:
      return <Badge status="error" text="Отклонена" />;
  }
};
