import {
  Space,
  Form,
  Card,
  Button,
  Row,
  Col,
  Table,
  Empty,
  InputNumber,
  Divider,
  TablePaginationConfig,
} from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import { apiClient } from 'app/api';
import { Notifications, NotificationsStore } from 'app/features/notifications/notifications';
import { useUserStore } from 'app/features/users/user.store';
import { AppPage } from 'app/ui/app-page';
import { Loading } from 'app/ui/loading';
import { Observer, observer } from 'mobx-react-lite';
import {
  CheckOutlined,
  ClearOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { AllBankLicensesService } from 'app/features/bank-liceses/all-bank-licenses/all-bank-licenses.service';
import { BankLicenseCollectionStore } from 'app/features/bank-liceses/bank-license-collection.store';
import { TBankLicense, TBankLicenseConstraints } from 'core/entities/bank-license';
import { licensesDetailsRoute } from '../routes';
import { AddChangeLicenseRequestService } from 'app/features/change-license-requests/add-change-license-request/add-change-license-requests.service';
import { permissions } from 'app/features/users/permissions';
import { LicensesToChangeStore } from 'app/features/change-license-requests/add-change-license-request/licenses-for-change.store';
// import {
//   ContextDsnChangeErrors,
//   NewDsnFormItem,
// } from 'app/features/change-license-requests/add-change-license-request/ui/new-dsn-form-item';

import { DsnMaskedInput } from 'app/ui/masked-input';
import { InnMaskedInput } from 'app/ui/masked-input/inn-masked-input';
import { ConstraintsStore } from 'app/features/constraints/constraints.store';

export const AddChangeLicenseRequestPage = observer(() => {
  const navigate = useNavigate();
  const userStore = useUserStore();
  const [licenseFiltersForm] = Form.useForm();

  const [notify] = useState(() => new NotificationsStore());
  const [availableLicensesStore] = useState(() => new BankLicenseCollectionStore());
  const [licensesToChangeStore] = useState(() => new LicensesToChangeStore());

  const [constraints] = useState(
    () => new ConstraintsStore<TBankLicenseConstraints>({ pageInfo: { size: 10 } }),
  );
  const [availableLicensesService] = useState(() => {
    const service = new AllBankLicensesService({
      apiClient,
      notify,
      licensesStore: availableLicensesStore,
      constraints,
    });

    return service;
  });

  const [addRevokeLicenseRequest] = useState(() => {
    const service = new AddChangeLicenseRequestService({ apiClient, navigate, notify, userStore });

    return service;
  });

  const findLicensesByDsn = useCallback(
    // @ts-expect-error
    ({ dsn }: { dsn: string }) => availableLicensesService.filterRecords({ dsn }),
    [availableLicensesService],
  );

  const clearLicensesFilter = useCallback(() => {
    licenseFiltersForm.resetFields();
    availableLicensesService.resetConstraints();
    availableLicensesService.fetchLicenses();
  }, [availableLicensesService, licenseFiltersForm]);

  // =======
  const addLicenseToRequest = (license: TBankLicense) => {
    licensesToChangeStore.addItem(license);
    const formValues = licenseEditForm.getFieldValue('editLicenses');
    const newFormValues = formValues.concat(license);

    licenseEditForm.setFieldValue('editLicenses', newFormValues);
  };
  // =======

  const removeLicenseFromRequest = (removableLicense: TBankLicense) => {
    licensesToChangeStore.removeItem(removableLicense);
  };

  const clearRequestLicenses = () => {
    licensesToChangeStore.clearItems();
    licenseEditForm.setFieldValue('editLicenses', []);
  };

  const sendChabgeLicenseRequest = () => {
    licenseEditForm
      .validateFields()
      .then((fields) => {
        const result = fields.editLicenses.map((license: TBankLicense) => ({
          ...license,
          licenceId: license.id,
        }));

        addRevokeLicenseRequest.createLicenseRequest({ licences: result });
      })
      .catch(console.log);
  };

  useEffect(() => {
    availableLicensesService.filterRecords();
  }, [availableLicensesService]);

  const [licenseEditForm] = Form.useForm();

  const onChangeConstraints = useCallback(
    (pagination: TablePaginationConfig, _: any, sorter: any) => {
      const _pagination = {
        current: pagination.current || 1,
        pageSize: pagination.pageSize || 40,
      };

      let _sorter = [] as any;

      if (Object.keys(sorter).length > 0) {
        _sorter =
          Array.isArray(sorter) && sorter.length > 0
            ? sorter.map(({ field, order }) => ({ name: field, direction: order }))
            : [{ name: sorter.field, direction: sorter.order }];
      }

      availableLicensesService.changeConstraints({
        pagination: _pagination,
        sort: _sorter,
      });
    },
    [availableLicensesService],
  );

  const pagination: TablePaginationConfig = useMemo(() => {
    return {
      hideOnSinglePage: false,
      showSizeChanger: true,
      defaultPageSize: 10,
      current: constraints.data.pageInfo?.page,
      pageSize: constraints.data.pageInfo?.size,
      total: constraints.data.pageInfo?.totalElements,
    };
  }, [
    constraints.data.pageInfo?.page,
    constraints.data.pageInfo?.size,
    constraints.data.pageInfo?.totalElements,
  ]);

  const canViewLicenses = userStore.canAccess(permissions.PERM_BANK_LICENCE_READ);

  return (
    <AppPage header="Заявка на сброс лицензий" subheader="Создание заявки на сброс лицензий">
      <Loading isActive={availableLicensesService.isLoading || addRevokeLicenseRequest.isLoading}>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <Card size="small" title="Все лицензии">
            {/* == Search DSN Form == */}
            <Form form={licenseFiltersForm} layout="vertical" onFinish={findLicensesByDsn}>
              <Form.Item label="Поиск по DSN">
                <Row gutter={8}>
                  <Col
                    xxl={{ span: 19 }}
                    xl={{ span: 17 }}
                    lg={{ span: 13 }}
                    md={{ span: 12 }}
                    sm={{ span: 10 }}
                  >
                    <Form.Item name="dsn" noStyle>
                      <InputNumber controls={false} style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 5 }}
                    xl={{ span: 7 }}
                    lg={{ span: 11 }}
                    md={{ span: 12 }}
                    sm={{ span: 14 }}
                  >
                    <Row gutter={8}>
                      <Col span={12}>
                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} block>
                          Найти
                        </Button>
                      </Col>
                      <Col span={12}>
                        <Button onClick={clearLicensesFilter} icon={<ClearOutlined />} block>
                          Очистить
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Item>
            </Form>

            <Table
              rowKey="id"
              dataSource={availableLicensesStore.items}
              size="small"
              pagination={pagination}
              onChange={onChangeConstraints}
              footer={() => (
                <Row justify="end">
                  Количество лицензий:
                  <strong style={{ paddingLeft: 4 }}>
                    {availableLicensesStore.items.length} шт.
                  </strong>
                </Row>
              )}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Доступные для удаления лицензии отсутствуют"
                  />
                ),
              }}
              bordered
            >
              <Table.Column title="ID" width="1%" dataIndex="id" />

              <Table.Column title="DSN" width="200px" dataIndex="dsn" />

              {canViewLicenses && (
                <Table.Column<TBankLicense>
                  width="200px"
                  render={(_, record) => (
                    <Link
                      to={generatePath(licensesDetailsRoute.path, {
                        licenseId: String(record.id),
                      })}
                    >
                      Просмотр лицензии
                    </Link>
                  )}
                />
              )}

              <Table.Column<TBankLicense>
                title="Действие"
                width="200px"
                render={(_, record) => (
                  <Observer>
                    {() =>
                      licensesToChangeStore.hasItem(record) ? (
                        <Button size="small" icon={<CheckOutlined />} disabled>
                          Уже добавлена
                        </Button>
                      ) : (
                        <Button
                          size="small"
                          icon={<PlusOutlined />}
                          onClick={() => addLicenseToRequest(record)}
                        >
                          Добавить в заявку
                        </Button>
                      )
                    }
                  </Observer>
                )}
              />
            </Table>
          </Card>

          <Card size="small" title="Лицензии для изменений">
            <Space direction="vertical" style={{ display: 'flex' }}>
              <Row justify="end">
                <Col>
                  <Button
                    icon={<ClearOutlined />}
                    onClick={clearRequestLicenses}
                    size="small"
                    danger
                  >
                    Очистить заявку
                  </Button>
                </Col>
              </Row>
              <Form
                initialValues={{ editLicenses: [] }}
                form={licenseEditForm}
                onFinish={console.log}
              >
                <Form.List
                  name="editLicenses"
                  rules={[
                    {
                      validator(_, data = []) {
                        if (data.length === 0) {
                          return Promise.reject('В заявку не добавлены лицензии для изменения');
                        }

                        const result = data.some(({ newDsn, newInn }: any) =>
                          Boolean(newDsn || newInn),
                        );

                        return result
                          ? Promise.resolve(data)
                          : Promise.reject('В добавленных лицензиях не заполнены поля');
                      },
                    },
                  ]}
                >
                  {(records: any, { remove }, { errors }) => (
                    <>
                      <Table
                        dataSource={records}
                        size="small"
                        pagination={{
                          hideOnSinglePage: false,
                          showSizeChanger: true,
                          defaultPageSize: 10,
                        }}
                        locale={{
                          emptyText: (
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              description="В заявке отсутсвуют лицензии на отзыв"
                            />
                          ),
                        }}
                        footer={() => (
                          <Row justify="end">
                            Количество лицензий:
                            <strong style={{ paddingLeft: 4 }}>
                              {licensesToChangeStore.items.length} шт.
                            </strong>
                          </Row>
                        )}
                        onRow={(_, idx = 1) =>
                          idx % 2 ? { style: { background: '#FBFCFE' } } : {}
                        }
                        bordered
                      >
                        <Table.Column
                          title="ID"
                          width="5%"
                          dataIndex="id"
                          align="center"
                          render={(_, record: any) =>
                            licenseEditForm.getFieldValue(['editLicenses', record.name, 'id'])
                          }
                        />
                        <Table.Column
                          title="Поля"
                          width="10%"
                          onCell={() => ({ style: { padding: '0.5em 0' } })}
                          render={() => (
                            <Row align="middle" style={{ flexDirection: 'column' }}>
                              <Col span={24} style={{ padding: '0 0.5em' }}>
                                DSN
                              </Col>
                              <Divider style={{ margin: '0.92em 0' }} />
                              <Col span={24} style={{ padding: '0 0.5em' }}>
                                ИНН
                              </Col>
                            </Row>
                          )}
                        />
                        <Table.Column
                          title="Текущее значение"
                          width="25%"
                          onCell={() => ({ style: { padding: '8px 0' } })}
                          render={(_, record: any) => {
                            const dsnFieldValue = licenseEditForm.getFieldValue([
                              'editLicenses',
                              record.name,
                              'dsn',
                            ]);

                            const innFieldValue = licenseEditForm.getFieldValue([
                              'editLicenses',
                              record.name,
                              'inn',
                            ]);

                            return (
                              <>
                                <div style={{ textAlign: 'center' }}>{dsnFieldValue ?? '-'}</div>
                                <Divider style={{ margin: '0.92em 0' }} />
                                <div style={{ textAlign: 'center' }}>{innFieldValue ?? '-'}</div>
                              </>
                            );
                          }}
                        />
                        <Table.Column
                          title="Новое значение"
                          width="25%"
                          onCell={() => ({ style: { padding: '8px 0' } })}
                          render={(_, record: any) => (
                            <>
                              <Row align="middle">
                                <Col span={24}>
                                  <Form.Item
                                    name={[record.name, 'newDsn']}
                                    style={{ margin: '0 8px' }}
                                    validateFirst
                                    rules={[
                                      {
                                        len: 11,
                                        message: 'DSN должен быть из 11 цифр',
                                        validateTrigger: 'onSubmit',
                                      },
                                      ({ getFieldValue }) => ({
                                        validator({ field }: any, value) {
                                          if (!value) return Promise.resolve(value);

                                          const formFields = getFieldValue('editLicenses');
                                          const { id: licId } = getFieldValue([
                                            'editLicenses',
                                            record.name,
                                          ]);

                                          const formFieldsValues = formFields.filter(
                                            ({ id }: any) => id !== licId,
                                          );

                                          if (formFieldsValues.length === 0) {
                                            return Promise.resolve(value);
                                          }

                                          const result = formFieldsValues.find(
                                            ({ newDsn }: any) => newDsn === value,
                                          )
                                            ? Promise.reject('Такой DSN уже используется')
                                            : Promise.resolve(value);

                                          return result;
                                        },
                                        validateTrigger: 'onSubmit',
                                      }),
                                    ]}
                                  >
                                    <DsnMaskedInput size="small" placeholder="DSN" />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row align="middle">
                                <Divider style={{ margin: '8px 0' }} />
                              </Row>
                              <Row align="middle">
                                <Col span={24}>
                                  <Form.Item
                                    name={[record.name, 'newInn']}
                                    style={{ margin: '0 8px' }}
                                    validateFirst
                                    rules={[
                                      {
                                        len: 8,
                                        message: 'ИНН должен быть из 8 цифр',
                                        validateTrigger: 'onSubmit',
                                      },
                                      ({ getFieldValue }) => ({
                                        validator(_: any, value) {
                                          if (!value) return Promise.resolve(value);

                                          const formFields = getFieldValue('editLicenses');
                                          const { id: licId } = getFieldValue([
                                            'editLicenses',
                                            record.name,
                                          ]);

                                          const formFieldsValues = formFields.filter(
                                            ({ id }: any) => id !== licId,
                                          );

                                          if (formFieldsValues.length === 0) {
                                            return Promise.resolve(value);
                                          }

                                          const result = formFieldsValues.find(
                                            ({ newInn }: any) => newInn === value,
                                          )
                                            ? Promise.reject('Такой ИНН уже используется')
                                            : Promise.resolve(value);

                                          return result;
                                        },
                                        validateTrigger: 'onSubmit',
                                      }),
                                    ]}
                                  >
                                    <InnMaskedInput placeholder="ИНН" size="small" />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          )}
                        />
                        <Table.Column
                          title="Действие"
                          render={(_, record: any) => (
                            <Button
                              size="small"
                              icon={<DeleteOutlined />}
                              onClick={() => {
                                const license = licenseEditForm.getFieldValue([
                                  'editLicenses',
                                  record.name,
                                ]);

                                removeLicenseFromRequest(license);
                                remove(record.name);
                              }}
                            >
                              Убрать из заявки
                            </Button>
                          )}
                        />
                      </Table>
                      <div style={{ textAlign: 'right' }}>
                        <Form.ErrorList errors={errors} />
                      </div>
                    </>
                  )}
                </Form.List>
              </Form>
            </Space>
          </Card>

          <Notifications messages={notify.messages} onClose={(msg) => notify.removeMessage(msg)} />

          <Card size="small">
            <Row justify="end">
              <Button type="primary" onClick={sendChabgeLicenseRequest}>
                Отправить заявку
              </Button>
            </Row>
          </Card>
        </Space>
      </Loading>
    </AppPage>
  );
});
