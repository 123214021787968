import React from 'react';
import * as Sentry from '@sentry/react';

function FallbackComponent() {
  return <div>Упс, что-то пошло не так, но мы уже знаем об этом </div>;
}

export const ErrorBoundary: React.FC = ({ children }) =>
  process.env.NODE_ENV === 'development' ? (
    <>{children}</>
  ) : (
    <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog dialogOptions={{ lang: 'ru' }}>
      {children}
    </Sentry.ErrorBoundary>
  );
