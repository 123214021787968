import React from 'react';
import { Avatar, Col, Layout, Menu, Row } from 'antd';
import { UserOutlined, DeploymentUnitOutlined } from '@ant-design/icons';
import { Link, Outlet } from 'react-router-dom';
import useLocalStorage from 'use-local-storage';
import { observer } from 'mobx-react-lite';
import { useUserStore } from 'app/features/users/user.store';
import { mainMenuItems } from 'app/menu/main-menu';
import appPackage from '../../../../package.json';

const { Header, Content, Footer, Sider } = Layout;

const AppVersion = () => {
  const version = appPackage.version;

  return <span>Версия: {version}</span>;
};

const UserShortData = observer(() => {
  const user = useUserStore().data;

  return (
    <div>
      <div>
        <Link to="/profile" style={{ color: '#fff' }}>
          {user.login}
        </Link>
      </div>
      <div style={{ fontSize: 12, color: 'gray' }}>ID: {user.id}</div>
    </div>
  );
});

const Logo = () => {
  return (
    <div
      style={{
        fontSize: 24,
        color: '#fff',
        lineHeight: 1.5,
      }}
    >
      <DeploymentUnitOutlined />
    </div>
  );
};

export const BaseLayout = observer(() => {
  const [menuCollapsed, setColapsed] = React.useState(false);
  const [defaultOpenKeys, setOpenKeys] = useLocalStorage('menuDefaultOpenKeys', [
    'bank-apps',
    'pos-apps',
    'licenses',
    'license-requests',
    'remove-license-requests',
    'change-license-requests',
  ]);

  const user = useUserStore();

  const permissionMenu = React.useMemo(() => {
    const menu = mainMenuItems();

    const result = menu.reduce<typeof menu>((acc, item) => {
      // @ts-expect-error
      if (user.canAccess(item.permission)) {
        // @ts-expect-error
        const children = item.children
          ? // @ts-expect-error
            item.children.filter((el) => user.canAccess(el.permission))
          : [];
        // @ts-expect-error
        item.children = children;
        acc.push(item);

        return acc;
      }

      return acc;
    }, []);

    return result;
  }, [user]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header
        style={{
          padding: '0 16px',
          background: '#222C39',
          boxShadow: '0 1px 4px rgb(0 21 41 / 8%)',
        }}
      >
        <Row align="middle" style={{ minHeight: '100%' }}>
          <Col span={4}>
            <Link to="/">
              <Logo />
            </Link>
          </Col>
          <Col span={8} offset={12}>
            <div
              style={{
                display: 'flex',
                lineHeight: 1.5,
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <div style={{ textAlign: 'right', marginRight: 10 }}>
                <UserShortData />
              </div>
              <Link to="/profile">
                <Avatar icon={<UserOutlined />} />
              </Link>
            </div>
          </Col>
        </Row>
      </Header>

      <Layout className="site-layout">
        <Sider
          width="280"
          theme="light"
          collapsible={true}
          collapsed={menuCollapsed}
          onCollapse={() => setColapsed((state) => !state)}
        >
          <Menu
            theme="light"
            mode="inline"
            defaultOpenKeys={defaultOpenKeys}
            items={permissionMenu}
            onOpenChange={(keys) => setOpenKeys(keys)}
          />
        </Sider>
        <Layout>
          <Content>
            <Outlet />
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            armax © 2022 | <AppVersion />{' '}
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
});
