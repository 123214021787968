import { Badge, Card, Space } from 'antd';
import { AppPage } from 'app/ui/app-page';
import { BaseTable } from 'app/ui/base-table';
import { FilterColumns } from 'app/ui/filter-columns/filter-columns';
import { Link } from 'react-router-dom';
import { Loading } from 'app/ui/loading';

export const AllTerminalsPage = () => (
  <AppPage header="Все терминалы" subheader="Просмотр подключенных терминалов">
    <Space direction="vertical" style={{ display: 'flex' }}>
      <FilterColumns
        columns={[
          { title: 'Заводской номер', dataIndex: 'hwid', filtrable: true },
          { title: 'Регистрационный номер', dataIndex: 'regid', filtrable: true },
        ]}
        onChange={console.log}
      />

      <Card size="small">
        <Loading isActive={false}>
          <BaseTable
            columns={[
              {
                title: 'ID',
                width: '1%',
                dataIndex: 'id',
                available: true,
                visible: true,
                sorter: { multiple: 1 },
              },
              {
                title: 'Заводской номер',
                dataIndex: 'hwid',
                available: true,
                visible: true,
              },
              {
                title: 'Регистрационный номер',
                dataIndex: 'regid',
                available: true,
                visible: true,
              },
              {
                title: 'Статус',
                dataIndex: 'hwid',
                available: true,
                visible: true,
                render: () => <Badge text="Активен" status="success" />,
              },
              {
                title: 'Подробнее',
                dataIndex: 'null',
                available: true,
                visible: true,
                render: (_, bank) => <Link to={`/terminal/${bank.id}`}>Подробнее</Link>,
              },
            ]}
            dataSource={[
              {
                id: 1,
                hwid: 45678943,
                regid: 9874354465798,
              },
              {
                id: 2,
                hwid: 45678943,
                regid: 9874354465798,
              },
            ]}
            persistentId="all-terminals-list"
          />
        </Loading>
      </Card>
    </Space>
  </AppPage>
);
