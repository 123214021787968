import { ObservableRequest } from 'app/tools/observable-request';

import { INotifications } from 'core/entities/notifications';
import { makeAutoObservable } from 'mobx';

import { IHttpClient } from 'app/libs/http-client/types';
import { RevokeLicenseRequestStore } from '../revoke-license-request.store';
import {
  ERevokeLicenseRequestStatus,
  TRevokeLicenseRequest,
} from 'core/entities/revoke-license-request';
import { updateRevokeLicenceDetails } from 'app/api/requests/revoke-license-requests';

// type СhangeableFields = Extract<keyof TRevokeLicenseRequest, 'inn' | 'licenceCount'>;

type UpdateRevokeLicenseRequestParams = {
  licenseRequestStore: RevokeLicenseRequestStore;
  apiClient: IHttpClient;
  notify: INotifications;
};

export class UpdateRevokeLicenseRequestService {
  private licenseRequest: ObservableRequest<
    TRevokeLicenseRequest['request'],
    TRevokeLicenseRequest
  >;

  public get isLoading() {
    return this.licenseRequest.isLoading;
  }

  private licenseRequestStore: RevokeLicenseRequestStore;
  private notify: INotifications;

  constructor({ licenseRequestStore, apiClient, notify }: UpdateRevokeLicenseRequestParams) {
    this.licenseRequestStore = licenseRequestStore;
    this.notify = notify;

    const request = (id: TRevokeLicenseRequest['request']) =>
      apiClient.fetch<TRevokeLicenseRequest>(updateRevokeLicenceDetails(id));

    this.licenseRequest = new ObservableRequest(request, {
      done: (licenseRequest) => licenseRequestStore.setData(licenseRequest),
      fail: (error) => notify.addMessage({ type: 'error', message: error.message }),
    });

    makeAutoObservable(this);
  }

  public saveRequestFromStore() {
    const updatedLicenseRequest: TRevokeLicenseRequest['request'] = {
      ...this.licenseRequestStore.data.request,
    };

    this.licenseRequest.fetch(updatedLicenseRequest);
  }

  public confirmLicenseRequest() {
    this.notify.clearAll();

    const confirmLicenseRequest: TRevokeLicenseRequest['request'] = {
      ...this.licenseRequestStore.data.request,
      status: ERevokeLicenseRequestStatus.confirmed,
    };

    this.licenseRequest.fetch(confirmLicenseRequest);
  }

  public rejectLicenseRequest() {
    const rejectedLicenseRequest: TRevokeLicenseRequest['request'] = {
      ...this.licenseRequestStore.data.request,
      status: ERevokeLicenseRequestStatus.rejected,
    };

    this.licenseRequest.fetch(rejectedLicenseRequest);
  }
}
