import { makeAutoObservable } from 'mobx';
import { saveAs } from 'file-saver';

import { IHttpClient } from 'app/libs/http-client/types';
import { ObservableRequest } from 'app/tools/observable-request';
import { INotifications } from 'core/entities/notifications';
import { Fail } from 'core/data/fail';

import { exportLicencesRequest } from 'app/api/requests/bank-licenses/export_licences';
import { TBankLicenseConstraints } from 'core/entities/bank-license';

type Filter = TBankLicenseConstraints['filter'];

type ExportLicensesServiceParams = {
  apiClient: IHttpClient;
  notify: INotifications;
};

export class ExportLicensesService {
  private exportLicenseRequest: ObservableRequest<TBankLicenseConstraints, Blob>;
  private loadingState: boolean = false;

  public get isLoading() {
    return this.loadingState || this.exportLicenseRequest.isLoading;
  }

  private sort: TBankLicenseConstraints['sorting'] = [];
  private filters: Filter = null;

  private notify: INotifications;

  constructor({ apiClient, notify }: ExportLicensesServiceParams) {
    this.notify = notify;

    const request = (constraints?: TBankLicenseConstraints) =>
      apiClient.fetch<Blob>(exportLicencesRequest(constraints));

    this.exportLicenseRequest = new ObservableRequest(request, {
      done: (file) => this.saveFile(file),
      fail: (error) => this.showError(error),
    });

    makeAutoObservable(this);
  }

  private showError(error: Fail): void {
    this.notify.addMessage({ type: 'error', message: error.message });
    this.changeLoadingState(false);
  }

  private changeLoadingState(state: boolean) {
    this.loadingState = state;
  }

  private saveFile(file: Blob) {
    const currentDate = new Date().toLocaleDateString();
    const currentTime = new Date().toLocaleTimeString();
    saveAs(file, `licences_${currentDate}_${currentTime}.xlsx`);
    this.changeLoadingState(false);
  }

  public exportToFile() {
    this.changeLoadingState(true);
    this.exportLicenseRequest.fetch({ sorting: this.sort, filter: this.filters });
  }

  public filterData(filters: Filter) {
    console.log(filters);
    this.filters = filters;

    this.exportToFile();
  }
}
