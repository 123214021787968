import { TPermission } from 'core/entities/role';
import { TUser } from 'core/entities/user';
import { makeAutoObservable } from 'mobx';
import React from 'react';
import { UPermissions } from './permissions';

const storeContext = React.createContext<UserStore | undefined>(undefined);

export const UserStoreProvider = storeContext.Provider;

export const useUserStore = () => {
  const store = React.useContext(storeContext);

  if (store === undefined) {
    throw new Error('useCurrentUserStore must be within CurrentUserStoreProvider');
  }

  return store;
};

export class UserStore {
  data: TUser = {
    id: -1,
    login: '',
    blocked: false,
    bank: { id: -1, name: '' },
    roles: [],
  };

  constructor(id?: TUser['id']) {
    this.data.id = id ?? -1;

    makeAutoObservable(this);
  }

  get hasBank() {
    return this.data.bank ? this.data.bank.id > -1 : false;
  }

  public get permList(): UPermissions[] {
    const perms: UPermissions[] = [];

    for (const role of this.data.roles) {
      role.permissions.forEach((perm) => {
        if (perms.includes(perm.name)) return;
        perms.push(perm.name);
      });
    }

    return perms;
  }

  public canAccess(perm: TPermission['name']) {
    return this.permList.includes(perm);
  }

  public setData(userData: TUser) {
    this.data = userData;
  }
}
