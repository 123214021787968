import _intersectionWith from 'lodash.intersectionwith';
import { TColumn } from 'core/entities/columns';
import { UPermissions } from 'app/features/users/permissions';

export const filterColumsByPerms = <T>(columns: Array<TColumn<T>>, perms: Array<UPermissions>) => {
  return columns.filter((column) => {
    const isNoPerms = !Array.isArray(column.perms) || column.perms.length === 0;
    if (isNoPerms) return true;

    const grantedPerms = _intersectionWith(
      perms,
      column.perms || [],
      (userPerm, colPerm) => userPerm === colPerm,
    );

    console.log({ grantedPerms });

    const hasAccess = grantedPerms.length > 0;

    return hasAccess;
  });
};
