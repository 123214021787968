import { Space, Card } from 'antd';
import { apiClient } from 'app/api';
import {
  allRevokeLicenseRequestsColumns,
  allRevokeLicenseRequestsFilters,
} from 'app/features/revoke-license-requests/all-revoke-license-requests/all-revoke-license-requests-columns';
import { Notifications, NotificationsStore } from 'app/features/notifications/notifications';
import { AppPage } from 'app/ui/app-page';
import { BaseTable } from 'app/ui/base-table';
import { FilterColumns } from 'app/ui/filter-columns/filter-columns';
import { TLicenseRequestsConstraints } from 'core/entities/license-request';
import { observer } from 'mobx-react-lite';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { Loading } from 'app/ui/loading';
import { AllRevokeLicenseRequestsService } from 'app/features/revoke-license-requests/all-revoke-license-requests/all-revoke-license-requests.service';
import { NotificationsProvider } from 'app/features/notifications/notifications/notification-provider';
import { filterColumsByPerms } from 'app/ui/filter-columns/filter-columns-by-perms';
import { useUserStore } from 'app/features/users/user.store';
import { TBaseConstraints } from 'app/ui/base-table/base-table';
import { ConstraintsStore } from 'app/features/constraints/constraints.store';

export const AllRevokeLicenseRequestsPage = observer(() => {
  const [notify] = useState(() => new NotificationsStore());

  const currentUser = useUserStore();
  const permList = currentUser.permList;

  const licenseRevokeRequestFilters = useMemo(
    () => filterColumsByPerms(allRevokeLicenseRequestsFilters, permList),
    [permList],
  );

  const [constraints] = useState(() => new ConstraintsStore<TLicenseRequestsConstraints>());

  const [allRevokeLicenseRequests] = useState(() => {
    const service = new AllRevokeLicenseRequestsService({
      apiClient,
      notify,
      constraints,
    });

    return service;
  });

  const onChangeFilter = useCallback(
    (filters) => allRevokeLicenseRequests.filterRecords(filters),
    [allRevokeLicenseRequests],
  );

  const onChangeConstraints = useCallback(
    (constraints: TBaseConstraints<TLicenseRequestsConstraints>) => {
      allRevokeLicenseRequests.changeConstraints(constraints);
    },
    [allRevokeLicenseRequests],
  );

  useEffect(() => {
    allRevokeLicenseRequests.fetchLicenseRequests();
  }, [allRevokeLicenseRequests]);

  return (
    <NotificationsProvider value={notify}>
      <AppPage header="Заявки на отзыв лицензии" subheader="Просмотр созданных заявок">
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Notifications messages={notify.messages} onClose={(msg) => notify.removeMessage(msg)} />

          <FilterColumns columns={licenseRevokeRequestFilters} onChange={onChangeFilter} />

          <Card size="small">
            <Loading isActive={allRevokeLicenseRequests.isLoading}>
              <BaseTable
                columns={allRevokeLicenseRequestsColumns}
                dataSource={allRevokeLicenseRequests.requests}
                paging={constraints.data.pageInfo}
                onChange={onChangeConstraints}
                persistentId="all-events-list"
              />
            </Loading>
          </Card>
        </Space>
      </AppPage>
    </NotificationsProvider>
  );
});
