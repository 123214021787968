import { DatePicker, Input } from 'antd';
import { TColumn } from 'core/entities/columns';
import { TEvent } from 'core/entities/event';
import { Link } from 'react-router-dom';

export const eventColumns: TColumn<TEvent>[] = [
  {
    key: 'id',
    title: 'ID',
    dataIndex: 'id',
    sorter: {
      multiple: 1,
    },
    width: '1%',
    available: true,
    visible: true,
  },

  {
    key: 'date',
    title: 'Дата',
    dataIndex: 'date',
    sorter: {
      multiple: 2,
    },
    available: true,
    visible: true,
    render: (_, { date }) => new Date(date).toLocaleString(),
  },
  {
    key: 'actionType',
    title: 'Тип события',
    dataIndex: 'actionType',
    sorter: {
      multiple: 3,
    },
    available: true,
    visible: true,
  },
  {
    key: 'description',
    title: 'Описание события',
    dataIndex: 'description',
    sorter: {
      multiple: 4,
    },
    available: true,
    visible: true,
  },
  {
    key: 'userId',
    title: 'ID пользователя',
    dataIndex: 'userId',
    sorter: {
      multiple: 5,
    },
    available: true,
    visible: true,
    render: (_, record) => <Link to={`/users/${record.userId}`}>{record.userLogin}</Link>,
  },
  {
    key: 'ip',
    title: 'IP',
    dataIndex: 'ip',
    sorter: {
      multiple: 7,
    },
    available: true,
    visible: true,
  },
];

export const eventsFilters: TColumn<TEvent>[] = [
  {
    title: 'Начальная дата',
    dataIndex: 'lowDate',
    available: true,
    visible: true,
    filtrable: true,
    filterRender: (props) => <DatePicker showTime {...props} />,
  },
  {
    title: 'Конечная дата',
    dataIndex: 'highDate',
    available: true,
    visible: true,
    filtrable: true,
    filterRender: (props) => <DatePicker showTime {...props} />,
  },
  {
    title: 'Тип события',
    dataIndex: 'actionType',
    available: true,
    visible: true,
    filtrable: true,
    filterRender: (props) => <Input {...props} />,
  },
];
