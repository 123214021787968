import { RequestConfig } from 'app/api/types';
import { HttpRequest } from './types';

export const createRequest = (req: RequestConfig): HttpRequest => {
  const computedUrl = new URL(req.url, window.location.toString());
  const headers = req.headers ?? new Headers({ 'content-type': 'application/json' });

  if (req.query) {
    computedUrl.search = new URLSearchParams(req.query).toString();
  }

  return new Request(computedUrl.toString(), { headers, ...req });
};
