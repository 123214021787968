import { addUserRequest } from 'app/api/requests/users';
import { IHttpClient } from 'app/libs/http-client/types';
import { usersRoute } from 'app/pages/routes';
import { ObservableRequest } from 'app/tools/observable-request';
import { INotifications } from 'core/entities/notifications';
import { TNewUser, TUser } from 'core/entities/user';
import { generatePath, NavigateFunction } from 'react-router-dom';

type AddUserServiceParams = {
  apiClient: IHttpClient;
  navigate: NavigateFunction;
  notify: INotifications;
};

export class AddUserService {
  private addUserRequest: ObservableRequest<TNewUser, TUser>;

  private navigate: NavigateFunction;
  private notify: INotifications;

  public get isLoading() {
    return this.addUserRequest.isLoading;
  }

  constructor({ apiClient, notify, navigate }: AddUserServiceParams) {
    this.navigate = navigate;
    this.notify = notify;

    const request = (user: TNewUser) => apiClient.fetch<TUser>(addUserRequest(user));

    this.addUserRequest = new ObservableRequest(request, {
      done: (user) => this.userCreated(user),
      fail: (error) => this.showError(error.message),
    });
  }

  public createUser(newUser: TNewUser) {
    this.addUserRequest.fetch(newUser);
  }

  private userCreated(user: TUser) {
    const path = generatePath(usersRoute.path, { userId: String(user.id) });

    this.navigate(path);
  }

  private showError(message: string) {
    this.notify.addMessage({ type: 'error', message });
  }
}
