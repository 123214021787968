import { ObservableRequest } from 'app/tools/observable-request';

import { INotifications } from 'core/entities/notifications';
import { makeAutoObservable } from 'mobx';

import { IHttpClient } from 'app/libs/http-client/types';
import { TBank } from 'core/entities/bank';
import { getBankRequest } from 'app/api/requests/banks';
import { BankStore } from '../bank.store';

type DetailsBankServiceParams = {
  bankStore: BankStore;
  apiClient: IHttpClient;
  notify: INotifications;
};

export class DetailsBankService {
  private bankRequest: ObservableRequest<TBank['id'], TBank>;

  public get isLoading() {
    return this.bankRequest.isLoading;
  }
  public get hasError() {
    return this.bankRequest.hasError;
  }

  constructor({ bankStore, apiClient, notify }: DetailsBankServiceParams) {
    const request = (id: TBank['id']) => apiClient.fetch<TBank>(getBankRequest(id));

    this.bankRequest = new ObservableRequest(request, {
      done: (bank) => bankStore.setData(bank),
      fail: (error) => notify.addMessage({ type: 'error', message: error.message }),
    });

    makeAutoObservable(this);
  }

  fetchBank(id: TBank['id']) {
    this.bankRequest.fetch(id);
  }
}
