import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {
  Space,
  Card,
  Descriptions,
  Button,
  Modal,
  Alert,
  Row,
  Table,
  Input,
  Form,
  Col,
  Empty,
  Divider,
} from 'antd';
import { apiClient } from 'app/api';

import { NotificationsStore, Notifications } from 'app/features/notifications/notifications';
import { permissions } from 'app/features/users/permissions';
import { useUserStore } from 'app/features/users/user.store';
import { AppPage } from 'app/ui/app-page';
import { Observer, observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Loading } from 'app/ui/loading';
import { bankDetailsRoute } from '../routes';
import { InnMaskedInput } from 'app/ui/masked-input/inn-masked-input';
import { DsnMaskedInput } from 'app/ui/masked-input';
import { DetailsChangeLicenseRequestService } from 'app/features/change-license-requests/details-change-license-request/details-change-license-request.service';
import { ChangeLicenseRequestStore } from 'app/features/change-license-requests/change-license-request.store';
import { UpdateChangeLicenseRequestService } from 'app/features/change-license-requests/update-change-license-request/update-revoke-license-request.service';
import { EChangeLicenseRequestStatus } from 'core/entities/change-license-request';
import { ChangeLicenseRequestStatus } from 'app/features/change-license-requests/ui/change-license-request-status';

export const DetailsChangeLicenseRequestsPage = observer(() => {
  const { requestId } = useParams<{ requestId: string }>();
  const [notify] = useState(() => new NotificationsStore());

  const [licenseRequestStore] = useState(() => new ChangeLicenseRequestStore(Number(requestId)));

  const [changeLicenseRequestService] = useState(
    () => new DetailsChangeLicenseRequestService({ apiClient, licenseRequestStore, notify }),
  );
  const [updateLicenseRequestService] = useState(
    () => new UpdateChangeLicenseRequestService({ apiClient, licenseRequestStore, notify }),
  );

  const [licenseEditForm] = Form.useForm();

  useEffect(() => {
    changeLicenseRequestService.fetchLicenseRequest(Number(requestId));
  }, [changeLicenseRequestService, requestId]);

  useEffect(() => {
    if (changeLicenseRequestService.isDone) {
      licenseEditForm.setFieldValue('editLicenses', licenseRequestStore.data.licences);
    }
  }, [changeLicenseRequestService, licenseEditForm, licenseRequestStore.data.licences, requestId]);

  const saveLicenseRequest = () => {
    licenseEditForm
      .validateFields(['editLicenses'])
      .then((data) => updateLicenseRequestService.saveRequestFromLicensesList(data.editLicenses))
      .catch(console.log);
  };

  const userStore = useUserStore();

  const licenseRequest = licenseRequestStore.data;

  const isLoading = changeLicenseRequestService.isLoading || updateLicenseRequestService.isLoading;

  const isLicenceRequestEditable =
    licenseRequestStore.data.status === EChangeLicenseRequestStatus.created;
  const hasRequestApplyPerm =
    licenseRequestStore.data.status === EChangeLicenseRequestStatus.created &&
    userStore.canAccess(permissions.PERM_CHANGE_LICENCE_REQUEST_APPLY);

  const isStatusDescriptionEditable =
    licenseRequestStore.status === EChangeLicenseRequestStatus.created && hasRequestApplyPerm;
  const isStatusDescriptionVisible =
    licenseRequestStore.statusDescription &&
    licenseRequestStore.status !== EChangeLicenseRequestStatus.created;

  const confirmLicenseRequest = () =>
    Modal.confirm({
      title: 'Подтвердить заявку на удаление лицензий?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          В заявке на удаление лицензии для банка <b>{licenseRequest.bank.name}</b>.
          <p>
            Заявка станет недоступна для изменения, а ее статус будет изменен на "Подтверждена"
          </p>
          {isStatusDescriptionEditable && (
            <>
              <span>Комментарий к статусу заявки:</span>
              <Observer>
                {() => (
                  <Input.TextArea
                    showCount
                    maxLength={500}
                    value={licenseRequestStore.statusDescription}
                    onChange={(event) =>
                      licenseRequestStore.setStatusDescription(event.target.value)
                    }
                  />
                )}
              </Observer>
            </>
          )}
        </div>
      ),
      onOk() {
        const editedLicenses = licenseEditForm.getFieldValue('editLicenses');

        licenseRequestStore.updateLicenses(editedLicenses);
        updateLicenseRequestService.confirmLicenseRequest();
      },
      okText: 'Подтвердить',
    });

  const rejectLicenseRequest = () =>
    Modal.confirm({
      title: 'Отклонить заявку?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          В заявке на удаление лицензии для банка <b>{licenseRequest.bank.name}</b>.
          <p>
            После этого действия, заявка станет недоступна для изменения, а ее статус будет изменен
            на "Отклонена"
          </p>
          {isStatusDescriptionEditable && (
            <>
              <span>Комментарий к статусу заявки:</span>
              <Observer>
                {() => (
                  <Input.TextArea
                    showCount
                    maxLength={500}
                    value={licenseRequestStore.statusDescription}
                    onChange={(event) =>
                      licenseRequestStore.setStatusDescription(event.target.value)
                    }
                  />
                )}
              </Observer>
            </>
          )}
        </div>
      ),
      onOk() {
        const editedLicenses = licenseEditForm.getFieldValue('editLicenses');

        licenseRequestStore.updateLicenses(editedLicenses);
        updateLicenseRequestService.rejectLicenseRequest();
      },
      okText: 'Отклонить',
    });

  if (!Number.isInteger(Number(requestId))) {
    return (
      <AppPage
        header="Просмотр заявки на изменение лицензий"
        subheader="Отображение данных заявки"
      >
        <Alert
          type="error"
          message={
            <span>
              Некорректный ID заявки: <b>{requestId}</b>
            </span>
          }
          description="Проверьте ID заявки в строке браузера"
          showIcon
        />
      </AppPage>
    );
  }

  if (!changeLicenseRequestService.isLoading && changeLicenseRequestService.hasError) {
    return (
      <AppPage
        header="Просмотр заявки на изменение лицензий"
        subheader="Отображение данных заявки"
      >
        <Notifications messages={notify.messages} onClose={(m) => notify.removeMessage(m)} />
      </AppPage>
    );
  }

  return (
    <AppPage header="Просмотр заявки на изменение лицензий" subheader="Отображение данных заявки">
      <Space direction="vertical" style={{ display: 'flex' }}>
        {isLoading ? (
          <Card size="small">
            <Loading isActive />
          </Card>
        ) : (
          <>
            <Card size="small">
              <Descriptions bordered size="small" column={1}>
                <Descriptions.Item
                  label="ID заявки"
                  labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                >
                  {licenseRequest.id}
                </Descriptions.Item>

                <Descriptions.Item
                  label="Статус"
                  labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                >
                  <ChangeLicenseRequestStatus status={licenseRequest.status} />
                </Descriptions.Item>

                <Descriptions.Item label="Банк" labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}>
                  <Link
                    to={generatePath(bankDetailsRoute.path, {
                      bankId: String(licenseRequest.bank.id),
                    })}
                  >
                    {licenseRequest.bank.name}
                  </Link>
                </Descriptions.Item>

                <Descriptions.Item
                  label="Дата создания заявки"
                  labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
                >
                  {new Date(licenseRequest.date).toLocaleString()}
                </Descriptions.Item>
              </Descriptions>
            </Card>

            {isStatusDescriptionVisible && (
              <Card size="small" title="Комментарий к статусу заявки">
                {licenseRequestStore.statusDescription}
              </Card>
            )}

            <Card size="small" title="Лицензии для изменений">
              <Form initialValues={{ editLicenses: [] }} form={licenseEditForm}>
                <Form.List
                  name="editLicenses"
                  rules={[
                    {
                      validator(_, data = []) {
                        if (data.length === 0) {
                          return Promise.reject('В заявку не добавлены лицензии для изменения');
                        }

                        const result = data.some(({ newDsn, newInn }: any) =>
                          Boolean(newDsn || newInn),
                        );

                        return result
                          ? Promise.resolve(data)
                          : Promise.reject('В добавленных лицензиях не заполнены поля');
                      },
                    },
                  ]}
                >
                  {(records: any, { remove }, { errors }) => (
                    <>
                      <Table
                        dataSource={records}
                        size="small"
                        pagination={{
                          hideOnSinglePage: false,
                          showSizeChanger: true,
                          defaultPageSize: 10,
                        }}
                        locale={{
                          emptyText: (
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              description="В заявке отсутсвуют лицензии на отзыв"
                            />
                          ),
                        }}
                        footer={() => (
                          <Row justify="end">
                            Количество лицензий:
                            <strong style={{ paddingLeft: 4 }}>
                              {licenseRequestStore.data.licences.length} шт.
                            </strong>
                          </Row>
                        )}
                        onRow={(_, idx = 1) =>
                          idx % 2 ? { style: { background: '#FBFCFE' } } : {}
                        }
                        bordered
                      >
                        <Table.Column
                          title="ID"
                          width="5%"
                          dataIndex="id"
                          align="center"
                          render={(_, record: any) =>
                            licenseEditForm.getFieldValue([
                              'editLicenses',
                              record.name,
                              'licenceId',
                            ])
                          }
                        />
                        <Table.Column
                          title="Поля"
                          width="10%"
                          onCell={() => ({ style: { padding: '0.5em 0' } })}
                          render={() => (
                            <Row align="middle" style={{ flexDirection: 'column' }}>
                              <Col span={24} style={{ padding: '0 0.5em' }}>
                                DSN
                              </Col>
                              <Divider style={{ margin: '0.92em 0' }} />
                              <Col span={24} style={{ padding: '0 0.5em' }}>
                                ИНН
                              </Col>
                            </Row>
                          )}
                        />
                        <Table.Column
                          title="Текущее значение"
                          width="25%"
                          onCell={() => ({ style: { padding: '8px 0' } })}
                          render={(_, record: any) => {
                            const dsnFieldValue = licenseEditForm.getFieldValue([
                              'editLicenses',
                              record.name,
                              'dsn',
                            ]);

                            const innFieldValue = licenseEditForm.getFieldValue([
                              'editLicenses',
                              record.name,
                              'inn',
                            ]);

                            return (
                              <>
                                <div style={{ textAlign: 'center' }}>{dsnFieldValue ?? '-'}</div>
                                <Divider style={{ margin: '0.92em 0' }} />
                                <div style={{ textAlign: 'center' }}>{innFieldValue ?? '-'}</div>
                              </>
                            );
                          }}
                        />
                        <Table.Column
                          title="Новое значение"
                          width="25%"
                          onCell={() => ({ style: { padding: '8px 0' } })}
                          render={(_, record: any) => (
                            <>
                              <Row align="middle">
                                <Col span={24}>
                                  {isLicenceRequestEditable ? (
                                    <Form.Item
                                      name={[record.name, 'newDsn']}
                                      style={{ margin: '0 8px' }}
                                      validateFirst
                                      rules={[
                                        {
                                          len: 11,
                                          message: 'DSN должен быть из 11 цифр',
                                          validateTrigger: 'onSubmit',
                                        },
                                        ({ getFieldValue }) => ({
                                          validator({ field }: any, value) {
                                            if (!value) return Promise.resolve(value);

                                            const formFields = getFieldValue('editLicenses');
                                            const { id: licId } = getFieldValue([
                                              'editLicenses',
                                              record.name,
                                            ]);

                                            const formFieldsValues = formFields.filter(
                                              ({ id }: any) => id !== licId,
                                            );

                                            if (formFieldsValues.length === 0) {
                                              return Promise.resolve(value);
                                            }

                                            const result = formFieldsValues.find(
                                              ({ newDsn }: any) => newDsn === value,
                                            )
                                              ? Promise.reject('Такой DSN уже используется')
                                              : Promise.resolve(value);

                                            return result;
                                          },
                                          validateTrigger: 'onSubmit',
                                        }),
                                      ]}
                                    >
                                      <DsnMaskedInput
                                        size="small"
                                        placeholder="DSN"
                                        disabled={!isLicenceRequestEditable}
                                      />
                                    </Form.Item>
                                  ) : (
                                    <div style={{ margin: '0 8px', textAlign: 'center' }}>
                                      {licenseEditForm.getFieldValue([
                                        'editLicenses',
                                        record.name,
                                        'newDsn',
                                      ])}
                                    </div>
                                  )}
                                </Col>
                              </Row>
                              <Row align="middle">
                                <Divider style={{ margin: '8px 0' }} />
                              </Row>
                              <Row align="middle">
                                <Col span={24}>
                                  {isLicenceRequestEditable ? (
                                    <Form.Item
                                      name={[record.name, 'newInn']}
                                      style={{ margin: '0 8px' }}
                                      validateFirst
                                      rules={[
                                        {
                                          len: 8,
                                          message: 'ИНН должен быть из 8 цифр',
                                          validateTrigger: 'onSubmit',
                                        },
                                        ({ getFieldValue }) => ({
                                          validator(_: any, value) {
                                            if (!value) return Promise.resolve(value);

                                            const formFields = getFieldValue('editLicenses');
                                            const { id: licId } = getFieldValue([
                                              'editLicenses',
                                              record.name,
                                            ]);

                                            const formFieldsValues = formFields.filter(
                                              ({ id }: any) => id !== licId,
                                            );

                                            if (formFieldsValues.length === 0) {
                                              return Promise.resolve(value);
                                            }

                                            const result = formFieldsValues.find(
                                              ({ newInn }: any) => newInn === value,
                                            )
                                              ? Promise.reject('Такой ИНН уже используется')
                                              : Promise.resolve(value);

                                            return result;
                                          },
                                          validateTrigger: 'onSubmit',
                                        }),
                                      ]}
                                    >
                                      <InnMaskedInput placeholder="ИНН" size="small" />
                                    </Form.Item>
                                  ) : (
                                    <div style={{ margin: '0 8px', textAlign: 'center' }}>
                                      {licenseEditForm.getFieldValue([
                                        'editLicenses',
                                        record.name,
                                        'newInn',
                                      ])}
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </>
                          )}
                        />
                        <Table.Column
                          title="Действие"
                          render={(_, record: any) =>
                            isLicenceRequestEditable ? (
                              <Button
                                size="small"
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                  remove(record.name);
                                }}
                              >
                                Убрать из заявки
                              </Button>
                            ) : null
                          }
                        />
                      </Table>
                      <div style={{ textAlign: 'right' }}>
                        <Form.ErrorList errors={errors} />
                      </div>
                    </>
                  )}
                </Form.List>
              </Form>
            </Card>

            <Notifications messages={notify.messages} onClose={(m) => notify.removeMessage(m)} />

            {isLicenceRequestEditable && (
              <Card size="small">
                <Row justify={hasRequestApplyPerm ? 'space-between' : 'end'}>
                  <Button
                    type={hasRequestApplyPerm ? 'default' : 'primary'}
                    icon={<SaveOutlined />}
                    onClick={() => saveLicenseRequest()}
                  >
                    Сохранить
                  </Button>
                  {hasRequestApplyPerm && (
                    <Space>
                      <Button
                        type="primary"
                        icon={<CheckCircleOutlined />}
                        onClick={confirmLicenseRequest}
                      >
                        Подтвердить
                      </Button>

                      <Button
                        type="default"
                        danger={true}
                        icon={<CloseCircleOutlined />}
                        onClick={rejectLicenseRequest}
                      >
                        Отклонить
                      </Button>
                    </Space>
                  )}
                </Row>
              </Card>
            )}
          </>
        )}
      </Space>
    </AppPage>
  );
});
