import { ObservableRequest } from 'app/tools/observable-request';

import { INotifications } from 'core/entities/notifications';
import { makeAutoObservable } from 'mobx';

import { IHttpClient } from 'app/libs/http-client/types';
import {
  TChangeLicenseRequest,
  TChangeLicenseRequestsConstraints,
} from 'core/entities/change-license-request';
import { getChangeLicenceRequestsRequest } from 'app/api/requests/change-license-requests';
import { ConstraintsStore } from 'app/features/constraints/constraints.store';
import { TPageable } from 'core/entities/pageable';

type ChangableConstraints = {
  pagination: { current: number; pageSize: number };
  sort: TChangeLicenseRequestsConstraints['sorting'];
};

type AllChangeLicenseRequestsServiceParams = {
  apiClient: IHttpClient;
  notify: INotifications;
  constraints: ConstraintsStore<TChangeLicenseRequestsConstraints>;
};

export class AllChangeLicenseRequestsService {
  public requests: TChangeLicenseRequest[] = [];

  private allChangeLicenseRequest: ObservableRequest<
    TChangeLicenseRequestsConstraints,
    TPageable<TChangeLicenseRequest[]>
  >;

  public get isLoading() {
    return this.allChangeLicenseRequest.isLoading;
  }

  private constraints: ConstraintsStore<TChangeLicenseRequestsConstraints>;

  constructor({ apiClient, notify, constraints }: AllChangeLicenseRequestsServiceParams) {
    this.constraints = constraints;

    const request = (constraints?: TChangeLicenseRequestsConstraints) =>
      apiClient.fetch<TPageable<TChangeLicenseRequest[]>>(
        getChangeLicenceRequestsRequest(constraints),
      );

    this.allChangeLicenseRequest = new ObservableRequest(request, {
      done: (licenseRequests) => this.setLicenseRequests(licenseRequests),
      fail: (error) => notify.addMessage({ type: 'error', message: error.message }),
    });

    makeAutoObservable(this);
  }

  private setLicenseRequests({ content, ...pagination }: TPageable<TChangeLicenseRequest[]>) {
    this.requests = content;
    this.constraints.updatePageInfo(pagination);
  }

  fetchLicenseRequests() {
    const constraints = this.constraints.data;

    this.allChangeLicenseRequest.fetch(constraints);
  }

  public filterRecords(filters: TChangeLicenseRequestsConstraints['filter']) {
    this.constraints.setFilters(filters);

    this.fetchLicenseRequests();
  }

  public changeConstraints({ pagination, sort }: ChangableConstraints) {
    this.constraints.updatePageInfo({
      page: pagination.current,
      size: pagination.pageSize,
    });

    this.constraints.setSort(sort);

    this.fetchLicenseRequests();
  }
}
