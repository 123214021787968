import { TBankLicense } from 'core/entities/bank-license';
import { ICollectionStore } from 'core/entities/collection-store';
import { TRevokeBankLicense } from 'core/entities/revoke-license-request';
import { makeAutoObservable } from 'mobx';

export class LicensesToRevokeStore implements ICollectionStore<TRevokeBankLicense> {
  items: TRevokeBankLicense[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public fillData(items: TRevokeBankLicense[]): void {
    this.items = items;
  }
  public removeItem({ id }: { id: TBankLicense['id'] }) {
    this.items = this.items.filter((item) => item.licenceId !== id);
  }

  public clearItems() {
    this.items = [];
  }

  public addItem(item: TBankLicense) {
    this.items.push({ ...item, licenceId: item.id });
  }

  public hasItem({ id }: { id: TBankLicense['id'] }) {
    return this.items.find((license) => license.licenceId === id);
  }
}
