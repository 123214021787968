import { Space, Form, Card, Button } from 'antd';
import { apiClient } from 'app/api';
import { Notifications, NotificationsStore } from 'app/features/notifications/notifications';
import { AddUserService } from 'app/features/users/add-user/add-user.service';
import { RolesFields } from 'app/features/users/add-user/roles-field';
import { UserFields } from 'app/features/users/add-user/user-fields';
import { AppPage } from 'app/ui/app-page';
import { TNewUser } from 'core/entities/user';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loading } from 'app/ui/loading';
import { NotificationsProvider } from 'app/features/notifications/notifications/notification-provider';
import { SelectAvailableBank } from 'app/features/banks/all-banks/select-available-banks';

export const AddUserPage = () => {
  const navigate = useNavigate();
  const [notify] = useState(() => new NotificationsStore());

  const [addUser] = useState(
    () =>
      new AddUserService({
        apiClient,
        navigate,
        notify,
      }),
  );

  return (
    <NotificationsProvider value={notify}>
      <AppPage
        header="Добавление пользователя"
        subheader="Создание учетной записи панели администрирования"
      >
        <Form
          layout="vertical"
          onFinish={(newUser: TNewUser) => addUser.createUser(newUser)}
          scrollToFirstError={{ behavior: 'smooth' }}
        >
          <Loading isActive={addUser.isLoading}>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
              <Card size="small">
                <div>
                  <h2>1. Данные входа</h2>
                  <div style={{ maxWidth: '50%' }}>
                    <UserFields />
                  </div>
                </div>
              </Card>

              <Card size="small">
                <div>
                  <h2>2. Данные банка</h2>
                  <p>Если пользователь принадлежит банку, выберите нужный банк</p>
                  <div style={{ maxWidth: '50%' }}>
                    <Form.Item name="bank" label="Банк">
                      <SelectAvailableBank />
                    </Form.Item>
                  </div>
                </div>
              </Card>

              <Card size="small">
                <div>
                  <h2>3. Роли и разрешения</h2>
                  <p>Выберите необходимое роли, которые будут назначены пользователю</p>

                  <RolesFields />
                </div>
              </Card>

              <Notifications
                messages={notify.messages}
                onClose={(msg) => notify.removeMessage(msg)}
              />

              <Card size="small">
                <div style={{ display: 'flex' }}>
                  <Button type="primary" htmlType="submit" style={{ marginLeft: 'auto' }}>
                    Добавить пользователя
                  </Button>
                </div>
              </Card>
            </Space>
          </Loading>
        </Form>
      </AppPage>
    </NotificationsProvider>
  );
};
