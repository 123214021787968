import { TRole } from 'core/entities/role';
import { makeAutoObservable } from 'mobx';

export class RolesStore {
  roles: TRole[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setData(roles: TRole[]) {
    this.roles = roles;
  }
}
