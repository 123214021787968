import { useEffect, ReactNode, useState } from 'react';
import { match } from 'ts-pattern';
import { observer } from 'mobx-react-lite';

import { apiClient } from 'app/api';
import { SignInPage } from 'app/pages/sign-in/sign-in.page';
import { UserStore, UserStoreProvider } from 'app/features/users/user.store';
import { UserGuardLoading } from './ui/user-guard-loading';
import { AuthVerifyService } from '../auth-verify/auth-verify';
import { UserGuardError } from './ui/user-guard-error';
import { AuthAlert } from '../auth-alert/auth-alert';
import { UnauthorizedFail } from 'core/data/unauthorized-fail';

export const UserGuard = observer(({ children }: { children: ReactNode }) => {
  const [userStore] = useState(() => new UserStore());

  const [authVerifyService] = useState(() => {
    const service = new AuthVerifyService({
      currentUserStore: userStore,
      apiClient,
    });

    return service;
  });

  useEffect(() => {
    authVerifyService.checkCurrentUser();
  }, [authVerifyService]);

  const renderResult = match(authVerifyService.userRequest.status)
    .with('idle', () => <UserGuardLoading />)
    .with('pending', () => <UserGuardLoading />)
    .with('done', () => (
      <UserStoreProvider value={userStore}>
        <AuthAlert>{children}</AuthAlert>
      </UserStoreProvider>
    ))
    .with('fail', () => {
      const isAuthRequired = authVerifyService.error instanceof UnauthorizedFail;

      return isAuthRequired ? (
        <SignInPage />
      ) : (
        <UserGuardError error={authVerifyService.errorMsg} />
      );
    })
    .with('abort', () => null)
    .exhaustive();

  return renderResult;
});
