import { ObservableRequest } from 'app/tools/observable-request';

import { INotifications } from 'core/entities/notifications';
import { makeAutoObservable } from 'mobx';

import { IHttpClient } from 'app/libs/http-client/types';

import { LicenseRequestStore } from '../license-request.store';
import {
  ELicenseRequestStatus,
  TLicenseRequest,
  TLicenseRequestDetails,
} from 'core/entities/license-request';
import { updateLicenceRequestDetailsRequest } from 'app/api/requests/license-requests';

type СhangeableFields = Extract<keyof TLicenseRequest, 'inn' | 'licenceCount'>;

type UpdateLicenseRequestParams = {
  licenseRequestStore: LicenseRequestStore;
  apiClient: IHttpClient;
  notify: INotifications;
};

export class UpdateLicenseRequestService {
  private licenseRequest: ObservableRequest<TLicenseRequest, TLicenseRequestDetails>;

  public get isLoading() {
    return this.licenseRequest.isLoading;
  }

  private licenseRequestStore: LicenseRequestStore;
  private notify: INotifications;

  constructor({ licenseRequestStore, apiClient, notify }: UpdateLicenseRequestParams) {
    this.licenseRequestStore = licenseRequestStore;
    this.notify = notify;

    const request = (id: TLicenseRequest) =>
      apiClient.fetch<TLicenseRequestDetails>(updateLicenceRequestDetailsRequest(id));

    this.licenseRequest = new ObservableRequest(request, {
      done: (licenseRequest) => licenseRequestStore.setData(licenseRequest),
      fail: (error) => notify.addMessage({ type: 'error', message: error.message }),
    });

    makeAutoObservable(this);
  }

  public changeField<F extends СhangeableFields, V extends TLicenseRequest[F]>(name: F, value: V) {
    const patchedLicenseRequest: TLicenseRequest = {
      ...this.licenseRequestStore.data,
      [name]: value,
    };

    this.licenseRequest.fetch(patchedLicenseRequest);
  }

  public saveRequestFromStore() {
    const updatedLicenseRequest: TLicenseRequest = {
      ...this.licenseRequestStore.data,
      licenceCount: this.licenseRequestStore.data.licences.length,
    };

    this.licenseRequest.fetch(updatedLicenseRequest);
  }

  public confirmLicenseRequest() {
    this.notify.clearAll();
    const isValid = this.validateDnsIpPairs(this.licenseRequestStore.data.licences);

    if (!isValid) {
      this.notify.addMessage({
        type: 'error',
        message: 'Количество DSN не совпадает с количеством IP адресов',
      });
      return;
    }
    const confirmLicenseRequest: TLicenseRequest = {
      ...this.licenseRequestStore.data,
      status: ELicenseRequestStatus.confirmed,
    };

    this.licenseRequest.fetch(confirmLicenseRequest);
  }

  public rejectLicenseRequest() {
    const rejectedLicenseRequest: TLicenseRequest = {
      ...this.licenseRequestStore.data,
      status: ELicenseRequestStatus.rejected,
    };

    this.licenseRequest.fetch(rejectedLicenseRequest);
  }

  private validateDnsIpPairs(licences: TLicenseRequest['licences']) {
    const result = licences.every(
      (item) => item.dsn && item.dsn.length > 0 && item.simIp && item?.simIp.length > 0,
    );

    return result;
  }
}
