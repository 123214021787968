import { ConfigProvider } from 'antd';
import ruRu from 'antd/lib/locale/ru_RU';
import 'antd/dist/antd.css';

import { BrowserRouter, useRoutes } from 'react-router-dom';

import { UserGuard } from 'app/features/auth/user-guard';
import { routes } from 'app/pages/routes';

const Routes = () => {
  const routesRender = useRoutes(routes);

  return routesRender;
};

export default function App() {
  return (
    <UserGuard>
      <ConfigProvider locale={ruRu}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ConfigProvider>
    </UserGuard>
  );
}
