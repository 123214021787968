import { TAppRouteObject } from 'core/entities/app-route';
import { generatePath, RouteObject } from 'react-router-dom';

export function createRoute<Params extends string>({
  path,
  element,
  ...params
}: {
  path: string;
  element: React.ReactNode;
} & RouteObject): TAppRouteObject<Params> {
  return {
    path,
    element,
    createPath: (params?: { [P in Params]: string }): string =>
      params ? generatePath(path, params) : path,
    ...params,
  };
}
