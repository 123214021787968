import { TBank } from './bank';
import { TBankLicense } from './bank-license';
import { TConstraints } from './constraints';

export enum ERevokeLicenseRequestStatus {
  created,
  confirmed,
  rejected,
}

export type TRevokeBankLicense = Pick<TBankLicense, 'dsn' | 'terminal'> & {
  licenceId: TBankLicense['id'];
  revoked?: boolean;
};

export type TRevokeLicenseRequest = {
  request: {
    id: number;
    bank: TBank;
    date: string;
    status: ERevokeLicenseRequestStatus;
    statusDescription: string;
    licences: Array<TRevokeBankLicense>;
  };
  licences: Array<TBankLicense>;
};

export type TRevokeLicenseRequestNew = Pick<TRevokeLicenseRequest['request'], 'licences'>;

export type TRevokeLicenseRequestsConstraints = TConstraints<
  Pick<TRevokeLicenseRequest['request'], 'id'>,
  { id: number; personId: number; showDeleted: boolean }
>;
