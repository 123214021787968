import { ObservableRequest } from 'app/tools/observable-request';

import { INotifications } from 'core/entities/notifications';
import { makeAutoObservable } from 'mobx';

import { IHttpClient } from 'app/libs/http-client/types';
import { LicenseRequestStore } from '../license-request.store';
import { TLicenseRequest, TLicenseRequestDetails } from 'core/entities/license-request';
import { getLicenceRequestDetailsRequest } from 'app/api/requests/license-requests';

type DetailsLicenseRequestParams = {
  licenseRequestStore: LicenseRequestStore;
  apiClient: IHttpClient;
  notify: INotifications;
};

export class DetailsLicenseRequestService {
  private licenseRequest: ObservableRequest<TLicenseRequest['id'], TLicenseRequestDetails>;

  public get isLoading() {
    return this.licenseRequest.isLoading;
  }
  public get hasError() {
    return this.licenseRequest.hasError;
  }

  constructor({ licenseRequestStore, apiClient, notify }: DetailsLicenseRequestParams) {
    const request = (id: TLicenseRequest['id']) =>
      apiClient.fetch<TLicenseRequestDetails>(getLicenceRequestDetailsRequest(id));

    this.licenseRequest = new ObservableRequest(request, {
      done: (licenseRequest) => licenseRequestStore.setData(licenseRequest),
      fail: (error) => notify.addMessage({ type: 'error', message: error.message }),
    });

    makeAutoObservable(this);
  }

  fetchLicenseRequest(id: TLicenseRequest['id']) {
    this.licenseRequest.fetch(id);
  }
}
