import React, { ReactNode } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './styles.module.css';

type LoadingProps = {
  isActive: boolean;
  children?: ReactNode | Array<ReactNode>;
};

const Icon = <LoadingOutlined className={styles.loadingOutlined} spin />;

export const Loading = ({ isActive, children }: LoadingProps) => {
  return children ? (
    <Spin delay={0} indicator={Icon} spinning={isActive}>
      {children}
    </Spin>
  ) : (
    <Spin delay={0} indicator={Icon} spinning={isActive} className={styles.loadingSpin} />
  );
};
