import { Button, Form, Input, Row, Switch } from 'antd';

import { useEffect } from 'react';

type FormFields = { login: string; blocked: boolean };

type UserDataProps = {
  login: string;
  loading: boolean;
  blocked: boolean;
  onSubmit: (credentials: FormFields) => void;
};

export const UserDataForm = ({ login, blocked, loading, onSubmit }: UserDataProps) => {
  const [form] = Form.useForm<FormFields>();

  useEffect(() => {
    form.setFieldsValue({ login, blocked });
  }, [form, login, blocked]);

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item name="login" label="Логин">
        <Input disabled />
      </Form.Item>

      <Form.Item name="password" label="Пароль">
        <Input.Password />
      </Form.Item>

      <Form.Item name="blocked" label="Блокировка пользователя" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Row justify="end">
        <Button htmlType="submit" type="primary" loading={loading}>
          Сохранить
        </Button>
      </Row>
    </Form>
  );
};
