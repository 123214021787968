import { Card, Button, Row, Col, Form } from 'antd';
import { useState } from 'react';
import { apiClient } from 'app/api';
import { Notifications, NotificationsStore } from 'app/features/notifications/notifications';
import { AppPage } from 'app/ui/app-page';
import { observer } from 'mobx-react-lite';
import { exportLicenseRequestsFilters } from 'app/features/license-requests/export-license-requests/export-license-requests-columns';
import { ExportLicenseRequestsService } from 'app/features/license-requests/export-license-requests/export-license-requests.service';
import { ELicenseRequestStatus } from 'core/entities/license-request';
import { NotificationsProvider } from 'app/features/notifications/notifications/notification-provider';

export const ExportLicenseRequestPage = observer(() => {
  const [notify] = useState(() => new NotificationsStore());

  const [exportLicenseRequests] = useState(() => {
    const service = new ExportLicenseRequestsService({
      apiClient,
      notify,
    });

    return service;
  });

  const [filtersForm] = Form.useForm();
  // const [sortersForm] = Form.useForm();

  const exportData = () => {
    const filters = filtersForm.getFieldsValue();

    exportLicenseRequests.filterData(filters);
  };

  return (
    <AppPage header="Экспорт заявок на лицензию" subheader="Выгрузка данных заявок">
      <NotificationsProvider value={notify}>
        <Row gutter={[0, 16]}>
          {/* <Col span={24}>
          <Card size="small" title="Сортировка колонок">
            <FormSorters form={sortersForm} onChange={console.log} />
          </Card>
        </Col> */}
          <Col span={24}>
            <Card size="small" title="Фильтры">
              <FormFilters form={filtersForm} onChange={console.log} />
            </Card>
          </Col>

          <Col span={24}>
            <Card size="small">
              <Row justify="end">
                <Button
                  type="primary"
                  onClick={exportData}
                  loading={exportLicenseRequests.isLoading}
                >
                  Экспорт заявок
                </Button>
              </Row>
            </Card>
          </Col>

          <Col span={24}>
            <Notifications
              messages={notify.messages}
              onClose={(msg) => notify.removeMessage(msg)}
            />
          </Col>
        </Row>
      </NotificationsProvider>
    </AppPage>
  );
});

const FormFilters = ({ form, onChange }: any) => {
  const handleOnFinish = (filters: { status: ELicenseRequestStatus }) => {
    onChange(filters);
  };

  return (
    <Form layout="vertical" onFinish={handleOnFinish} form={form}>
      <Row gutter={[16, 16]}>
        {exportLicenseRequestsFilters.map((col) => {
          if (!col.filtrable) return null;

          return (
            <Col span={12}>
              <Form.Item name={col.dataIndex} label={col.title}>
                {col.filterRender && col.filterRender({ style: { width: '100%' } })}
              </Form.Item>
            </Col>
          );
        })}
      </Row>
    </Form>
  );
};

// const SegmentOptions = [
//   {
//     label: (
//       <>
//         <MinusOutlined /> По умолчанию
//       </>
//     ),
//     value: '',
//   },
//   {
//     label: (
//       <>
//         <CaretUpOutlined /> По возрастанию
//       </>
//     ),
//     value: 'asc',
//   },
//   {
//     label: (
//       <>
//         <CaretDownOutlined /> По убыванию
//       </>
//     ),
//     value: 'desc',
//   },
// ];

// const FormSorters = ({ form, onChange }: any) => {
//   const handleOnFinish = (filters: { status: ELicenseRequestStatus }) => {
//     onChange(filters);
//   };

//   return (
//     <Form layout="vertical" onFinish={handleOnFinish} form={form}>
//       <Form.Item name="status" label="Статус">
//         <Segmented options={SegmentOptions} />
//       </Form.Item>
//     </Form>
//   );
// };
