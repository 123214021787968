import { ReactNode } from 'react';
import styles from './page-header.module.css';

type PageHeaderProps = {
  header: string;
  subheader?: string;
  after?: ReactNode;
};

export const PageHeader = ({ header, subheader, after }: PageHeaderProps) => {
  return (
    <div className={styles.container}>
      <div>
        <h1 className={styles.header}>{header}</h1>
        {subheader && <span className={styles.subheader}>{subheader}</span>}
      </div>

      {after}
    </div>
  );
};
