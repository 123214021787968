import {
  Badge,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Tabs,
} from 'antd';
import { AppPage } from 'app/ui/app-page';
import { IpInputMask } from 'app/ui/ip-input-mask';
import { Link } from 'react-router-dom';

export const DetailsTerminalPage = () => (
  <AppPage header="Информация о терминале">
    <Space direction="vertical" style={{ display: 'flex' }}>
      {/** <CardTabs> ? */}
      <Card size="small">
        <Descriptions bordered size="small" column={1}>
          <Descriptions.Item
            label="ID терминала"
            labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
          >
            4
          </Descriptions.Item>
          <Descriptions.Item
            label="Статус терминала"
            labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
          >
            <Badge status="success" text="Активен" /> <Divider type="vertical" />
          </Descriptions.Item>
          <Descriptions.Item
            label="Заводской номер"
            labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
          >
            4688879654
          </Descriptions.Item>
          <Descriptions.Item
            label="Регистрационный номер"
            labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
          >
            13564786
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <Card size="small" bodyStyle={{ paddingTop: 0 }}>
        <Tabs defaultActiveKey="1" animated={false}>
          <Tabs.TabPane tab="Настройки" key="1">
            <Form layout="vertical">
              <Form.Item label="IP адрес">
                <IpInputMask />
              </Form.Item>
              <Form.Item label="Тип платформы">
                <Select>
                  <Select.Option key="1">Пеймоб</Select.Option>
                </Select>
              </Form.Item>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label="Протокол">
                    <Select>
                      <Select.Option key="1">http</Select.Option>
                      <Select.Option key="2">https</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Адрес сервера предхоста">
                    <Input placeholder="example.com" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Порт сервера предхоста">
                    <InputNumber style={{ width: '100%' }} placeholder="4242" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Пароль" help="Для авторизации по ID терминала">
                <Input.Password />
              </Form.Item>

              <Row justify="end">
                <Button type="primary" htmlType="submit">
                  Сохранить
                </Button>
              </Row>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Лицензия" key="2">
            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item
                label="Статус лицензии"
                labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
              >
                <Badge status="success" text="Активна" /> <Divider type="vertical" />{' '}
                <Link to={'/license/1'}>Подробнее</Link>
              </Descriptions.Item>
              <Descriptions.Item
                label="Дата активации"
                labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
              >
                {new Date().toLocaleString()}
              </Descriptions.Item>
              <Descriptions.Item
                label="Ключ лицензии"
                labelStyle={{ width: '1%', whiteSpace: 'nowrap' }}
              >
                456-789987-321
              </Descriptions.Item>
            </Descriptions>
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </Space>
  </AppPage>
);
