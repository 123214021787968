// import { HomePage } from 'pages/home';
import React from 'react';
import { TAppRouteObject } from 'core/entities/app-route';

import { BaseLayout } from 'app/ui/layouts/base-layout';

import { createRoute } from './create-route';

import { SignInPage } from './sign-in/sign-in.page';
import { AllUsersPage } from './users/all-users.page';
import { EditUserPage } from './users/edit-user.page';
import { AddUserPage } from './users/create-user.page';
import { EventsPage } from './events/events.page';
import { DetailsLicenseRequestsPage } from './license-requests/details-license-requests.page';
import { AddLicenseRequestPage } from './license-requests/add-license-request.page';
import { AllBankLicensesPage } from './bank-licenses/all-bank-licenses.page';
import { DetailsBankLicensePage } from './bank-licenses/details-bank-license.page';
import { ProfilePage } from './profile/profile.page';
import { AllBanksPage, DetailsBankPage } from './banks';
import { AddTerminalPage, AllTerminalsPage, DetailsTerminalPage } from './terminals';
import { AddBankPage } from './banks/add-bank.page';
import { AddBankLicensePage } from './bank-licenses/add-bank-license.page';
import { NotFoundPage } from './not-found.page';
import { AddRevokeLicenseRequestPage } from './revoke-license-requests/add-revoke-license-request.page';
import { AllRevokeLicenseRequestsPage } from './revoke-license-requests/all-revoke-license-requests.page';
import { DetailsRevokeLicenseRequestsPage } from './revoke-license-requests/details-revoke-license-requests.page';
import { ExportLicenseRequestPage } from './license-requests/export-license-request.page';
import { AllChangeLicenseRequestsPage } from './change-license-requests/all-change-license-requests.page';
import { AddChangeLicenseRequestPage } from './change-license-requests/add-change-license-request.page';
import { DetailsChangeLicenseRequestsPage } from './change-license-requests/details-change-license-requests.page';
import { ExportLicensesPage } from './bank-licenses/export-licenses.page';

const DefaultSettingsLicensePage = React.lazy(
  () => import('./bank-licenses/default-settings-license.page'),
);

const AllLicenseRequestsPage = React.lazy(
  () => import('./license-requests/all-license-requests.page'),
);

export const signInRoute = createRoute({
  path: '/sign-in',
  element: <SignInPage />,
});

// export const homeRoute = createRoute({
//   index: true,
//   element: <HomePage />,
// };

export const terminalsRoute = createRoute({
  path: '/terminals/',
  element: <AllTerminalsPage />,
});
export const terminalDetailsRoute = createRoute<'terminalId'>({
  path: '/terminal/:terminalId',
  element: <DetailsTerminalPage />,
});
export const terminalAddRoute = createRoute({
  path: '/terminals/add',
  element: <AddTerminalPage />,
});

export const licensesRoute = createRoute({
  path: '/licenses/',
  element: <AllBankLicensesPage />,
});
export const licensesAddRoute = createRoute({
  path: '/licenses/add',
  element: <AddBankLicensePage />,
});
export const licensesExport = createRoute({
  path: '/licenses/export',
  element: <ExportLicensesPage />,
});
export const licensesDetailsRoute = createRoute<'licenseId'>({
  path: '/license/:licenseId',
  element: <DetailsBankLicensePage />,
});
export const licensesDefaultSettingsRoute = createRoute({
  path: '/licenses/default-settings',
  element: (
    <React.Suspense fallback={<>Loading...</>}>
      <DefaultSettingsLicensePage />
    </React.Suspense>
  ),
});

export const licensesRequestRoute = createRoute({
  path: '/license-requests/',
  element: (
    <React.Suspense fallback={<>Loading...</>}>
      <AllLicenseRequestsPage />
    </React.Suspense>
  ),
});
export const licensesRequestCreateRoute = createRoute({
  path: '/license-requests/create',
  element: <AddLicenseRequestPage />,
});
export const licensesRequestExportRoute = createRoute({
  path: '/license-requests/export',
  element: <ExportLicenseRequestPage />,
});
export const licensesRequestDetailsRoute = createRoute<'requestId'>({
  path: '/license-request/:requestId',
  element: <DetailsLicenseRequestsPage />,
});

export const revokeLicensesRequestRoute = createRoute({
  path: '/revoke-license-requests/',
  element: <AllRevokeLicenseRequestsPage />,
});
export const revokeLicensesRequestCreateRoute = createRoute({
  path: '/revoke-license-requests/create',
  element: <AddRevokeLicenseRequestPage />,
});
export const revokeLicenseRequestDetailsRoute = createRoute<'requestId'>({
  path: '/revoke-license-request/:requestId',
  element: <DetailsRevokeLicenseRequestsPage />,
});

export const changeLicensesRequestRoute = createRoute({
  path: '/change-license-requests/',
  element: <AllChangeLicenseRequestsPage />,
});
export const changeLicensesRequestCreateRoute = createRoute({
  path: '/change-license-requests/create',
  element: <AddChangeLicenseRequestPage />,
});
export const changeLicenseRequestDetailsRoute = createRoute<'requestId'>({
  path: '/change-license-request/:requestId',
  element: <DetailsChangeLicenseRequestsPage />,
});

export const banksRoute = createRoute({
  path: '/banks',
  element: <AllBanksPage />,
});
export const bankAddRoute = createRoute({
  path: '/banks/add',
  element: <AddBankPage />,
});
export const bankDetailsRoute = createRoute<'bankId'>({
  path: '/bank/:bankId',
  element: <DetailsBankPage />,
});

export const eventsRoute = createRoute({
  path: '/events',
  element: <EventsPage />,
});

export const usersRoute = createRoute({
  path: '/users',
  element: <AllUsersPage />,
});
export const userDetailsRoute = createRoute<'userId'>({
  path: '/user/:userId',
  element: <EditUserPage />,
});
export const createUserRoute = createRoute({
  path: '/users/create',
  element: <AddUserPage />,
});

export const profileRoute = createRoute({
  path: '/profile',
  element: <ProfilePage />,
});

export const notFountRoute = createRoute({
  path: '*',
  element: <NotFoundPage />,
});

export const indexRoute = createRoute({
  path: '/',
  element: <BaseLayout />,
  children: [
    // homeRoute,

    profileRoute,

    // terminalsRoute,
    // terminalAddRoute,
    // terminalDetailsRoute,

    licensesRoute,
    licensesExport,
    licensesAddRoute,
    licensesDetailsRoute,

    licensesDefaultSettingsRoute,

    licensesRequestRoute,
    licensesRequestCreateRoute,
    licensesRequestDetailsRoute,
    licensesRequestExportRoute,

    revokeLicensesRequestRoute,
    revokeLicensesRequestCreateRoute,
    revokeLicenseRequestDetailsRoute,

    changeLicensesRequestRoute,
    changeLicensesRequestCreateRoute,
    changeLicenseRequestDetailsRoute,

    usersRoute,
    userDetailsRoute,
    createUserRoute,

    banksRoute,
    bankAddRoute,
    bankDetailsRoute,

    eventsRoute,

    notFountRoute,
  ],
});

export const routes: TAppRouteObject<any>[] = [signInRoute, indexRoute, notFountRoute];
