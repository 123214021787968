import { getUsersRequest } from 'app/api/requests/users';
import { ConstraintsStore } from 'app/features/constraints/constraints.store';
import { IHttpClient } from 'app/libs/http-client/types';
import { ObservableRequest } from 'app/tools/observable-request';
import { INotifications } from 'core/entities/notifications';
import { TPageable } from 'core/entities/pageable';
import { TUser, TUserConstraints } from 'core/entities/user';
import { makeAutoObservable } from 'mobx';

type ChangableConstraints = {
  pagination: { current: number; pageSize: number };
  sort: TUserConstraints['sorting'];
};

type AllUsersServiceParams = {
  apiClient: IHttpClient;
  notify: INotifications;
  constraints: ConstraintsStore<TUserConstraints>;
};

export class AllUsersService {
  public users: TUser[] = [];

  private allUsersRequest: ObservableRequest<TUserConstraints, TPageable<TUser[]>>;

  public get isLoading() {
    return this.allUsersRequest.isLoading;
  }

  private constraints: ConstraintsStore<TUserConstraints>;

  constructor({ apiClient, notify, constraints }: AllUsersServiceParams) {
    this.constraints = constraints;

    const request = (constraints?: TUserConstraints) =>
      apiClient.fetch<TPageable<TUser[]>>(getUsersRequest(constraints));

    this.allUsersRequest = new ObservableRequest(request, {
      done: (users) => this.setUsers(users),
      fail: (error) => notify.addMessage({ type: 'error', message: error.message }),
    });

    makeAutoObservable(this);
  }

  setUsers({ content, ...pagination }: TPageable<TUser[]>) {
    this.users = content;
    this.constraints.updatePageInfo(pagination);
  }

  public fetchUsers() {
    const constraints = this.constraints.data;

    this.allUsersRequest.fetch(constraints);
  }

  public filterRecords(filters: TUserConstraints['filter']) {
    this.constraints.setFilters(filters);

    this.fetchUsers();
  }

  public changeConstraints({ pagination, sort }: ChangableConstraints) {
    this.constraints.updatePageInfo({
      page: pagination.current,
      size: pagination.pageSize,
    });

    this.constraints.setSort(sort);

    this.fetchUsers();
  }
}
