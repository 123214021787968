import { ok, err, Result } from 'neverthrow';
import { ApiFail } from 'core/data/api-fail';
import { BlobParseFail } from 'core/data/blob-parce-fail';
import { Fail } from 'core/data/fail';
import { JsonParseFail } from 'core/data/json-parce-fail';
import { PermissionsFail } from 'core/data/permissions-fail';
import { ServerFail } from 'core/data/server-fail';
import { UnauthorizedFail } from 'core/data/unauthorized-fail';
import { Subscribers } from './subscribers';
import { isApiError, isBlob } from './tools';
import { EventPredicate, Handler, HttpRequest, IHttpClient, ReceivedResponse } from './types';
import { AbortFail } from 'core/data/abort-fail';
import { ConnectionFail } from 'core/data/connection-fail';
import { HttpLogger, LogData, LogRecord } from './http-logger';

/**
 * Сделать HttpRequest, HttpResponse
 *  class HttpRequest extends Request {
 *    toJSON() {
 *      return JSON.stringify({
 *        status: this.status,
 *        headers: Object.fromEntries(this.headers),
 *
 *      })
 *    }
 *   }
 */

type HttpClientParams = {
  log: (record: LogRecord) => void;
};

export class HttpClient implements IHttpClient {
  private events: Subscribers = new Subscribers();
  private logger: HttpLogger;

  constructor(params: HttpClientParams) {
    this.logger = new HttpLogger({ dist: params.log });
  }

  private log(data: LogData) {
    this.logger.add(data);
  }

  public on(event: EventPredicate, handler: Handler) {
    return this.events.on(event, handler);
  }

  public async fetch<Payload>(request: HttpRequest): Promise<Result<Payload, Fail>> {
    try {
      const response = await window.fetch(request);

      this.emitEvents(request, {
        url: response.url,
        status: response.status,
        headers: response.headers,
      });

      if (response.ok) {
        if (isBlob(response.headers)) return this.extractBlob(request, response);

        const textBody = await response.text();
        const parsedJson = this.parseJson(textBody);
        const result = parsedJson.andThen((json) => this.checkApiError<Payload>(json));

        if (parsedJson.isErr()) {
          this.log({
            url: request.url,
            body: textBody,
            res: response,
            req: request,
            err: parsedJson.error,
          });
        }

        return result;
      }

      return this.checkStatus(request, response);
    } catch (error) {
      this.log({ url: request.url, req: request, body: null, err: error });

      if (error instanceof Fail) return err(error);

      if (error instanceof DOMException && error.name === 'AbortError') {
        return err(new AbortFail());
      }

      return err(new ConnectionFail());
    }
  }

  private emitEvents(request: RequestInit, response: ReceivedResponse) {
    try {
      this.events.notifyHandlers(
        (isEvent, handler) => isEvent(request, response) && handler(request, response),
      );
    } catch (error) {
      this.log({ url: response.url, req: request, res: response, body: null, err: error });
    }
  }

  private async extractBlob<T>(
    request: RequestInit,
    response: Response,
  ): Promise<Result<T, BlobParseFail>> {
    try {
      const blob = await response.blob();

      return ok(blob as unknown as T);
      //          ^ Yeah, actually it's Blob, but for corrent result types,
      //            better pass Payload generic to main function:
      //            httpClient.fetch<Blob>(url, request) => Promise<Blob, Fail>
      //                                      vs
      //            httpClient.fetch(url, request) => Promise<Payload | Blob, Fail>
    } catch (error) {
      this.log({ url: response.url, req: request, res: response, body: null, err: error });
      const fail = new BlobParseFail();
      fail.cause = error;

      return err(fail);
    }
  }

  private parseJson(body: string): Result<any, JsonParseFail> {
    try {
      const data = JSON.parse(body);

      return ok(data);
    } catch (error) {
      const fail = new JsonParseFail(error.message);
      fail.cause = error;

      return err(fail);
    }
  }

  private checkApiError<NoErrors>(json: any) {
    return isApiError(json)
      ? err(new ApiFail(json?.resultDescription))
      : ok(json as unknown as NoErrors);
  }

  private async checkStatus(request: RequestInit, response: Response) {
    const textBody = await response.text();
    const jsonParsed = this.parseJson(textBody);
    let message = '';

    if (jsonParsed.isErr()) {
      this.log({
        url: response.url,
        req: request,
        res: response,
        body: textBody,
        err: jsonParsed.error,
      });
    }

    if (jsonParsed.isOk()) {
      const result = this.checkApiError(jsonParsed.value);
      message = result.isErr() ? result.error.message : textBody;
    }

    switch (response.status) {
      case 401:
        return err(new UnauthorizedFail(message));
      case 403:
        return err(new PermissionsFail(message));
      default:
        const error = new ServerFail(message);
        this.log({
          url: response.url,
          req: request,
          res: response,
          body: textBody,
          err: error,
        });

        return err(error);
    }
  }
}
