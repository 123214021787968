type TPermissions = typeof permissions;

export type UPermissions = TPermissions[keyof TPermissions];

export const permissions = {
  PERM_USER_CREATE: 'PERM_USER_CREATE',
  PERM_USER_READ: 'PERM_USER_READ',
  PERM_USER_UPDATE: 'PERM_USER_UPDATE',

  PERM_JOURNAL_READ: 'PERM_JOURNAL_READ',

  PERM_BANK_CREATE: 'PERM_BANK_CREATE',
  PERM_BANK_READ: 'PERM_BANK_READ',
  PERM_BANK_UPDATE: 'PERM_BANK_UPDATE',

  PERM_TERMINAL_CREATE: 'PERM_TERMINAL_CREATE',
  PERM_TERMINAL_READ: 'PERM_TERMINAL_READ',
  PERM_TERMINAL_UPDATE: 'PERM_TERMINAL_UPDATE',

  PERM_BANK_LICENCE_CREATE: 'PERM_BANK_LICENCE_CREATE',
  PERM_BANK_LICENCE_READ: 'PERM_BANK_LICENCE_READ',
  PERM_BANK_LICENCE_UPDATE: 'PERM_BANK_LICENCE_UPDATE',

  PERM_TERMINAL_POS_CONF_UPDATE: 'PERM_TERMINAL_POS_CONF_UPDATE',
  PERM_DEFAULT_TERMINAL_POS_CONF_UPDATE: 'PERM_DEFAULT_TERMINAL_POS_CONF_UPDATE',

  PERM_BANK_LICENCE_REQUEST_CREATE: 'PERM_BANK_LICENCE_REQUEST_CREATE',
  PERM_BANK_LICENCE_REQUEST_READ: 'PERM_BANK_LICENCE_REQUEST_READ',
  PERM_BANK_LICENCE_REQUEST_UPDATE: 'PERM_BANK_LICENCE_REQUEST_UPDATE',
  PERM_BANK_LICENCE_REQUEST_APPLY: 'PERM_BANK_LICENCE_REQUEST_APPLY',

  PERM_REVOKE_LICENCE_REQUEST_CREATE: 'PERM_REVOKE_LICENCE_REQUEST_CREATE',
  PERM_REVOKE_LICENCE_REQUEST_READ: 'PERM_REVOKE_LICENCE_REQUEST_READ',
  PERM_REVOKE_LICENCE_REQUEST_UPDATE: 'PERM_REVOKE_LICENCE_REQUEST_UPDATE',
  PERM_REVOKE_LICENCE_REQUEST_APPLY: 'PERM_REVOKE_LICENCE_REQUEST_APPLY',

  PERM_CHANGE_LICENCE_REQUEST_CREATE: 'PERM_CHANGE_LICENCE_REQUEST_CREATE',
  PERM_CHANGE_LICENCE_REQUEST_READ: 'PERM_CHANGE_LICENCE_REQUEST_READ',
  PERM_CHANGE_LICENCE_REQUEST_UPDATE: 'PERM_CHANGE_LICENCE_REQUEST_UPDATE',
  PERM_CHANGE_LICENCE_REQUEST_APPLY: 'PERM_CHANGE_LICENCE_REQUEST_APPLY',
} as const;
