import { createMockHandler } from 'app/api/create-mock-handler';
import { RequestConfig } from 'app/api/types';
import { TTerminal, TTerminalPosConfig } from 'core/entities/terminal';
import { createRequest } from '../create-request';

const config: RequestConfig = {
  method: 'post',
  url: '/tms-ws/api/update_terminal_pos_conf',
};

export const updateTerminalPosConfRequest = (license: TTerminalPosConfig) =>
  createRequest({
    url: config.url,
    method: config.method,
    body: JSON.stringify(license),
  });

const data: TTerminal = {
  id: 1,
  bankLicence: {},
  blocked: false,
  data: '',
  dsn: '000000',
  type: { id: 1, name: 'Test' },
  zn: '0000000',
  posConfig: { host: 'test.com', https: true, password: '', port: 0, type: 1 },
};

export const updateTerminalPosConfMockHandler = createMockHandler<TTerminal>({
  url: config.url,
  method: config.method,
  status: 200,
  data,
});
