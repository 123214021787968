import { useEffect } from 'react';
import { Button, Form, Row } from 'antd';
import { TRole } from 'core/entities/role';
import { SelectRoles } from './select-roles/select-roles';

type UserRolesProps = {
  availableRoles: TRole[];
  selectedRoles: TRole[];
  loading: boolean;
  onSubmit?: (roles: TRole[]) => void;
};
export const UserRoles = ({
  availableRoles,
  selectedRoles,
  loading,
  onSubmit,
}: UserRolesProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ roles: selectedRoles });
  }, [form, selectedRoles]);

  return (
    <Form form={form} onFinish={onSubmit ?? (() => {})}>
      <Form.Item name="roles" valuePropName="selected">
        <SelectRoles roles={availableRoles} />
      </Form.Item>

      {onSubmit && (
        <Row justify="end">
          <Button htmlType="submit" type="primary" loading={loading}>
            Сохранить
          </Button>
        </Row>
      )}
    </Form>
  );
};
