import { TConstraints } from 'core/entities/constraints';
import { makeAutoObservable } from 'mobx';

type ConstraintsStoreParams = {
  pageInfo?: Partial<TConstraints<any, any>['pageInfo']>;
};

const pagingInitialState = () => ({ page: 1, size: 40, totalElements: 20, totalPages: 1 });

type InitialStateOverride = ConstraintsStoreParams;
export class ConstraintsStore<Constraints extends TConstraints<any, any>> {
  private sort: Constraints['sorting'] = [];
  private filters: Constraints['filter'] = null;
  private pageInfo: Constraints['pageInfo'] = pagingInitialState();

  constructor(params?: ConstraintsStoreParams) {
    makeAutoObservable(this);

    this.initiateInitialState(params);
  }

  get data() {
    return {
      sorting: this.sort,
      filter: this.filters,
      pageInfo: this.pageInfo,
    };
  }

  private initiateInitialState(initState?: InitialStateOverride) {
    initState?.pageInfo && this.updatePageInfo(initState?.pageInfo);
  }

  public updatePageInfo(page: Partial<Constraints['pageInfo']>) {
    this.pageInfo = { ...this.pageInfo, ...page };
  }

  public setFilters(filters: Constraints['filter']) {
    this.filters = filters;
  }

  public setSort(sort: Constraints['sorting']) {
    this.sort = sort;
  }

  public reset() {
    this.sort = [];
    this.filters = null;
    this.pageInfo = pagingInitialState();
  }
}
