import { Space, Card } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { apiClient } from 'app/api';
import { AppPage } from 'app/ui/app-page';
import { BaseTable } from 'app/ui/base-table';
import { FilterColumns } from 'app/ui/filter-columns/filter-columns';
import { Loading } from 'app/ui/loading';
import { Notifications, NotificationsStore } from 'app/features/notifications/notifications';
import { NotificationsProvider } from 'app/features/notifications/notifications/notification-provider';
import { filterColumsByPerms } from 'app/ui/filter-columns/filter-columns-by-perms';
import { useUserStore } from 'app/features/users/user.store';
import { AllChangeLicenseRequestsService } from 'app/features/change-license-requests/all-change-license-requests/all-change-license-requests.service';
import {
  allChangeLicenseRequestsColumns,
  allChangeLicenseRequestsFilters,
} from 'app/features/change-license-requests/all-change-license-requests/all-change-license-requests-columns';
import { ConstraintsStore } from 'app/features/constraints/constraints.store';
import { TChangeLicenseRequestsConstraints } from 'core/entities/change-license-request';
import { TBaseConstraints } from 'app/ui/base-table/base-table';

export const AllChangeLicenseRequestsPage = observer(() => {
  const [notify] = useState(() => new NotificationsStore());

  const currentUser = useUserStore();
  const permList = currentUser.permList;

  const licenseChangeRequestFilters = useMemo(
    () => filterColumsByPerms(allChangeLicenseRequestsFilters, permList),
    [permList],
  );
  const [constraints] = useState(() => new ConstraintsStore<TChangeLicenseRequestsConstraints>());

  const [allChangeLicenseRequests] = useState(() => {
    const service = new AllChangeLicenseRequestsService({
      apiClient,
      notify,
      constraints,
    });

    return service;
  });

  const onChangeFilter = useCallback(
    (filters) => allChangeLicenseRequests.filterRecords(filters),
    [allChangeLicenseRequests],
  );

  const onChangeConstraints = useCallback(
    (constraints: TBaseConstraints<TChangeLicenseRequestsConstraints>) => {
      allChangeLicenseRequests.changeConstraints(constraints);
    },
    [allChangeLicenseRequests],
  );

  useEffect(() => {
    allChangeLicenseRequests.fetchLicenseRequests();
  }, [allChangeLicenseRequests]);

  return (
    <NotificationsProvider value={notify}>
      <AppPage header="Заявки на редактирование лицензии" subheader="Просмотр созданных заявок">
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Notifications messages={notify.messages} onClose={(msg) => notify.removeMessage(msg)} />

          <FilterColumns columns={licenseChangeRequestFilters} onChange={onChangeFilter} />

          <Card size="small">
            <Loading isActive={allChangeLicenseRequests.isLoading}>
              <BaseTable
                columns={allChangeLicenseRequestsColumns}
                dataSource={allChangeLicenseRequests.requests}
                paging={constraints.data.pageInfo}
                onChange={onChangeConstraints}
                persistentId="all-change-license-request-list"
              />
            </Loading>
          </Card>
        </Space>
      </AppPage>
    </NotificationsProvider>
  );
});
