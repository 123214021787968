import { MaskedInput } from 'antd-mask-input';

const INN_MASK = '00000000';

type InnMaskedInputProps = Omit<React.ComponentProps<typeof MaskedInput>, 'mask'>;
export const InnMaskedInput = ({ ...props }: InnMaskedInputProps) => {
  return (
    <MaskedInput
      maskOptions={{ lazy: true, placeholderChar: '' }}
      onPaste={(e: any) => e.preventDefault()}
      // ^ fix bug (double value) when paste value to field (https://github.com/antoniopresto/antd-mask-input/issues/55#issuecomment-1207435176)
      style={{ width: '100%' }}
      {...props}
      mask={INN_MASK}
    />
  );
};
