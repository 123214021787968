import { ApiError } from './types';

export const isApiError = (json: any): json is ApiError => {
  const rules = [
    json !== null,
    typeof json === 'object',
    typeof json.result === 'number',
    json.result > 0,
  ];

  return rules.every(Boolean);
};

export const isBlob = (headers: Headers) => {
  const disposition = headers.get('content-disposition');
  const result = disposition?.includes('attachment');

  return result;
};
