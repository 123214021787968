import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ErrorBoundary } from 'libs/error-tracker/react-error-boundary';

if (process.env.NODE_ENV === 'development') {
  // require('./setup-api-mocks');
}

if (process.env.NODE_ENV === 'production') {
  require('./setup-error-tracking');
}

ReactDOM.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  // </React.StrictMode>,
  document.getElementById('root'),
);
