type FormItemAdapterProps<ChildValue, ChildOnChangeValue, FromFormValue, ToFormValue> = {
  valueFormat: (value: FromFormValue) => ChildValue;
  onChangeFormat: (value: ChildOnChangeValue) => ToFormValue;
  value?: FromFormValue;
  onChange?: (value: ToFormValue) => void;
  children: (
    value: ChildValue | null,
    onChange: (value: ChildOnChangeValue) => void,
  ) => React.ReactNode;
};
export const FormItemAdapter = <ChildValue, ChildOnChangeValue, InputValue, OutputValue>({
  valueFormat,
  onChangeFormat,
  children,
  value,
  onChange,
}: FormItemAdapterProps<ChildValue, ChildOnChangeValue, InputValue, OutputValue>) => {
  const onChangedValue = (value: ChildOnChangeValue) =>
    onChange && onChange(onChangeFormat(value));

  return <>{children(value ? valueFormat(value) : null, onChangedValue)}</>;
};

/**
 * Example: Transform Moment instance from/to DatePicker in onChange(value: Moment)/value props
 * Form state will receive date as ISO(JSON) string from onChange prop
 * Component will receive Moment instance from value prop

type FormFields = { date: string | null }

const [form] = Form.useForm<FormFields>();

<Form form={form} defaultValues={{date: '2022-08-25T23:22:08.548Z'}}>
  <FormItem name="date">
    <FormItemAdapter
      valueFormat={nullStringToNullMoment}
      onChangeFormat={nullMomentToNullString}
      // value: string                      | from Form store. Uses by FormItem. Dont use this prop manually
      // onChange: (value: string) => void  | to Form store.   Uses by FormItem. Dont use this prop manually
    >
      {(value, onChange) => <DatePicker value={value} onChange={onChange}>}
        ^ Moment  ^ (value: Moment) => void
    </FormItemAdapter>
  <FormItem>
</Form>
 */
