import { TRole } from 'core/entities/role';
import { TablePermission, TableRoleRecord, TableSelectedRole } from './types';

export const extractRolesList = (roles: Array<TRole>) => {
  if (roles.length === 0) return [];

  return roles.reduce<Array<TableRoleRecord>>(
    (acc, role, idx: number) =>
      acc.concat({
        key: idx,
        id: role.id,
        title: role.title,
      }),
    [],
  );
};

export const extractPermissions = (roles: Array<TRole>): Array<TablePermission> => {
  if (roles.length === 0) return [];
  const permissions = roles.reduce<Array<TablePermission>>((acc, role) => {
    return acc.concat(
      role.permissions.map((p) => ({
        id: p.id,
        roleId: role.id,
        roleTitle: role.title,
        title: p.title,
        description: p.description || '',
      })),
    );
  }, []);

  return permissions;
};

export const filterPermissionsByRoles = (
  permissions: Array<TablePermission>,
  selectedRoles: Array<TableSelectedRole> = [],
) => {
  return selectedRoles.length === 0
    ? []
    : permissions.filter((perm) => selectedRoles.find(({ roleId }) => roleId === perm.roleId));
  // ? permissions
  // : permissions.filter((perm: any) => selectedRolesId.find((roleId) => roleId === perm.roleId));
};
