import { ObservableRequest } from 'app/tools/observable-request';

import { INotifications } from 'core/entities/notifications';
import { makeAutoObservable } from 'mobx';

import { IHttpClient } from 'app/libs/http-client/types';
import { ChangeLicenseRequestStore } from '../change-license-request.store';
import {
  EChangeLicenseRequestStatus,
  TChangeBankLicense,
  TChangeLicenseRequest,
  TChangeUpdatedLicenseRequest,
} from 'core/entities/change-license-request';
import { updateChangeLicenceDetails } from 'app/api/requests/change-license-requests';

type UpdateChangeLicenseRequestParams = {
  licenseRequestStore: ChangeLicenseRequestStore;
  apiClient: IHttpClient;
  notify: INotifications;
};

export class UpdateChangeLicenseRequestService {
  private licenseRequest: ObservableRequest<TChangeLicenseRequest, TChangeUpdatedLicenseRequest>;

  public get isLoading() {
    return this.licenseRequest.isLoading;
  }

  private licenseRequestStore: ChangeLicenseRequestStore;
  private notify: INotifications;

  constructor({ licenseRequestStore, apiClient, notify }: UpdateChangeLicenseRequestParams) {
    this.licenseRequestStore = licenseRequestStore;
    this.notify = notify;

    const request = (data: TChangeLicenseRequest) =>
      apiClient.fetch<TChangeUpdatedLicenseRequest>(updateChangeLicenceDetails(data));

    this.licenseRequest = new ObservableRequest(request, {
      done: (licenseRequest) => this.setUpdatedLicenseRequest(licenseRequest),
      fail: (error) => notify.addMessage({ type: 'error', message: error.message }),
    });

    makeAutoObservable(this);
  }

  private setUpdatedLicenseRequest(data: TChangeUpdatedLicenseRequest) {
    const licenseRequest = data.request;
    this.licenseRequestStore.setData(licenseRequest);
  }

  public saveRequestFromLicensesList(licences: TChangeBankLicense[]) {
    const updatedLicenseRequest: TChangeLicenseRequest = {
      ...this.licenseRequestStore.data,
      licences: licences,
    };

    this.licenseRequest.fetch(updatedLicenseRequest);
  }

  public confirmLicenseRequest() {
    this.notify.clearAll();

    const confirmLicenseRequest: TChangeLicenseRequest = {
      ...this.licenseRequestStore.data,
      status: EChangeLicenseRequestStatus.confirmed,
    };

    this.licenseRequest.fetch(confirmLicenseRequest);
  }

  public rejectLicenseRequest() {
    const rejectedLicenseRequest: TChangeLicenseRequest = {
      ...this.licenseRequestStore.data,
      status: EChangeLicenseRequestStatus.rejected,
    };

    this.licenseRequest.fetch(rejectedLicenseRequest);
  }
}
