import { Badge } from 'antd';
import { ELicenseRequestStatus } from 'core/entities/license-request';

type LicenseRequestStatusProps = { status: ELicenseRequestStatus };

export const LicenseRequestStatus = ({ status }: LicenseRequestStatusProps) => {
  switch (status) {
    case ELicenseRequestStatus.created:
      return <Badge status="processing" text="В обработке" />;
    case ELicenseRequestStatus.confirmed:
      return <Badge status="success" text="Подтверждена" />;
    case ELicenseRequestStatus.rejected:
      return <Badge status="error" text="Отклонена" />;
  }
};
