import { Card, Space } from 'antd';
import { apiClient } from 'app/api';
import { AllBanksService } from 'app/features/banks/all-banks/all-banks.service';
import { bankColumns, bankFilters } from 'app/features/banks/all-banks/bank-columns';
import { Notifications, NotificationsStore } from 'app/features/notifications/notifications';
import { AppPage } from 'app/ui/app-page';
import { BaseTable } from 'app/ui/base-table';
import { FilterColumns } from 'app/ui/filter-columns/filter-columns';
import { TBankConstraints } from 'core/entities/bank';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { Loading } from 'app/ui/loading';
import { ConstraintsStore } from 'app/features/constraints/constraints.store';
import { TBaseConstraints } from 'app/ui/base-table/base-table';

export const AllBanksPage = observer(() => {
  const [notify] = useState(() => new NotificationsStore());
  const [constraints] = useState(() => new ConstraintsStore<TBankConstraints>());

  const [allBanks] = useState(() => {
    const service = new AllBanksService({ apiClient, notify, constraints });

    return service;
  });

  const onChangeFilter = useCallback((filters) => allBanks.filterRecords(filters), [allBanks]);

  const onChangeConstraints = useCallback(
    (constraints: TBaseConstraints<TBankConstraints>) => {
      allBanks.changeConstraints(constraints);
    },
    [allBanks],
  );

  useEffect(() => {
    allBanks.fetchBanks();
  }, [allBanks]);

  return (
    <AppPage header="Все банки" subheader="Просмотр подключенных банков">
      <Space direction="vertical" style={{ display: 'flex' }}>
        <Notifications messages={notify.messages} onClose={(msg) => notify.removeMessage(msg)} />

        <FilterColumns columns={bankFilters} onChange={onChangeFilter} />

        <Card size="small">
          <Loading isActive={allBanks.isLoading}>
            <BaseTable
              columns={bankColumns}
              dataSource={allBanks.banks}
              paging={constraints.data.pageInfo}
              onChange={onChangeConstraints}
              persistentId="all-banks-list"
            />
          </Loading>
        </Card>
      </Space>
    </AppPage>
  );
});
