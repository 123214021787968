import { LoadingOutlined } from '@ant-design/icons';
import { Card } from 'antd';

export const UserGuardLoading = () => {
  return (
    <div
      style={{
        display: 'flex',
        minHeight: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card hoverable>
        <LoadingOutlined style={{ fontSize: 24 }} spin />
        <span style={{ marginLeft: 16 }}>Загрузка...</span>
      </Card>
    </div>
  );
};
