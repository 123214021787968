import { makeAutoObservable } from 'mobx';
import { INotifications } from 'core/entities/notifications';
import { ObservableRequest } from 'app/tools/observable-request';
import { IHttpClient } from 'app/libs/http-client/types';
import { TBank, TBankNew } from 'core/entities/bank';
import { addBankRequest } from 'app/api/requests/banks';
import { NavigateFunction } from 'react-router-dom';
import { banksRoute } from 'app/pages/routes';

type AddBankServiceParams = {
  apiClient: IHttpClient;
  navigate: NavigateFunction;
  notify: INotifications;
};

export class AddBankService {
  private addBankRequest: ObservableRequest<TBankNew, TBank>;

  public get status() {
    return this.addBankRequest.status;
  }

  public get isLoading() {
    return this.addBankRequest.isLoading;
  }

  private notify: INotifications;
  private navigate: NavigateFunction;

  constructor({ apiClient, notify, navigate }: AddBankServiceParams) {
    this.notify = notify;
    this.navigate = navigate;

    const request = (newBank: TBankNew) => apiClient.fetch<TBank>(addBankRequest(newBank));

    this.addBankRequest = new ObservableRequest(request, {
      done: () => this.bankCreated(),
      fail: (error) => this.addError(error.message),
    });

    makeAutoObservable(this);
  }

  public async addBank(newBank: TBankNew) {
    this.addBankRequest.fetch(newBank);
  }

  private bankCreated() {
    this.navigate(banksRoute.path);
  }

  private addError(error: string) {
    this.notify.addMessage({ type: 'error', message: error });
  }
}
