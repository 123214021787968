import { ObservableRequest } from 'app/tools/observable-request';

import { INotifications } from 'core/entities/notifications';
import { makeAutoObservable } from 'mobx';

import { IHttpClient } from 'app/libs/http-client/types';
import { TExportLicenseRequestConstraints } from 'core/entities/export-licenses';
import { exportLicenceRequestsRequest } from 'app/api/requests/license-requests/export_licence_requests';
import { saveAs } from 'file-saver';
import { Fail } from 'core/data/fail';

type Filter = TExportLicenseRequestConstraints['filter'];

type ExportLicenseRequestsServiceParams = {
  apiClient: IHttpClient;
  notify: INotifications;
};

export class ExportLicenseRequestsService {
  private exportLicenseRequest: ObservableRequest<TExportLicenseRequestConstraints, Blob>;
  private loadingState: boolean = false;

  public get isLoading() {
    return this.loadingState || this.exportLicenseRequest.isLoading;
  }

  private sort: TExportLicenseRequestConstraints['sorting'] = [];
  private filters: Filter = null;

  private notify: INotifications;

  constructor({ apiClient, notify }: ExportLicenseRequestsServiceParams) {
    this.notify = notify;

    const request = (constraints?: TExportLicenseRequestConstraints) =>
      apiClient.fetch<Blob>(exportLicenceRequestsRequest(constraints));

    this.exportLicenseRequest = new ObservableRequest(request, {
      done: (file) => this.saveFile(file),
      fail: (error) => this.showError(error),
    });

    makeAutoObservable(this);
  }

  private showError(error: Fail): void {
    this.notify.addMessage({ type: 'error', message: error.message });
    this.changeLoadingState(false);
  }

  private changeLoadingState(state: boolean) {
    this.loadingState = state;
  }

  private saveFile(file: Blob) {
    const currentDate = new Date().toLocaleDateString();
    const currentTime = new Date().toLocaleTimeString();
    saveAs(file, `licence_requests_${currentDate}_${currentTime}.xlsx`);
    this.changeLoadingState(false);
  }

  public exportToFile() {
    this.changeLoadingState(true);
    this.exportLicenseRequest.fetch({ sorting: this.sort, filter: this.filters });
  }

  // sortData(sort: TLicenseRequestsConstraints['sorting']) {
  //   this.sort = sort;
  //   this.fetchLicenseRequests();
  // }

  public filterData(filters: Filter) {
    console.log(filters);
    this.filters = filters;

    this.exportToFile();
  }
}
