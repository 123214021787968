import { Space, Form, Card, Button } from 'antd';
import { apiClient } from 'app/api';
import { AddBankLicenseService } from 'app/features/bank-liceses/add-bank-license/add-bank-license.service';
import { Notifications, NotificationsStore } from 'app/features/notifications/notifications';
import { AppPage } from 'app/ui/app-page';
import { TBankLicenseNew } from 'core/entities/bank-license';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loading } from 'app/ui/loading';
import { MaskedInput } from 'app/ui/masked-input';
import { SelectAvailableBank } from 'app/features/banks/all-banks/select-available-banks';
import { NotificationsProvider } from 'app/features/notifications/notifications/notification-provider';

export const AddBankLicensePage = () => {
  const navigate = useNavigate();
  const [notify] = useState(() => new NotificationsStore());

  const [addBank] = useState(() => {
    const service = new AddBankLicenseService({
      apiClient,
      navigate,
      notify,
    });

    return service;
  });

  return (
    <AppPage header="Добавление банковской лицензии" subheader="Создание лицензии для банка">
      <NotificationsProvider value={notify}>
        <Form
          layout="vertical"
          onFinish={(newBank: TBankLicenseNew) => addBank.addBank(newBank)}
          scrollToFirstError={{ behavior: 'smooth' }}
        >
          <Loading isActive={addBank.isLoading}>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
              <Card size="small">
                <div>
                  <h2>Данные лицензии</h2>
                  <div style={{ maxWidth: '50%' }}>
                    <Form.Item
                      label="Выберите банк"
                      name="bank"
                      rules={[
                        {
                          required: true,
                          message: 'Укажите банк',
                        },
                      ]}
                    >
                      <SelectAvailableBank />
                    </Form.Item>
                    <Form.Item
                      label="ИНН"
                      name="inn"
                      validateFirst={true}
                      rules={[
                        {
                          required: true,
                          message: 'Укажите ИНН',
                        },
                        { len: 8, message: 'Введите 8 цифр' },
                      ]}
                    >
                      <MaskedInput mask="00000000" style={{ width: '100%' }} />
                    </Form.Item>
                  </div>
                </div>
              </Card>

              <Notifications
                messages={notify.messages}
                onClose={(msg) => notify.removeMessage(msg)}
              />

              <Card size="small">
                <div style={{ display: 'flex' }}>
                  <Button type="primary" htmlType="submit" style={{ marginLeft: 'auto' }}>
                    Добавить лицензию
                  </Button>
                </div>
              </Card>
            </Space>
          </Loading>
        </Form>
      </NotificationsProvider>
    </AppPage>
  );
};
