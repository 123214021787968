import { Space, Form, Card, Button, Input, Row } from 'antd';
import { apiClient } from 'app/api';
import { addUserRequest } from 'app/api/requests/users';
import { Notifications, NotificationsStore } from 'app/features/notifications/notifications';
import { IHttpClient } from 'app/libs/http-client/types';
import { ObservableRequest } from 'app/tools/observable-request';
import { INotifications } from 'core/entities/notifications';
import { TNewUser, TUser } from 'core/entities/user';
import { useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Loading } from 'app/ui/loading';
import { PageContent } from 'UI/page-content';
import { PageHeader } from 'UI/page-header';

export const AddTerminalPage = () => {
  const navigate = useNavigate();
  const [notify] = useState(() => new NotificationsStore());

  const [addUser] = useState(() => {
    const service = new AddUserService({
      apiClient,
      navigate,
      notify,
    });

    return service;
  });

  return (
    <>
      <PageHeader header="Добавление терминала" />

      <PageContent>
        <Form
          layout="vertical"
          onFinish={(newUser: TNewUser) => addUser.createUser(newUser)}
          scrollToFirstError={{ behavior: 'smooth' }}
        >
          <Loading isActive={addUser.isLoading}>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
              <Card size="small">
                <h2>Данные терминала</h2>
                <div style={{ maxWidth: '50%' }}>
                  <Form.Item
                    label="Регистрационный номер"
                    name="regid"
                    rules={[
                      {
                        required: true,
                        message: 'Заполните поле',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Заводской номер"
                    name="innhwid"
                    rules={[
                      {
                        required: true,
                        message: 'Заполните поле',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Row justify="end">
                    <Button type="primary" htmlType="submit">
                      Добавить терминал
                    </Button>
                  </Row>
                </div>
              </Card>

              <Notifications
                messages={notify.messages}
                onClose={(msg) => notify.removeMessage(msg)}
              />
            </Space>
          </Loading>
        </Form>
      </PageContent>
    </>
  );
};

type AddUserServiceParams = {
  apiClient: IHttpClient;
  navigate: NavigateFunction;
  notify: INotifications;
};

class AddUserService {
  private addUserRequest: ObservableRequest<TNewUser, TUser>;

  private navigate: NavigateFunction;
  private notify: INotifications;

  public get isLoading() {
    return this.addUserRequest.isLoading;
  }

  constructor({ apiClient, notify, navigate }: AddUserServiceParams) {
    this.navigate = navigate;
    this.notify = notify;

    const request = (user: TNewUser) => apiClient.fetch<TUser>(addUserRequest(user));

    this.addUserRequest = new ObservableRequest(request, {
      done: (user) => this.userCreated(user),
      fail: (error) => this.showError(error.message),
    });
  }

  public createUser(newUser: TNewUser) {
    this.addUserRequest.fetch(newUser);
  }

  private userCreated(user: TUser) {
    // const userPath = generatePath(editUserRoute.path!, { userId: String(user.id) });
    this.navigate('/license-requests/');
  }

  private showError(message: string) {
    this.notify.addMessage({ type: 'error', message });
  }
}
