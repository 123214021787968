import { makeAutoObservable } from 'mobx';

import { ObservableRequest } from 'app/tools/observable-request';
import { INotifications } from 'core/entities/notifications';
import { TLogout } from 'core/entities/logout';
import { IHttpClient } from 'app/libs/http-client/types';
import { logoutRequest } from 'app/api/requests/auth';

type LogoutServiceParams = {
  onFinish: () => void;
  apiClient: IHttpClient;
  notify: INotifications;
};

export class LogoutService {
  public get status() {
    return this.logoutRequest.status;
  }

  public get isLoading() {
    return this.logoutRequest.isLoading;
  }

  private logoutRequest: ObservableRequest<void, TLogout>;

  constructor({ onFinish, apiClient, notify }: LogoutServiceParams) {
    const request = () => apiClient.fetch<TLogout>(logoutRequest());

    this.logoutRequest = new ObservableRequest(request, {
      done: onFinish,
      fail: (error) => notify.addMessage({ type: 'error', message: error.message }),
    });

    makeAutoObservable(this);
  }

  async exit() {
    this.logoutRequest.fetch();
  }
}
