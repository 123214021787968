import { CloseOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Input, Space, InputProps } from 'antd';
import { useState } from 'react';

type EditableLabelProps = {
  value?: any;
  onChange?: any;
  editable?: boolean;
  label?: any;
  input?: any;
  size?: InputProps['size'];
  validator?: (value: any) => Promise<string>;
  // formItemProps?: FormItemProps;
};
export const EditableLabel = ({
  value,
  onChange,
  editable,
  label,
  input,
  size = 'small',
  validator,
}: // formItemProps,
EditableLabelProps) => {
  const [isEdit, setEdit] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [error, setError] = useState('');

  const handleChange = () => {
    if (validator) {
      validator(inputValue)
        .then((value) => {
          setEdit(false);
          onChange(value);
        })
        .catch((error) => setError(error));

      return;
    }

    setEdit(false);
    onChange(inputValue);
  };

  if (!editable) return label ? label(value) : value;

  return isEdit ? (
    <div>
      <Space>
        {input ? (
          input(inputValue, setInputValue)
        ) : (
          <Input
            size={size}
            value={inputValue}
            status={error && 'error'}
            onChange={(event) => setInputValue(event.target.value)}
          />
        )}

        <Button size="small" type="primary" onClick={handleChange} icon={<SaveOutlined />} />
        <Button
          size="small"
          onClick={() => {
            setEdit(false);
          }}
          icon={<CloseOutlined />}
        />
      </Space>
      {error && <div className="ant-form-item-explain-error">{error}</div>}
    </div>
  ) : (
    <Space>
      {label ? label(value) : value}
      <Button
        size="small"
        onClick={() => {
          setEdit(true);
          setError('');
        }}
        icon={<EditOutlined style={{ color: '#2564CD' }} />}
        ghost
      />
    </Space>
  );
};
