import { makeAutoObservable } from 'mobx';
import { INotifications } from 'core/entities/notifications';
import { ObservableRequest } from 'app/tools/observable-request';
import { IHttpClient } from 'app/libs/http-client/types';
import { generatePath, NavigateFunction } from 'react-router-dom';
import { licensesRequestDetailsRoute } from 'app/pages/routes';
import { TLicenseRequest, TLicenseRequestNew } from 'core/entities/license-request';
import { addLicenceRequestRequest } from 'app/api/requests/license-requests';
import { UserStore } from 'app/features/users/user.store';

type AddLicenseRequestServiceParams = {
  apiClient: IHttpClient;
  userStore: UserStore;
  navigate: NavigateFunction;
  notify: INotifications;
};

export class AddLicenseRequestService {
  private addLicenseRequest: ObservableRequest<TLicenseRequestNew, TLicenseRequest>;

  public get status() {
    return this.addLicenseRequest.status;
  }

  public get isLoading() {
    return this.addLicenseRequest.isLoading;
  }

  private notify: INotifications;
  private navigate: NavigateFunction;

  constructor({ apiClient, notify, navigate, userStore }: AddLicenseRequestServiceParams) {
    this.notify = notify;
    this.navigate = navigate;

    const request = (newRequest: TLicenseRequestNew) => {
      const requestData = { ...newRequest, bank: userStore.data.bank };

      return apiClient.fetch<TLicenseRequest>(addLicenceRequestRequest(requestData));
    };

    this.addLicenseRequest = new ObservableRequest(request, {
      done: (request) => this.requestCreated(request.id),
      fail: (error) => this.addError(error.message),
    });

    makeAutoObservable(this);
  }

  public async createLicenseRequest(newBank: TLicenseRequestNew) {
    this.addLicenseRequest.fetch(newBank);
  }

  private requestCreated(requestId: TLicenseRequest['id']) {
    this.navigate(
      generatePath(licensesRequestDetailsRoute.path, { requestId: String(requestId) }),
    );
  }

  private addError(error: string) {
    this.notify.addMessage({ type: 'error', message: error });
  }
}
