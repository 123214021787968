import { Badge } from 'antd';
import { ERevokeLicenseRequestStatus } from 'core/entities/revoke-license-request';

type LicenseRequestStatusProps = { status: ERevokeLicenseRequestStatus };

export const RevokeLicenseRequestStatus = ({ status }: LicenseRequestStatusProps) => {
  switch (status) {
    case ERevokeLicenseRequestStatus.created:
      return <Badge status="processing" text="В обработке" />;
    case ERevokeLicenseRequestStatus.confirmed:
      return <Badge status="success" text="Подтверждена" />;
    case ERevokeLicenseRequestStatus.rejected:
      return <Badge status="error" text="Отклонена" />;
  }
};
