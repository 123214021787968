import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';

type SignInFormProps = {
  loading: boolean;
  onSubmit: (credentials: { login: string; password: string }) => void;
};

export const SignInForm = ({ loading = false, onSubmit }: SignInFormProps) => {
  return (
    <Form layout="vertical" requiredMark={false} onFinish={onSubmit}>
      <Form.Item
        name="login"
        label="Логин"
        rules={[{ required: true, message: 'Пожалуйста введите логин' }]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="login"
          autoComplete="username"
        />
      </Form.Item>
      <Form.Item
        name="password"
        label="Пароль"
        rules={[{ required: true, message: 'Пожалуйста введите пароль' }]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          type="password"
          placeholder="••••••••"
          autoComplete="current-password"
        />
      </Form.Item>

      <Button
        type="primary"
        htmlType="submit"
        className="login-form-button"
        block={true}
        loading={loading}
      >
        Войти
      </Button>
    </Form>
  );
};
