import { createMockHandler } from 'app/api/create-mock-handler';
import { RequestConfig } from 'app/api/types';
import { TPosConfig } from 'core/entities/pos-config';
import { createRequest } from '../create-request';

const config: RequestConfig = {
  method: 'get',
  url: '/tms-ws/api/get_default_pos_conf',
};

export const getDefaultPosConfRequest = () =>
  createRequest({
    url: config.url,
    method: config.method,
  });

const data: TPosConfig = {
  https: false,
  host: 'example.com',
  port: 4242,
  password: '',
  type: 0,
};

export const getDefaultPosConfMockHandler = createMockHandler<TPosConfig>({
  url: config.url,
  method: config.method,
  status: 200,
  data,
});
